<template>
	<p></p>
</template>
<script>
import ChatManage from './mixins/ChatManage'

export default {
  mixins: [ChatManage],
  props: {
    hideflg: Boolean,
  },
  // components:{RocketChatWindow},
  data(){
    return{
    }
  },
  mounted: function(){
		let _this = this
		console.log(this.hideflg)
		_this.$emit('update:hideflg', false)
		console.log(this.hideflg)
  },
	beforeUnmount: async function(){
		this.$emit('update:hideflg', true)
		// await this.logout_chat();
		// console.log("ログインユーザー：" + this.$store.state.loginUser)
		// await this.Login_chat_bg(this.$store.state.loginUser)
	}
}
</script>