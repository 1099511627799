<template>
    <div class="container">
		<h2>
			<font-awesome-icon icon="clipboard-user" />&nbsp;{{ title }}
			<span  data-bs-toggle="tooltip" data-bs-placement="top" title="再読み込み" style="float: right;margin-right: 50px;cursor: pointer;">
				<font-awesome-icon icon="fa-redo-alt" @click="reloadPage" />
			</span>
		</h2>
		<header class="mt-3 mb-4">
			<div class="row justify-content-center">
				<div class="col-lg-8 row">
					<p>DICOMイメージをサーバーへ送信する前にクライアントサイドで匿名化処理し、エスフィルクラウドサーバーへアップロードします。</p>
				</div>
				<!-- <div class="loading hide" ref="loadingImage">
					<span class="circle"></span>
				</div> -->
			</div>
		</header>
		<div id="InputZone" class="popup" ref="InputZone" :class="{'is-show':showFlg}">
			<span id="popup-inner_orderDetail">
				<div id="orderGroupInputZone" class="row justify-content-center border border-solid">
					<h2 style="color:black;">オーダーグループ情報入力欄</h2>
					<span>
						<label for="GroupName">グループ名：</label>
						<input name="GroupName" v-model="GroupName" style="border: 1px solid;">
					</span>
					<span>
						<label for="DoubleReadingMode">種別：</label>
						<select name="DoubleReadingMode" id="DoubleReadingMode" v-model="DoubleReadingMode" style="border: 1px solid;">
							<option value=0>1読影</option>
							<option value=3>２重読影（オープン,１レポート）</option>
							<option value=4>２重読影（ブラインド,１レポート）</option>
						</select>
					</span>
					<span>
						<label for="Anonymously">匿名：</label>
						<label style="margin-left:0px;"><input type="checkbox" name="Anonymously" v-model="Anonymously" id="Anonymously" value=1></label>
					</span>
					<span>
						<label for="GroupComment">伝言：</label>
						<textarea name="GroupComment" v-model="GroupComment" class="messageArea"  style="border: 1px solid;"></textarea>
					</span>
				</div>

				<div id="orderInputZone" class="row justify-content-center border border-solid">
					<h2 style="color:black;">オーダー情報入力欄</h2>
					<span>
						<label for="Applicant">作成者：</label>
						<input name="Applicant" v-model="Applicant"  style="border: 1px solid;">
					</span>
					<span>
						<label for="InterpretationRequests">読影要求：</label>
						<select name="InterpretationRequests" id="InterpretationRequests" v-model="InterpretationRequests" style="border: 1px solid;">
							<option value=0>通常</option>
							<option value=1>優先</option>
							<option value=2>緊急</option>
						</select>
						<label for="OutPatientHospital" style="margin-left:20px;">入院外来：</label>
						<select name="OutPatientHospital" id="OutPatientHospital" v-model="OutPatientHospital" style="border: 1px solid;">
							<option value=-1>なし</option>
							<option value=0>入院</option>
							<option value=1>外来</option>
							<option value=2>受託</option>
							<option value=3>健診</option>
						</select>
						<label for="Departments" style="margin-left:20px;">診療科：</label>
						<select name="Departments" id="Departments" v-model="Departments"  style="border: 1px solid;">
							<option value="整形外科">整形外科</option>
							<option value="内科">内科</option>
							<option value="外科">外科</option>
							<option value="健診科">健診科</option>
							<option value="放射線科">放射線科</option>
							<option value="その他">その他</option>
						</select>
					</span>
					<span>
						<label for="LedgerNumber">台帳番号：</label>
						<input name="LedgerNumber" v-model="LedgerNumber"  style="border: 1px solid;">
					</span>
					<span>
						<label for="EditedPatientName">患者名：</label>
						<input name="EditedPatientName" v-model="EditedPatientName"  style="border: 1px solid;">
					</span>
					<span>
						<label for="EditedPatientIdeogram">漢字：</label>
						<input name="EditedPatientIdeogram" v-model="EditedPatientIdeogram"  style="border: 1px solid;">
					</span>
					<span>
						<label for="EditedPatinePhonetic">フリガナ：</label>
						<input name="EditedPatinePhonetic" v-model="EditedPatinePhonetic"  style="border: 1px solid;">
					</span>
					<span>
						<label for="BodyPart">部位：</label>
						<input name="BodyPart" v-model="BodyPart" style="border: 1px solid;">
					</span>
					<br>
					<span>
						<label for="AttendingPhysician">主治医：</label>
						<input name="AttendingPhysician" v-model="AttendingPhysician"  style="border: 1px solid;">
					</span>
					<span>
						<label for="Protocol">プロトコル：</label>
						<input name="Protocol" v-model="Protocol"  style="border: 1px solid;">
					</span>
					<span>
						<label for="Operator">オペレータ：</label>
						<input name="Operator" v-model="Operator"  style="border: 1px solid;">
					</span>
					<span>
						<label for="ContrastAgent">造影剤：</label>
						<textarea name="ContrastAgent" v-model="ContrastAgent" class="messageArea" style="border: 1px solid;"></textarea>
					</span>
					<span>
						<label for="ClinicalInfo">臨床情報：</label>
						<textarea name="ClinicalInfo" v-model="ClinicalInfo" class="messageArea" style="border: 1px solid;"></textarea>
					</span>
					<span>
						<label for="StudyComments">検査伝言：</label>
						<textarea name="StudyComments" v-model="StudyComments" class="messageArea" style="border: 1px solid;"></textarea>
					</span>
					<span>
						<label for="OrderComments">依頼伝言：</label>
						<textarea name="OrderComments" v-model="OrderComments" class="messageArea" style="border: 1px solid;"></textarea>
					</span>
					<span>
						<label for="OrderDocument">伝票：</label>
						<div id="dropZone_OD" style="border: 1px solid; padding: 30px; height: 300px;">
							<p>伝票ファイルをドラッグ＆ドロップもしくは</p>
							<input type="file" name="OrderDocument" id="OrderDocument" accept="image/png, image/jpeg">
							<button id = "filedelete" @click="fileDelete()">ファイル削除</button>
							<div id="preview"></div>
						</div>
					</span>
				</div>
				<span style="float:right;">
					<button class="btn btn-sm btn-info" id="orderStart" ref="orderStart" @click="orderStart()">オーダー</button>
					<button class="btn btn-sm btn-info" id="close_btn_orderDetail" ref="close_btn_orderDetail" style="margin-left: 10px;" @click="showFlg=false">キャンセル</button>
				</span>
			</span>
			<div class="black-background" id="black_bg_orderDetail" ref="black_bg_orderDetail" @click="showFlg=false"></div>
		</div>

		<div class="row justify-content-center">
			<div id="dropZone" class="container row justify-content-center col-lg-8 py-3"  style="border: 1px solid; padding: 30px;">
				<p style="color:gray;">こちらにDICOMファイルをドラッグ＆ドロップしてください</p>
				<div class="col-lg-12 px-0">
					<div id="status" class="alert alert-info">
						<div id="statusText">
							Status: ready_check (no file loaded)
						</div>
						<ul id="warnings"></ul>
					</div>

					<div id="dicon-preview-wrapper" class="row justify-content-center flex-column w-100 mx-auto hide-preview" style="position:relative; margin-bottom: 225px;">
						<div class="col p-1 bg-secondary text-white rounded-top">File Preview</div>
						<div class="col d-flex center-block py-1 overflow-auto" id="ImagePreviewZone" ref="ImagePreviewZone">
							<div 
								v-for="(item,index) in ImagePreviewList" 
								v-bind:key="index" class='imagelist' 
								v-bind:studynumber="item.stnum" 
								v-bind:seriesnumber_study="item.senum" 
								v-html="item.image" 
								@wheel.prevent="viewWheelControl_image($event)"
								style="min-width: 200px; min-height: 200px; position:relative; margin:0 5px 0 5px;">
							</div>
						</div>
						<div class="col d-flex d-none justify-content-center py-1" id="ImageInputZone">
							<div v-for="n in filecount" :key="n" v-bind:id="'dicom-preview' + n" class='image' v-bind:filenumber="n" style="width: 200px; height: 200px; position:absolute;"></div>
						</div>

					</div>
				</div>
				<div v-show="showGenre !== ''" id="overlay" @click.stop="showGenre=''">
					<div v-show="showGenre == 'dumpProgress'" class="popup_progress">
						<div @click="showGenre=''" style="float:right;">&#10006;</div>
						<h3>
							<div id="checkfile">
								<div>{{message_status_cf}}</div>
								<progress id="myProgress_cf" class="loading22" value="0" max="100">0%</progress>
								<span id="message_progress_cf"></span>
							</div>
							<div id="dumpfile">
								<div>{{message_status_df}}</div>
								<progress id="myProgress_df" class="loading22" value="0" max="100">0%</progress>
								<span id="message_progress_df"></span>
							</div>
						</h3>
					</div>
					<div v-show="showGenre == 'orderProgress'" class="popup_orderprogress">
						<div @click="showGenre=''" style="float:right;">&#10006;</div>
						<div class="list-table mb-4 mt-5 progressZone">
							<table class="table table-striped table-bordered">
								<thead>
									<tr>
										<template v-for="(item,index) in studyitems_progress" v-bind:key="index">
											<th v-if="item.code!='progress'" v-bind:class="'px-2 py-1 align-middle ' + item.class" style="background-color: #D3D3D3;">
												{{ item.name }}
											</th>
											<th v-else ref="orderProgressHeader" class='px-2 py-1 align-middle sticky-x progressColumn' style="background-color: #D3D3D3;width:50px;">進捗</th>
										</template>
									</tr>
								</thead>
								<tbody ref="studyBody_progress">
									<tr v-for="( data, n ) in StudyProgressDataList" :key="n" class='studyRow mouse_hover'>
										<template v-for="(item,index) in studyitems_progress" v-bind:key="index">
											<td v-if="item.code!='progress'" v-bind:class="'col-sm-4 px-2 py-1 align-middle ' + item.class" >
												<span class="study" v-bind:data-dicom="item.code">{{ data[item.code] }}</span>
											</td>
											<td v-else class='col-sm-4 px-2 py-1 align-middle sticky-x progressColumn' style="background-color: #E6E6E6;">
												<progress :max="data[item.code].max" :value="data[item.code].value" class="progressBar" style="width:6rem;"></progress>
												<div class="progressMessage">{{ data[item.code].message }}</div>
											</td>
										</template>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="text-end mt-3">
				<button id="allUpload" class="allstudy btn btn-sm btn-info" disabled="disabled" style="display:none;" @click="select_storeData()">一括個別オーダー</button>
				<button id="concludeUpload" class="allstudy btn btn-sm btn-info" disabled="disabled" @click="editOrder($event,'conclude')">まとめオーダー</button>
				<!-- <button id="progressShow" class="allstudy btn btn-sm btn-info" disabled="disabled" @click="showGenre='orderProgress'">進捗表示</button> -->
				<!-- <button id="progressShow" class="allstudy btn btn-sm btn-info animate_progress" @click="showGenre='orderProgress'">進捗表示</button> -->
				<div id="progressShow" class='allstudy btn  btn-sm btn-info ProgressCheck' :class="{ 'ordering': ordering }" disabled="disabled" @click="showGenre='orderProgress'">進捗確認</div>

			</div>

			<div class="list-table mb-4">
				<table id="studyTable" class="table table-striped table-bordered">
					<thead>
						<tr>
							<td v-bind:class="'px-2 py-1 align-middle ' + item.class" v-for="(item,index) in studyitems" v-bind:key="index">
								{{ item.name }}
							</td>
							<td ref="orderHeader" class="px-2 py-1 align-middle sticky-x2" style="background-color: #D3D3D3;">
								<div class="text-primary" role="status">オーダー</div>
							</td>
							<!-- <td ref="orderProgressHeader" class='px-2 py-1 align-middle sticky-x progressColumn' style="background-color: #D3D3D3;width:50px;">進捗</td> -->
						</tr>
					</thead>
					<tbody ref="studyBody">
						<tr v-for="( data, n ) in StudyViewDataList" :key="n" class='studyRow mouse_hover' v-bind:studynumber="n+1">
							<!-- <td>
								<input type="checkbox" class="form-check-input selectOrders">
							</td> -->
							<td v-bind:class="'col-sm-4 px-2 py-1 align-middle ' + item.class" v-for="(item,index) in studyitems" v-bind:key="index" @click="viewControl_series($event,'study')">
								<span class="study" v-bind:data-dicom="item.code" v-bind:studynumber="n+1">{{ data[item.code] }}</span>
							</td>
							<td class="sticky-x" style="background-color: #E6E6E6;">
								<button class="study orderButton btn btn-sm btn-info" style="width: max-content;" v-bind:studynumber="n+1" @click="editOrder($event,'study')">オーダー</button>
							</td>
							<!-- <td class='col-sm-4 px-2 py-1 align-middle sticky-x progressColumn' style="background-color: #E6E6E6;">
								<progress max="100" value="0" class="d-none progressBar" style="width:6rem;"></progress>
								<div class="progressMessage"></div>
							</td> -->
						</tr>
					</tbody>
				</table>
			</div>

			<div class="list-table">
				<table id="seriesTable" class="table table-striped table-bordered">
					<thead>
						<tr>
							<td v-bind:class="'px-2 py-1 align-middle ' + item.class" v-for="(item,index) in seriesitems" v-bind:key="index">
								{{ item.name }}
							</td>
							<!-- <td class="px-2 py-1 align-middle">
								<div class="text-primary" role="status">オーダー</div>
							</td> -->
						</tr>
					</thead>
					<tbody ref="seriesBody">
						<tr v-for="( data, n ) in SeriesViewDataList" :key="n+1" class='seriesRow mouse_hover'>
							<td v-bind:class="'col-sm-4 px-2 py-1 align-middle ' + item.class" v-for="(item,index) in seriesitems" v-bind:key="index" @click="viewControl_image($event,'series')">
								<span class="series" v-bind:data-dicom="item.code" v-bind:seriesnumber="n+1">{{ data[item.code] }}</span>
							</td>
							<!-- <td>
								<button class="series btn btn-sm btn-info" style="width: max-content;" v-bind:seriesnumber="n+1" @click="storeData($event,'series')">オーダー</button>
							</td> -->
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>


<script>
/* eslint no-irregular-whitespace: ["error", {"skipRegExps": true}] */

	import axios from "axios";
	import rd from "./readDicom"
	import { convertBytes } from 'dicom-character-set';


	export default {
		name: "DicomParser",
		data() {
			var studyCollumns =  [
				{ name: '患者名', code: 'x00100010', class:'' },
				{ name: '患者ID', code: 'x00100020', class:'' },
				{ name: '生年月日', code: 'x00100030', class:'' },
				{ name: '年齢', code: 'x00101010', class:'' },
				{ name: '性別', code: 'x00100040', class:'' },
				{ name: '検査日付', code: 'x00080020', class:'' },
				{ name: '検査時間', code: 'x00080030', class:'' },
				{ name: 'モダリティ', code: 'x00080060', class:'' },
				{ name: '部位', code: 'x00180015', class:'' },
				{ name: 'シリーズ', code: 'seriescount', class:'' },
				{ name: '画像', code: 'imagecount_study', class:'' },
				{ name: 'Study Instance UID', code: 'x0020000d', class:'hide' }
			]
			return{
				filecount:0,
				filecount_onettime:0,
				okcmpFileNum:0,
				cmpcount_check:0,
				ready_check:false,
				cmpcount_dump:0,
				ready_dump:false,
				noTargetcount:0,
				studycount:0,
				seriescount:0,
				okFileEntryList:[],
				okFileList:[],
				studylist:[],
				serieslist:[],
				SOPlist :[],
				dataSetList:[],
				StudydataSetList:[],
				SeriesdataSetList:[],
				StudyViewDataList:[],
				SeriesViewDataList:[],
				ImagePreviewList:[],
				ImagePreviewList_old:[],
				studyitems: studyCollumns,
				seriesitems: [
					{ name: 'モダリティ', code: 'x00080060', class:'' },
					{ name: 'シリーズ番号', code: 'x00200011', class:'' },
					{ name: '部位', code: 'x00180015', class:'' },
					{ name: '記述', code: 'x0008103e', class:'' },
					{ name: 'シリーズ日付', code: 'x00080021', class:'' },
					{ name: 'シリーズ時間', code: 'x00080031', class:'' },
					{ name: 'コメント', code: 'x00204000', class:'' },
					{ name: '画像', code: 'imagecount_series', class:'' },
					{ name: 'Series Instance UID', code: 'x0020000e', class:'hide' }
                ],
				studyitems_progress: [
					{ name: 'オーダー日時', code: 'current_orderDate', class:'' },
					...studyCollumns,
					{ name: '進捗', code: 'progress', class:'' },
                ],
				message: "",
				message_json: "",
				message_send: "",
				message_send_error: "",
				message_store: "",
				message_progress_cf:"",
				message_progress_df:"",
				message_status_cf:"",
				message_status_df:"",
				progres_finish_flg: false,
				progressCount_total_cf:0,
				progressCount_now_cf:0,
				progressCount_total_df:0,
				progressCount_now_df:0,
				postcount:0,
				sendDCM_cmp_check: false,
				sendJson_cmp_check: false,
				storeData_cmp_check: false,
				Base64text: "",
				dcm_CmpCount:0,
				seriesnumber_study:0,
				read_check:false,
				targetNum_study:0,
				targetNum_series:0,
				postMax_study:0,
				postMax_series:0,
				postMax_image:0,
				storeList:[],
				intervalID_checkfile:"",
				intervalID_dumpfile:"",
				orderUID_res:"",
				GroupName: "",
				DoubleReadingMode: "",
				Anonymously: "",
				GroupComment: "",
				Applicant: "",
				InterpretationRequests: "",
				OutPatientHospital: "",
				Departments: "",
				LedgerNumber: "",
				EditedPatientName: "",
				EditedPatientIdeogram: "",
				EditedPatinePhonetic: "",
				BodyPart: "",
				AttendingPhysician: "",
				Protocol: "",
				Operator: "",
				ContrastAgent: "",
				ClinicalInfo: "",
				StudyComments: "",
				OrderComments: "",
				OrderDocument: null,
				title: '読影オーダー（DICOM)',
				btnHide: true,
				TargetModality: "",
				errorConclude:{},
				wheelCountFlg:false,
				imageCreated_check:false,
				orderJson:"",
				queue: [], // キュー
				queue_exe_check:false,
				nextEventDom: "",
				// size_change_progress:0, //値が変わるたびに進捗列の横幅を取得し、オーダー列の位置を固定する
				ProgressDomList:[],
				orderErrorCount:0,
				showGenre:"",
				StudyProgressDataList:[],
				ordering:false,
				showFlg:false,
				eventDom:"",
				genre:"",
			}
		},
		// watch: {
		// 	size_change_progress:{
		// 		handler(){
		// 			// DOM要素が作成されてから処理。最右列から２列目（オーダー列）をスクロール固定
		// 			this.$nextTick(() => {
		// 				let width = this.$refs.orderProgressHeader.offsetWidth
		// 				let elements = this.$refs.studyBody.getElementsByClassName("sticky-x2")
		// 				for(let i = 0; i < elements.length; i++){
		// 					elements[i].style.right = width + "px"
		// 				}
		// 				this.$refs.orderHeader.style.right = width + "px"
		// 			});
		// 		},
		// 		deep: true
		// 	}
		// },
		methods: {
			reloadPage() {
				// thisを使ってwindow.location.reload()を呼び出す
				window.location.reload();
			},
			async handleFileSelect(evt) {
				evt.preventDefault();
				const entries = [];
				for (var i = 0; i < evt.dataTransfer.items.length; i++) {
					entries.push(evt.dataTransfer.items[i].webkitGetAsEntry());
				}

				const files = [];
				await this.readfile(files,entries)
				evt.stopPropagation();
				evt.preventDefault();
				document.getElementById("dropZone").style.background = '#ffffff';
				document.getElementById('dicon-preview-wrapper').classList.remove('hide-preview')
				document.getElementById('dicon-preview-wrapper').classList.add('show-preview')
				document.getElementById('allUpload').disabled = false;
				document.getElementById('concludeUpload').disabled = false;
				document.getElementById('InputZone').style.display = "block";

				// 正常なdcmファイル以外を除外
				this.checkFiles(files);
				var _this = this

				var checkcmp = function() {
					if (_this.ready_check === true) {
						// ファイルの中身を見てDOM要素作成
						_this.dumpFiles();
						_this.ready_check = false;
						return;
					}
					setTimeout(checkcmp, 200);
				}
				checkcmp();

				var dumpcmp = async function() {
					if (_this.ready_dump === true) {
						// 各DOM要素に値をセット
						// _this.dumpDataSet_study();
						_this.dumpDataSet_series();
						await _this.dumpDataSet_image();

						// 入力欄に値を編集
						_this.settingInputZone()
						// シリーズ・画像項目の集計
						_this.countSet()
						// _this.size_change_progress = 1;
						var execount = 0;
						_this.okFileList.splice(0)
						for( var l=0; l < _this.okFileEntryList.length; l++ ){
							_this.okFileEntryList[l].file(function (file) {
									execount += 1;
									// Get WadoUri
									rd.readDicom(file,(execount))
									_this.okFileList.push(file)
							});
						}

						_this.ready_dump = false;
						return;
					}
					setTimeout(dumpcmp, 200);
				}
				dumpcmp();
			},

			handleDragOver(evt) {
				evt.stopPropagation();
				evt.preventDefault();
				evt.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
				document.getElementById('dropZone').style.background = '#e1e7f0';
			},
			async readfile(files,entries){
				for (const entry of entries) {
					await this.scanFiles(entry, files);
				}
				return;
			},
			async scanFiles(entry, tmpObject) {
				var _this = this
				async function readEntriesAsync(reader) {
					return new Promise((resolve, reject) => {
						reader.readEntries(entries => {
							resolve(entries);
						}, error => reject(error));
					})
				}
				async function enumerateDirectoryWithManyFiles(directoryEntry) {
					let reader = directoryEntry.createReader();
					let resultEntries = [];

					let read = async function() {
						let entries = await readEntriesAsync(reader);
						if (entries.length > 0) {
							resultEntries = resultEntries.concat(entries);
							await read();
						}
					}

					await read();
					return resultEntries;
				}
				switch (true) {
					case (entry.isDirectory) : {
						let entries = await enumerateDirectoryWithManyFiles(entry);
						await Promise.all(entries.map(async function (entry) {await _this.scanFiles(entry, tmpObject); }));
						break;
					}
					case (entry.isFile) : {
						console.log("ファイルあり")
						tmpObject.push(entry);
						break;
					}
				}
				return;

            },
			// 正常なdcmファイル以外を除外
			checkFiles(files){
				//ドラッグアンドドロップするたびにテーブルを上書きするため、各変数を初期化  2023/11/21追加---------------------------
				this.filecount_onettime = 0 
				this.okFileEntryList = [];
				this.okFileList = [];
				this.studylist = [];
				this.serieslist = [];
				this.SOPlist = [];
				this.dataSetList = [];
				this.StudydataSetList = [];
				this.SeriesdataSetList = [];
				this.StudyViewDataList = [];
				this.SeriesViewDataList = [];
				this.ImagePreviewList = [];
				this.ImagePreviewList_old = [];
				this.studycount = 0;
				this.seriescount = 0;
				console.log("ファイルリスト初期化")
				// ----------------------------------------------------------------------------------------------------------------

				console.log("ファイルチェック開始")
				// 画像アップロード進捗表示
				this.okcmpFileNum = this.okFileEntryList.length
				this.progres_finish_flg = false
				this.showGenre = 'dumpProgress'
				this.message_status_cf = "アップロードファイルチェック中・・・"
				this.progressCount_now_cf = 0
				this.progressCount_total_cf = files.length
				// document.getElementById("checkfile").style.display = "block"
				document.getElementById("dumpfile").style.display = "none"
				var _this = this
				_this.intervalID_checkfile = setInterval(function() {_this.updateProgress(_this.progressCount_now_cf,_this.progressCount_total_cf,document.getElementById('myProgress_cf'),document.getElementById('message_progress_cf'))}, 50);
				_this.filecount = 0
				for( var i=0; i < files.length; i++ ){
					this.checkFile(files[i],files.length)
				}
			},
			// 正常なdcmファイル以外を除外
			checkFile(fileEntry,maxfile) {
				console.log("正常なdcmファイル以外を除外")
				console.log(fileEntry)
				// jqueryもしくはjsのfunction使用中にthisの内容が変わってしまうため、変数に代入
				var _this = this
				var dataSet ;

				document.getElementById('status').classList.remove('alert-warning')
				document.getElementById('status').classList.remove('alert-success')
				document.getElementById('status').classList.remove('alert-danger')
				document.getElementById('status').classList.add('alert-info')
				document.getElementById('statusText').innerHTML = 'Status: Loading file, please wait..';
				document.getElementById('warnings').innerHTML = ''


				fileEntry.file(function (file) {
					var reader = new FileReader();
					reader.onload = function(file) {
						console.log("ファイル読込開始")
						var arrayBuffer = reader.result;
						// Here we have the file data as an ArrayBuffer.  dicomParser requires as input a
						// Uint8Array so we create that here
						var byteArray = new Uint8Array(arrayBuffer);
						console.log(file)
						var kb = byteArray.length / 1024;
						var mb = kb / 1024;
						var byteStr = mb > 1 ? mb.toFixed(3) + " MB" : kb.toFixed(0) + " KB";
						document.getElementById('statusText').innerHTML = '<span class="glyphicon glyphicon-cog"></span>Status: Parsing ' + byteStr + ' bytes, please wait..';
						// set a short timeout to do the parse so the DOM has time to update itself with the above message
						// Invoke the paresDicom function and get back a DataSet object with the contents
						try {
							console.log("ファイル読込開始")
							_this.progressCount_now_cf += 1;
							_this.cmpcount_check += 1;
							dataSet = rd.dicomparser(byteArray);
							var siuid = dataSet.string('x0020000d');
							var seiuid = dataSet.string('x0020000e');
							var SOPiuid = dataSet.string('x00080018');
							_this.TargetModality = dataSet.string('x00080060')
							var searchResult_file = _this.SOPlist.find((v) => v.siuid === siuid && v.seiuid === seiuid && v.SOPiuid === SOPiuid);
							// ファイル名のみ異なる同一ファイルを削除
							if(!searchResult_file){
								// モダリティがSRまたはPR（StructoredReport 照射線量報告などに用いられているデータ）は除外
								if(_this.TargetModality != "SR" && _this.TargetModality != "PR"){
									_this.okFileEntryList.push(fileEntry);
									_this.dataSetList.push(dataSet);
									_this.SOPlist.push({siuid: siuid, seiuid: seiuid, SOPiuid: SOPiuid});
									_this.filecount_onettime += 1;
								}else{
									console.log('読影対象のファイルはありません。（エラー1）');
								}
							}else{
								console.log('読影対象のファイルはありません。（エラー2）');
							}
						}
						catch(err){
							console.log('読影対象のファイルはありません。（エラー3）');
							console.log(err)
						}
						finally{
							if((_this.cmpcount_check) == maxfile){
								_this.filecount = _this.filecount_onettime;
								_this.ready_check = true;
								_this.cmpcount_check = 0;
								console.log("ファイル読込完了")
							}
						}

					}

					reader.onerror = function (event) {
						console.log(event)
						// errorCallback(reader.error);
					}

					reader.readAsArrayBuffer(file);
				});
			},
			// ファイルの中身を見てDOM要素作成
			dumpFiles(){
				if(this.okFileEntryList.length !== this.okcmpFileNum){
					this.progressCount_now_df = 0
					this.progressCount_total_df = this.okFileEntryList.length - this.okcmpFileNum
					this.message_status_df = "画像アップロード中・・・"
					// document.getElementById("checkfile").style.display = "none"
					document.getElementById("dumpfile").style.display = "block"
					var _this = this
					_this.intervalID_dumpfile = setInterval(function() {_this.updateProgress(_this.progressCount_now_df,_this.progressCount_total_df,document.getElementById('myProgress_df'),document.getElementById('message_progress_df'))}, 50);
				}else{
					this.showGenre = ""
				}
				for( var j=0; j < this.okFileEntryList.length; j++ ){
					// this UI is only built for a single file so just dump the first one
					this.dumpFile(this.okFileEntryList[j], j, this.okFileEntryList.length);
				}
			},
			// ファイルの中身を見てDOM要素作成
			dumpFile(fileEntry,filenum,maxfile) {
				var dataSet;
				document.getElementById('status').classList.remove('alert-warning')
				document.getElementById('status').classList.remove('alert-success')
				document.getElementById('status').classList.remove('alert-danger')
				document.getElementById('status').classList.add('alert-info')
				document.getElementById('warnings').innerHTML = ''
				document.getElementById('statusText').innerHTML = 'Status: Loading file, please wait..';

				// jqueryもしくはjsのfunction使用中にthisの内容が変わってしまうため、変数に代入
				var _this = this
				fileEntry.file(function (file) {
					var reader = new FileReader();
					reader.onload = async function(file) {
						var arrayBuffer = reader.result;
						// Here we have the file data as an ArrayBuffer.  dicomParser requires as input a
						// Uint8Array so we create that here
						var byteArray = new Uint8Array(arrayBuffer);
						console.log("ファイル表示")
						console.log(file)
						var kb = byteArray.length / 1024;
						var mb = kb / 1024;
						var byteStr = mb > 1 ? mb.toFixed(3) + " MB" : kb.toFixed(0) + " KB";
						document.getElementById('statusText').innerHTML = '<span class="glyphicon glyphicon-cog"></span>Status: Parsing ' + byteStr + ' bytes, please wait..';
						// set a short timeout to do the parse so the DOM has time to update itself with the above message

						// Invoke the paresDicom function and get back a DataSet object with the contents
						try {
							var start = new Date().getTime();
							dataSet = rd.dicomparser(byteArray);
							_this.DuplicateCheck(dataSet,filenum,byteArray)
							var end = new Date().getTime();
							var time = end - start;
							if(dataSet.warnings.length > 0)
							{
								document.getElementById('status').classList.remove('alert-success')
								document.getElementById('status').classList.remove('alert-info')
								document.getElementById('status').classList.remove('alert-danger')
								document.getElementById('status').classList.add('alert-warning')
								document.getElementById('statusText').innerHTML = 'Status: Warnings encountered while parsing file (file of size '+ byteStr + ' parsed in ' + time + 'ms)';

								dataSet.warnings.forEach(function(warning) {
									document.getElementById('warnings').insertAdjacentHTML('beforeend','<li>' + warning +'</li>')
								});
							} else {
								var pixelData = dataSet.elements.x7fe00010;
								if(pixelData) {
									document.getElementById('status').classList.remove('alert-warning')
									document.getElementById('status').classList.remove('alert-info')
									document.getElementById('status').classList.remove('alert-danger')
									document.getElementById('status').classList.add('alert-success')
									document.getElementById('statusText').innerHTML = 'Status: ready_check (file of size '+ byteStr + ' parsed in ' + time + 'ms)';
								} else {
									document.getElementById('status').classList.remove('alert-warning')
									document.getElementById('status').classList.remove('alert-info')
									document.getElementById('status').classList.remove('alert-danger')
									document.getElementById('status').classList.add('alert-success')
									document.getElementById('statusText').innerHTML = 'Status: ready_check - no pixel data found (file of size ' + byteStr + ' parsed in ' + time + 'ms)';
								}
							}

							// Create de-identified values for each element
							document.querySelectorAll('input[data-dicom]').forEach(function(input) {
								var attr = input.getAttribute('data-dicom');
								var element = dataSet.elements[attr];
								var text = "";
								var vr = input.getAttribute('data-vr');
								if(element !== undefined)
								{
									var str = dataSet.string(attr);
									if(str !== undefined) {
										text = str;
									}
								}
								var deIdentifiedValue = this.makeDeIdentifiedValue(text.length, vr);
								input.value = deIdentifiedValue;
								input.readonly = "true"
							});

							_this.cmpcount_dump += 1;
							if((_this.cmpcount_dump) == maxfile){
								_this.read_check = true;
								_this.ready_dump = true;
								_this.cmpcount_dump = 0;
							}
						}
						catch(err)
						{
							document.getElementById('status').classList.remove('alert-success')
							document.getElementById('status').classList.remove('alert-info')
							document.getElementById('status').classList.remove('alert-warning')
							document.getElementById('status').classList.add('alert-danger')
							document.getElementById('statusText').innerHTML = 'Status: Error - ' + err + ' (file of size ' + byteStr + ' )';
							console.log(err)
							_this.showGenre = ""
							clearInterval(_this.intervalID_dumpfile)
						}
					};

					reader.readAsArrayBuffer(file);
				});
			},
			// スタディ、シリーズの重複をまとめる処理
			DuplicateCheck(dataSet,filenum,byteArray){
				console.log("重複チェック開始")
				// スタディ重複チェック
				var siuid = dataSet.string('x0020000d');
				var SpecificCharacterSet = dataSet.string('x00080005');
				var someStudy = this.studylist.some(b => b.siuid === siuid);
				if(!someStudy){
					this.studycount += 1;
					this.studylist.push({id: this.studycount, siuid: siuid});
					this.StudydataSetList.push(dataSet);
					//スタディの値を各DOM要素に編集
					let columnDict = {}
					for (let j=0; j < this.studyitems.length; j++){
						let code = this.studyitems[j]["code"];
						let str =dataSet.string(code);
						if(str !== undefined) {
							if(code == "x00100010"){
								str = this.strEncode(SpecificCharacterSet,dataSet,byteArray)
							}

							// 日付をyyyy/mm/dd形式に変換
							if(code == "x00080020"){
								let year = str.substr(0,4)
								let month = str.substr(4,2)
								let date = str.substr(6,2)
								str = year + "/" + month + "/" + date
							}
							// 時間をhh:mm:ss形式に変換
							if(code == "x00080030"){
								let hour = str.substr(0,2)
								let minute = str.substr(2,2)
								let second = str.substr(4,2)
								str = hour + ":" + minute + ":" + second
							}
						}
						columnDict[code] = str;
					}
					console.log("ファイル追加：" + siuid)
					console.log(columnDict)
					this.StudyViewDataList.push(columnDict);
					console.log(this.StudyViewDataList)
				}else{
					console.log("重複ファイル：" + siuid)
				}

				// シリーズ重複チェック
				var seiuid = dataSet.string('x0020000e');
				// １スタディ中のシリーズ数チェック
				var someSeries_studycheck = this.serieslist.some(d => d.siuid === siuid);
				var someSeries = this.serieslist.some(c => c.siuid === siuid && c.seiuid === seiuid);

				console.log("------------------------------------------")
				console.log(this.seriesnumber_study)

				if(!someSeries){
					if(someSeries_studycheck){
						this.seriesnumber_study += 1;
					}else{
						this.seriesnumber_study = 1;
					}
				}else{
					console.log("同シリーズ")
				}

				if(!someSeries){
					this.seriescount += 1;
					this.serieslist.push({siuid: siuid, id: this.seriescount, seiuid: seiuid,seriesnumber_study: this.seriesnumber_study});
					this.SeriesdataSetList.push(dataSet);
					// シリーズの値を各DOM要素に編集
					let columnDict = {}
					for (let j=0; j < this.seriesitems.length; j++){
						let code = this.seriesitems[j]["code"];
						let str =dataSet.string(code);
						if(str !== undefined) {
							// 日付をyyyy/mm/dd形式に変換
							if(code == "x00080021"){
								let year = str.substr(0,4)
								let month = str.substr(4,2)
								let date = str.substr(6,2)
								str = year + "/" + month + "/" + date
							}
							// 時間をhh:mm:ss形式に変換
							if(code == "x00080031"){
								console.log("シリーズタイム：" + str)
								let hour = str.substr(0,2)
								let minute = str.substr(2,2)
								let second = str.substr(4,2)
								str = hour + ":" + minute + ":" + second
							}
						}
						columnDict[code] = str;
					}
					this.SeriesViewDataList.push(columnDict);
				}
				console.log("重複チェック終了")
			},
			dumpDataSet_series(){
				for( var i=0; i < this.seriescount; i++ ){
					var siuid = this.SeriesdataSetList[i].string("x0020000d");
					var seiuid = this.SeriesdataSetList[i].string("x0020000e");
					var searchResult_study = this.studylist.find((v) => v.siuid === siuid);
					var searchResult_series = this.serieslist.find((v) => v.siuid === siuid && v.seiuid === seiuid);
					document.querySelectorAll('span[seriesnumber="' + (i + 1) + '"].series').forEach(function(value,index)
					{
						value.setAttribute('studynumber',searchResult_study["id"])
						// １スタディ中の何シリーズ目かを設定
						value.setAttribute('seriesnumber_study',searchResult_series["seriesnumber_study"])
						if(index == 0){
							value.parentNode.parentNode.setAttribute('studynumber',searchResult_study["id"])
							// １スタディ中の何シリーズ目かを設定
							value.parentNode.parentNode.setAttribute('seriesnumber_study',searchResult_series["seriesnumber_study"])
						}
					});
				}
			},

			async dumpDataSet_image(){
				var imagelist_now = [];
				// var searchResult_series_old = ""
				for( let i=0; i < this.dataSetList.length; i++ ){
					var siuid = this.dataSetList[i].string("x0020000d");
					var seiuid = this.dataSetList[i].string("x0020000e");
					var searchResult_study = this.studylist.find((v) => v.siuid === siuid);
					var searchResult_series = this.serieslist.find((v) => v.siuid === siuid && v.seiuid === seiuid);
					var SOPiuid = this.dataSetList[i].string('x00080018');
					let domTarget = document.getElementById('dicom-preview' + (i+1))
					console.log(domTarget)
					domTarget.setAttribute('studynumber',searchResult_study["id"]);
					domTarget.setAttribute('seriesnumber_study',searchResult_series["seriesnumber_study"]);
					domTarget.setAttribute('SOPiuid',SOPiuid);

					var searchResult_image_now = imagelist_now.find((v) => v.siuid === siuid && v.seiuid === seiuid);
					if(searchResult_image_now){
						searchResult_image_now.imagecount = searchResult_image_now.imagecount + 1;
						domTarget.setAttribute('imagenumber_study_series',searchResult_image_now.imagecount)
					}else{
						imagelist_now.push({siuid: siuid, seiuid: seiuid, imagecount:1});
						domTarget.setAttribute('imagenumber_study_series',1)
					}
				}

				await this.showImage_onetime("show")

				await this.moveImage()

				// canvasタグの生成後に以下の処理を実行させないと再レンダリング時に、サイズが0になり、
				// display:none;の画像は対象のスタディ・シリーズを選択しても表示されなくなってしまうため、一度全て表示させて描画後に表示状態をもとに戻している
				var _this = this
				var cmpcount = 0
				var imagecheckcmp_flg = false
				let imagecheckcmp = function() {
					if(imagecheckcmp_flg === false){
						if(document.getElementById("ImagePreviewZone").querySelectorAll(".image").length !== 0 && document.getElementById("ImagePreviewZone").querySelectorAll("canvas").length !== 0){
							var image_div = document.getElementById("ImagePreviewZone").querySelectorAll(".image").length
							var image_canvas = document.getElementById("ImagePreviewZone").querySelectorAll("canvas").length
							if (image_div === image_canvas && image_div !== 0 && cmpcount == 0) {
								_this.imageCreated_check = true;
								cmpcount++;
							}
							if(_this.imageCreated_check === true && cmpcount == 1){
								_this.showImage_onetime()
								//画像がドラッグアンドドロップされる直前に選択されていたスタディまたはシリーズをクリックして再度画像表示
								var st_rows = document.querySelectorAll(".studyRow")
								for( let j=0; j < st_rows.length; j++ ){
									if(st_rows[j].style.backgroundColor == "rgb(118, 183, 239)"){
										let target = st_rows[j].childNodes[0]
										target.addEventListener('click', function(event) {
											_this.viewControl_series(event,"study")
										});
										target.click()
									}
								}
								var se_rows = document.querySelectorAll(".seriesRow")
								for( let k=0; k < se_rows.length; k++ ){
									if(se_rows[k].style.backgroundColor == "rgb(118, 183, 239)"){
										let target = se_rows[k].childNodes[0]
										target.addEventListener('click', function(event) {
											_this.viewControl_image(event,"series")
										});
										target.click()
									}
								}
								imagecheckcmp_flg = true
								_this.imageCreated_check = false;
							}
						}
						setTimeout(imagecheckcmp, 200);
					}
				}
				imagecheckcmp();

				return;
			},
			async showImage_onetime(type){
				if(document.getElementById("ImagePreviewZone").querySelectorAll(".imagelist").length !== 0){
					var imagelists = document.getElementById("ImagePreviewZone").querySelectorAll(".imagelist")
					for( let i=0; i < imagelists.length; i++ ){
						if(type == "show"){
							imagelists[i].classList.add("show_onetime")
						}else{
							imagelists[i].classList.remove("show_onetime")
						}
					}
					return;
				}else{
					return;
				}
			},
			// ImageInputZoneに入った画像をImagePreviewZoneに移動
			async moveImage(){
				// サムネイルのマウスホイールによる切り替え表示
				for( let i=0; i < this.studylist.length; i++ ){
					var imageObj_study = document.getElementById("ImageInputZone").querySelectorAll(".image[studynumber='" + (i+1) + "']")
					console.log(imageObj_study)
					var stnum = i + 1
					for( let k=0; k < imageObj_study.length; k++ ){
						var seriesnumber_study_list = {}
						var senum = imageObj_study[k].getAttribute("seriesnumber_study")
						var searchResult_image = this.ImagePreviewList.find((v) => v.stnum === stnum && v.senum === senum);
						if(searchResult_image){
							searchResult_image["image"] += imageObj_study[k].outerHTML
						}else{
							seriesnumber_study_list["stnum"] = stnum
							seriesnumber_study_list["senum"] = senum
							// htmlコンテンツをレンダリングしても表示できないため、outerHTML使用、
							seriesnumber_study_list["image"] = imageObj_study[k].outerHTML
							this.ImagePreviewList_old.push(seriesnumber_study_list)
							this.ImagePreviewList.push(seriesnumber_study_list)
						}
						this.progressCount_now_df += 1;
					}
				}
				this.progres_finish_flg = true

				// innerHTMLを空にするとvue3ではv-forが機能しなくなり、エラーになってしまうので、filecount初期化で対応
				// document.getElementById("ImageInputZone").innerHTML = ""
				this.filecount = 0

				document.getElementById("dicon-preview-wrapper").style.marginBottom = "0px"

				return;
			},
			strEncode(SpecificCharacterSet,dataSet,byteArray){
				console.log("変換前：" + byteArray);
				console.log("予約語：" + SpecificCharacterSet)
				let patientNameStr = ""
				if(SpecificCharacterSet){
					let patientNameBytes = rd.sharedCopyFile(dataSet,byteArray)
					patientNameStr = convertBytes(SpecificCharacterSet.replace(/'\\'/g,'\\\\'),patientNameBytes, {vr: 'PN'});
					console.log("変換後：" + patientNameStr);
					// 文字列が漢字のみか半角カナのみか、全角カナのみかをチェック
					patientNameStr = this.strCheck(patientNameStr)
				}else{
					console.log("変換なし患者名---------")
					console.log(dataSet.string('x00100010'))
					console.log("---------------")
					// 予約語がない場合はイコール区切りの最初の文字列のみ抽出（大抵はローマ字氏名）
					patientNameStr = dataSet.string('x00100010').split("=")[0]
				}
				return patientNameStr
			},
			strCheck(str){
				let array = str.split("=")
				var str_edited = ""
				// 第二文字列が漢字のみかチェック
				let str_second = ""
				var regexp = /^([\u{3005}\u{3007}\u{303b}\u{3400}-\u{9FFF}\u{F900}-\u{FAFF}\u{20000}-\u{2FFFF}][\u{E0100}-\u{E01EF}\u{FE00}-\u{FE02}]?)+$/mu;
				if(array.length >= 2){
					let kanjiCheck = regexp.test(array[1].replace("^","").replace(/\s+/g,''))
					if(!kanjiCheck){
						str_second = ""
					}else{
						str_second = array[1]
					}
					str_edited = array[0] + "=" + str_second
				}else {
					str_edited = array[0]
				}

				let str_third = ""
				if(array.length == 3){
					console.log("チェック前第三文字列：" + array[2].replace("^","").replace(/\s+/g,''))
					// 第三文字列が全角カナ・半角カナのみかチェック
					regexp = new RegExp(/^[ァ-ヶー　]+$/);
					let ZenkakuKanaCheck = regexp.test(array[2].replace("^","").replace(/\s+/g,''))
					regexp = new RegExp(/^[ｦ-ﾟ]*$/);
					let HankakuKanaCheck = regexp.test(array[2].replace("^","").replace(/\s+/g,''))
					console.log(HankakuKanaCheck)
					if(!ZenkakuKanaCheck && !HankakuKanaCheck){
						str_third = ""
					}else{
						str_third = array[2]
					}
					str_edited = str_edited + "=" + str_third
				}
				console.log("チェック後：" + str_edited)
				return str_edited;
			},
			// シリーズ・画像項目の集計
			countSet(){
				let _this = this
				for( var i=0; i < this.StudyViewDataList.length; i++ ){
					console.log(this.$refs.studyBody.querySelectorAll('span[data-dicom="seriescount"].study'))
					var studynumber = this.$refs.studyBody.querySelectorAll('span[data-dicom="seriescount"].study')[i].getAttribute('studynumber');
					var count = this.$refs.seriesBody.querySelectorAll('tr[studynumber="' + studynumber + '"].seriesRow').length;
					this.StudyViewDataList[i]["seriescount"] = count;
				}
				this.$refs.studyBody.querySelectorAll('span[data-dicom="imagecount_study"].study').forEach(function(value,index)
				{
					var studynumber = value.getAttribute('studynumber');
					var count = _this.$refs.ImagePreviewZone.querySelectorAll('div[studynumber="' + studynumber + '"].image').length;
					_this.StudyViewDataList[index]["imagecount_study"] = count;
				});
				this.$refs.seriesBody.querySelectorAll('span[data-dicom="imagecount_series"].series').forEach(function(value)
				{
					var studynumber = value.getAttribute('studynumber');
					var seriesnumber = value.getAttribute('seriesnumber_study');
					var count = _this.$refs.ImagePreviewZone.querySelectorAll('div[studynumber="' + studynumber + '"][seriesnumber_study="' + seriesnumber + '"].image').length;
					value.textContent = count;
				});
			},
			makeRandomString(length){
				var text = "";
				var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

				for( var i=0; i < length; i++ )
					text += possible.charAt(Math.floor(Math.random() * possible.length));

				return text;
			},
			pad(num, size) {
				var s = num+"";
				while (s.length < size) s = "0" + s;
				return s;
			},
			makeDeIdentifiedValue(length, vr) {
				var now = ""
				if(vr === 'LO' || vr === "SH" || vr === "PN") {
					return this.makeRandomString(length);
				}
				else if(vr === 'DA') {
					now = new Date();
					return now.getYear() + 1900 + this.pad(now.getMonth() + 1, 2) + this.pad(now.getDate(), 2);
				} else if(vr === 'TM') {
					now = new Date();
					return this.pad(now.getHours(), 2) + this.pad(now.getMinutes(), 2) + this.pad(now.getSeconds(), 2);
				}
				console.log('unknown VR:' + vr);
			},
			// シリーズのDOM要素の表示切り替え
			viewControl_series(event){
				var studynumber = event.currentTarget.parentNode.getAttribute('studynumber')
				var elm = document.getElementsByClassName('seriesRow');
				for(var i=0;i<elm.length;i++){
					elm[i].style.display = 'none';
				}
				document.querySelectorAll('tr[studynumber].studyRow').forEach(function(value)
				{
					value.style.backgroundColor = 'white';
				})
				document.querySelectorAll('tr[studynumber="' + studynumber + '"].studyRow').forEach(function(value)
				{
					value.style.backgroundColor = 'rgb(118, 183, 239)';
				})
				document.querySelectorAll('tr[studynumber="' + studynumber + '"].seriesRow').forEach(function(value)
				{
					value.style.display = 'table-row';
				})

				this.viewControl_image(event,"study")
			},
			// 画像のDOM要素の表示切り替え
			viewControl_image(event,type){
				var studynumber = event.currentTarget.parentNode.getAttribute('studynumber')
				var elm = document.getElementsByClassName('imagelist')
				for(var i=0;i<elm.length;i++){
					elm[i].style.display = "none"
				}
				var target_imagelist = ""
				if(type == "study"){
					target_imagelist = document.querySelectorAll('div[studynumber="' + studynumber + '"].imagelist')
				}else{
					var seriesnumber = event.currentTarget.parentNode.getAttribute('seriesnumber_study')
					document.querySelectorAll('tr[studynumber][seriesnumber_study].seriesRow').forEach(function(value)
					{
						value.style.backgroundColor = 'white';
					});
					document.querySelectorAll('tr[studynumber="' + studynumber + '"][seriesnumber_study="' + seriesnumber + '"].seriesRow').forEach(function(value)
					{
						value.style.backgroundColor = 'rgb(118, 183, 239)';
					});

					// 対象の画像リストのみ表示
					target_imagelist = document.querySelectorAll('div[studynumber="' + studynumber + '"][seriesnumber_study="' + seriesnumber + '"].imagelist')
				}
				target_imagelist.forEach(function(value)
				{
					value.style.display = "block"
					value.childNodes.forEach(function(child,index)
					{
						if(index == 0){
							child.classList.add("show");
							child.classList.remove("showhide");
						}else{
							child.classList.remove("show");
							child.classList.add("showhide");
						}
					});
				});
			},
			viewWheelControl_image(event){
				var _this = this
				var count = 1;
				var contents = event.currentTarget.childNodes
				contents.forEach(function(target,index)
				{
					if(target.classList.contains('show') == true){
						count = index + 1
					}
				});
				// countFlgがfalseの場合だけ動く
				if (!_this.wheelCountFlg) {
					// ホイールが下方向だったら
					if (event.deltaY > 0) {
						// countの値をプラス
						count++;
						// countの値の上限をコンテンツの数とする
						if (count > contents.length) {
							count = 1;
						}
					}
					// ホイールが上方向だったら
					else if (event.deltaY < 0) {
						//countの値をマイナスにする
						count--;
						// countの値の下限を1とする
						if (count < 1) {
							// count = 1;
							count = contents.length;
						}
					}
					// countFlgをtrueにする
					_this.wheelCountFlg = true;
					
					// 数秒後、countFlgをfalseにして、またホイールのイベントで動くように
					setTimeout(function () {
						_this.wheelCountFlg = false;
					},100 ); // 秒数を指定。ミリ秒
					// 一旦コンテンツを全部非表示にし、
					for (var i = 0; i < contents.length; i++) {
						contents[i].classList.remove('show'); // showクラスを削除して非表示に
						contents[i].classList.add("showhide");
					}
					// 該当コンテンツのみ表示
					contents[count - 1].classList.add('show'); // showクラスを付与して表示
					contents[count - 1].classList.remove("showhide");
				}
			},
			// オーダー情報入力欄を開く
			async editOrder(event, genre) {
				this.clearInputZone();
				this.showFlg = true
				this.eventDom = event;
				this.genre = genre
			},
			orderStart(){
				this.showFlg = false
				this.queue.push({ process: "storeData",nexteventDom:this.eventDom });
				this.executeQueue(this.genre);
			},
			// Axiosの処理をキューから実行
			async executeQueue(genre) {
				if (this.queue.length > 0) {
					if(!this.queue_exe_check){
						console.log("残キュー：" + this.queue.length)
						this.queue_exe_check = true
						const { process,nexteventDom } = this.queue.shift();
						await this[process](nexteventDom, genre)
						this.queue_exe_check = false
						// 連続してオーダーが発生した場合、前のオーダーの完了を待って次のオーダーを処理
						this.executeQueue(genre)
					}
				}
			},
			// 画像、JSONをサーバーにアップロード
			async storeData(event,item){
				console.log("キューから処理実行")
				document.getElementById('progressShow').disabled = false;
				this.ordering = true
				// if(this.size_change_progress == 1){
				// 	this.size_change_progress = 2;
				// }
				var _this = this
				let orderJson = {
					OrderGroup:{
						Name:"",
						Order:{
							OrderDate:"",
							OrderTime:"",
							Anonymously:1,
							ReadingSystem:"EsPACS",
							ReadingMode:"EsPACS",
							DoubleReadingMode:0,
							OrderParent:"",
							OrderInstitution:"",
							OrderDepartment:"",
							ImageInfo:[],
							OrderInfo:{
								LedgerNumber:"",
								Applicant:"読影管理",
								InterpretationRequests:0,
								OutPatientHospital:0,
								Departments:"整形外科",
								AttendingPhysician:"佐々木",
								Protocol:"",
								Operator:"",
								ContrastAgent:"",
								ClinicalInfo:"臨床情報1",
								StudyComments:"",
								OrderDocument:{
									Format:"",
									Encode:"",
									Image:""
								},
								OrderComments:"",
								EditedPatientName:"",
								EditedPatientIdeogram:"",
								EditedPatinePhonetic:""
							}
						}
					}
				}
				this.orderJson = orderJson
				this.dcm_CmpCount = 0

				if (item == "study") {
					this.targetNum_study = event.target.closest("tr").getAttribute('studynumber');
					this.progressCount_total = parseInt(event.target.closest("tr").querySelector("span[data-dicom='imagecount_study']").textContent)
					console.log('スタディプログレス：' + this.progressCount_total)
				}
				// else if(item == "series"){
				// 	this.targetNum_study = event.target.closest("tr").getAttribute('studynumber');
				// 	this.targetNum_series =  event.target.closest("tr").getAttribute('seriesnumber_study');
				// 	this.progressCount_total = parseInt(event.target.closest("tr").querySelector("span[data-dicom='imagecount_series']").textContent)
				// 	console.log('シリーズプログレス：' + this.progressCount_total)
				// }
				console.log(localStorage);
				// this.$refs.loadingImage.classList.remove( 'hide' );
				console.log(localStorage);
				var studynumber = ""
				var seriesnumber = ""
				var filenumber = ""
				var studyTargets = ""
				var seriesTargets = ""
				// var imageTargets = ""

				_this.postMax_study = 0
				_this.postMax_series = 0
				// オーダーJson作成
				_this.settingJson("","group",0);
				_this.dcm_CmpCount = 0;
				_this.sendDCM_cmp_check = false;
				_this.sendJson_cmp_check = false;

				// ストア対象リスト初期化
				_this.storeList = [];

				_this.ProgressDomList = [];

				async function editSeries(imageTargets,seCount,stCount,pCount) {
					_this.postMax_image += imageTargets.length
					_this.ProgressDomList[pCount].max = _this.postMax_image * 2
					_this.ProgressDomList[pCount].message = Math.floor(_this.ProgressDomList[stCount-1].value / _this.ProgressDomList[stCount-1].max * 100) + "%完了"
					for( var k=0; k < imageTargets.length; k++ ){
						filenumber = imageTargets[k].getAttribute('filenumber');
						_this.postcount = 0;
						_this.settingJson(_this.dataSetList[filenumber - 1],"image",k);
						await _this.sendDCM(_this.dataSetList[filenumber - 1],(filenumber - 1),(k + 1),imageTargets.length,seCount,stCount)
						// document.getElementById("head").classList.remove("disabled");
					}
				}

				async function  editStudy(seriesTargets,stCount,pCount) {
					_this.postMax_series = seriesTargets.length
					for( var i=0; i < seriesTargets.length; i++ ){
						seriesnumber = seriesTargets[i].getAttribute('seriesnumber_study');
						let imageIndex = 0
						for( var k=0; k < _this.$refs.ImagePreviewZone.children.length; k++ ){
							if(_this.$refs.ImagePreviewZone.children[k].getAttribute("studynumber") == studynumber && _this.$refs.ImagePreviewZone.children[k].getAttribute("seriesnumber_study") == seriesnumber){
								imageIndex = k
							}
						}
						await editSeries(_this.$refs.ImagePreviewZone.children[imageIndex].children,(i + 1),stCount,pCount)
					}
				}

				function getCurrentDateTime() {
					const now = new Date();

					const year = now.getFullYear();
					const month = padZero(now.getMonth() + 1); // 月は0から始まるため+1する
					const day = padZero(now.getDate());
					const hours = padZero(now.getHours());
					const minutes = padZero(now.getMinutes());
					const seconds = padZero(now.getSeconds());

					return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
				}

				function padZero(num) {
					return num < 10 ? `0${num}` : num;
				}


				switch (item) {
					case 'conclude':{
						studyTargets = _this.$refs.studyBody.querySelectorAll('tr.studyRow');
						_this.progressCount_total = 0
						for( let k=0; k < studyTargets.length; k++ ){
							_this.progressCount_total += parseInt(studyTargets[k].querySelector("span[data-dicom='imagecount_study']").textContent)
						}
						console.log('オールアップロードプログレス：' + _this.progressCount_total)
						_this.postMax_study = studyTargets.length
						for( let i=0; i < studyTargets.length; i++ ){
							studynumber = i + 1
							let copyRow = Object.assign({}, _this.StudyViewDataList[studynumber - 1]);
							_this.StudyProgressDataList.push(copyRow)
							let currentDateTime = getCurrentDateTime();
							_this.StudyProgressDataList[_this.StudyProgressDataList.length - 1]["current_orderDate"] = currentDateTime
							_this.StudyProgressDataList[_this.StudyProgressDataList.length - 1]["progress"] = {"max":100,"value":0,"message":""}
							// current_orderDate プロパティに基づいて降順にソート
							_this.StudyProgressDataList.sort((a, b) => new Date(b.current_orderDate) - new Date(a.current_orderDate));
							await _this.$nextTick(async function(){
								_this.ProgressDomList[i] = _this.StudyProgressDataList[0]["progress"]
								seriesTargets = _this.$refs.seriesBody.querySelectorAll('tr[studynumber="' + studynumber + '"].seriesRow');
								console.log("スタディナンバー：" + studynumber)
								_this.postMax_image = 0
								await editStudy(seriesTargets,(i+1),i);
							})
						}
						break;
					}
					case 'study':{
						studynumber = _this.targetNum_study
						let copyRow = Object.assign({}, _this.StudyViewDataList[studynumber - 1]);
						_this.StudyProgressDataList.push(copyRow)
						let currentDateTime = getCurrentDateTime();
						_this.StudyProgressDataList[_this.StudyProgressDataList.length - 1]["current_orderDate"] = currentDateTime
						_this.StudyProgressDataList[_this.StudyProgressDataList.length - 1]["progress"] = {"max":100,"value":0,"message":""}
						// current_orderDate プロパティに基づいて降順にソート
						_this.StudyProgressDataList.sort((a, b) => new Date(b.current_orderDate) - new Date(a.current_orderDate));
						await _this.$nextTick(async function(){
							_this.ProgressDomList[0] = _this.StudyProgressDataList[0]["progress"]
							_this.postMax_study = 1
							// studynumber = event.currentTarget.closest("tr").getAttribute('studynumber');
							seriesTargets = _this.$refs.seriesBody.querySelectorAll('tr[studynumber="' + studynumber + '"].seriesRow');
							_this.postMax_image = 0
							await editStudy(seriesTargets,1,0);
							console.log("スタディナンバー：" + studynumber)
						})
						break;
					}
					// case 'series':
					// 	// studynumber = event.currentTarget.closest("tr").getAttribute('studynumber');
					// 	// seriesnumber =  event.currentTarget.closest("tr").getAttribute('seriesnumber_study');
					// 	_this.postMax_study = 1
					// 	_this.postMax_series = 1
					// 	studynumber = _this.targetNum_study
					// 	seriesnumber = _this.targetNum_series
					// 	imageTargets = document.querySelectorAll('div[studynumber="' + studynumber + '"][seriesnumber_study="' + seriesnumber + '"].image');
					// 	console.log("スタディナンバー：" + studynumber)
					// 	console.log("シリーズナンバー：" + seriesnumber)
					// 	await editSeries(imageTargets,1,1);
					// 	break;
				}

				// 全ての非同期処理が完了した後に後続の処理を実行
				_this.postcount_json = 0;
				console.log(_this.dcm_CmpCount + "件のDICOMファイルが保存できました。");
				if(this.orderErrorCount == 0){
					// Json送信
					await _this.sendJson();
				}
				if(this.orderErrorCount == 0){
					await _this.storeDCM();
				}
				_this.ordering = false
			},
			// 一括オーダーするものを選定
			select_storeData(){
				let _this = this
				let targets = document.getElementById("studyTable").getElementsByClassName("orderButton")
				_this.storeData_cmp_check = true;
				for(let i=0;i<targets.length;i++){
					console.log("forターゲット：" + i)
					let check_storeData_cmp = function() {
						if (_this.storeData_cmp_check === true) {
							console.log("ターゲット：" + i)
							_this.storeData_cmp_check = false;
							targets[i].click()
							let timerId = setTimeout(check_storeData_cmp, 1000);
							clearTimeout(timerId)
						}else{
							setTimeout(check_storeData_cmp, 1000);
						}
					}
					check_storeData_cmp();
				}
			},
			// プログレスバーを更新する
			updateProgress(now,total,progress,message) {
				try{
					var _this = this;
					// プログレスバーの進捗値を更新し、プログレスバーに反映させる
					var val = Math.floor(now / total * 100);
					progress.value = val;
					progress.innerText = val + "%";
					message.textContent = "(" + val + "%)"
					console.log("進捗：" + val + "%")

					// 最大値まで達したら終了
					if (val == 100) {
						setTimeout(() =>{
							if(_this.progres_finish_flg){
								setTimeout(() => {
									_this.showGenre = ""
									clearInterval(_this.intervalID_dumpfile)
								}, 50);
							}else{
								clearInterval(_this.intervalID_checkfile)
							}
						},100)
					}
				}catch(err){
					document.getElementById('status').classList.remove('alert-success')
					document.getElementById('status').classList.remove('alert-info')
					document.getElementById('status').classList.remove('alert-warning')
					document.getElementById('status').classList.add('alert-danger')
					document.getElementById('statusText').innerHTML = 'Status: Error - ' + err;
					console.log(err)
					_this.showGenre = ""
					clearInterval(_this.intervalID_checkfile)
					clearInterval(_this.intervalID_dumpfile)
				}
			},
			// OpenModal() {
			// 	let element = document.getElementById('overlay')
			// 	element.style.display = 'block'
			// },
			// CloseModal() {
			// 	let element = document.getElementById('overlay')
			// 	element.style.display = 'none'
			// },
			async sendDCM(dataSet,itemcount,nowcount,maxcount,seCount,stCount){
				let _this = this;
				const post_url = this.$store.state.API_HOST + '/send_dicom'

				// const data = {'data':this.okFileList[itemcount]}
				let formData = new FormData()
				formData.append("data",this.okFileList[itemcount])
				formData.append("patid",dataSet.string("x00100020"))
				//headerを設定する必要あり。
				// let config = {headers: {'content-type': 'multipart/form-data'}}
				console.log("DCM送信開始")
				console.log(localStorage)
				await axios.post(
					post_url,
					formData,
					{
						params: {
							'app_name': _this.$store.state.APP_NAME,
							'user_id': _this.$store.state.userID,
						},
						withCredentials: true
					}
				).then(
					async function(response) {
						console.log(post_url)
						console.log(response);
						console.log(response.data);
						if(typeof(response.data) != "string"){
							if(response.data.res.indexOf('.dcm') != -1){
								this.dcm_CmpCount += 1;
								this.ProgressDomList[stCount-1].value += 1;
								_this.ProgressDomList[stCount-1].message = Math.floor(_this.ProgressDomList[stCount-1].value / _this.ProgressDomList[stCount-1].max * 100) + "%完了"
								this.storeList.push(response.data.res)
							}else{
								this.orderErrorCount ++;
								this.ProgressDomList[stCount-1].message = "エラー [11]";
								console.log("エラー [11]")
							}
							console.log("DCM送信終了")
						}else{
							_this.$store.dispatch('logout');
							_this.$store.commit('setLoginUser', '')
						}
					}.bind(this)
				)
				.catch(
					async function(error) {
						let sopUID = dataSet.string("x00080018")
						if(!this.errorConclude[sopUID]){
							this.errorConclude[sopUID] = 1
							this.errorConclude[sopUID] = 1
							await this.sendDCM(dataSet,itemcount,nowcount,maxcount,seCount,stCount)
						}else{
							console.log(this.errorConclude[sopUID])
							console.log(error.response);
							console.log("POSTエラー");
							this.orderErrorCount ++;
							this.ProgressDomList[stCount-1].message = "エラー [12]";
						}
					}.bind(this)
				)
				.finally(
					function() {
						console.log("DCMファイル送信完了：" + this.dcm_CmpCount)
						console.log("DCMファイル送信最大：" + this.postMax_image)
						if(this.dcm_CmpCount == this.postMax_image){
							console.log("sendDCM正常終了")
							setTimeout(this.sendDCM_cmp_check = true, 500);
						}
					}.bind(this)
				);
			},
			settingJson(dataSet,item,num){
				var date = new Date();
				var editDate = date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2)
				var editTime = ('0' + date.getHours()).slice(-2) + ('0' + date.getMinutes()).slice(-2) + ('0' + date.getSeconds()).slice(-2)
				switch (item){
					case 'group':
						console.log('オーダー日時：' + date.getFullYear() + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' +('0' + date.getDate()).slice(-2) + ' ' +  ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2));
						this.orderJson["OrderGroup"]["Name"] = this.GroupName
						this.orderJson["OrderGroup"]["Order"]["OrderDate"] = editDate
						this.orderJson["OrderGroup"]["Order"]["OrderTime"] = editTime
						this.orderJson["OrderGroup"]["Order"]["DoubleReadingMode"] = this.DoubleReadingMode
						if(document.getElementById("Anonymously").checked){
							this.orderJson["OrderGroup"]["Order"]["Anonymously"] = 1
						}else{
							this.orderJson["OrderGroup"]["Order"]["Anonymously"] = 0
						}
						// console.log(this.studylist)
						// console.log(orderJson)
						break;
					case 'image':
						var targetStudy = this.orderJson["OrderGroup"]["Order"]["ImageInfo"].find((v) => v["StudyInfo"]["StudyInstanceUID"] === dataSet.string("x0020000d"));
						if (!targetStudy){
							var studyJson = {
									StudyInfo:{
										PatientID:"",
										PatientAE:"HOSP01",
										StudyInstanceUID:"",
										StudyModalities:"",
										StudyDate:0,
										SourceAETitle:"DICOMSRV",
										MoveImage:"yes",
										PastMoveImage:"yes",
										PastStudyFilter:{
											Modality:"",
											StudyDate:{
												From:"",
												To:""
											}
										},
										SOPInstanceUID:[]
									}
							}
							console.log("患者ID:" + dataSet.string("x00100020"))
							studyJson["StudyInfo"]["PatientID"] = dataSet.string("x00100020");
							studyJson["StudyInfo"]["StudyInstanceUID"] = dataSet.string("x0020000d");
							studyJson["StudyInfo"]["StudyModalities"] = dataSet.string("x00080060");
							studyJson["StudyInfo"]["StudyDate"] = dataSet.string("x00080020");
							this.orderJson["OrderGroup"]["Order"]["ImageInfo"].push(studyJson);
						}
						var targetStudy_index = this.orderJson["OrderGroup"]["Order"]["ImageInfo"].findIndex((v) => v["StudyInfo"]["StudyInstanceUID"] === dataSet.string("x0020000d"));
						this.orderJson["OrderGroup"]["Order"]["ImageInfo"][targetStudy_index]["StudyInfo"]["SOPInstanceUID"].push({"UID":dataSet.string("x00080018")});

						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["Applicant"] = this.Applicant
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["InterpretationRequests"] = this.InterpretationRequests
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["OutPatientHospital"] = this.OutPatientHospital
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["Departments"] = this.Departments
						if(num == 0){
							if(dataSet.string("x00080050")){
								this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["LedgerNumber"] = dataSet.string("x00080050");
							}else{
								this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["LedgerNumber"] = (editDate + editTime)
							}
						}
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["EditedPatientName"] = this.EditedPatientName
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["EditedPatientIdeogram"] = this.EditedPatientIdeogram
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["EditedPatinePhonetic"] = this.EditedPatinePhonetic
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["AttendingPhysician"] = this.AttendingPhysician
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["Protocol"] = this.Protocol
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["Operator"] = this.Operator
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["ContrastAgent"] = this.ContrastAgent
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["ClinicalInfo"] = this.ClinicalInfo
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["StudyComments"] = this.StudyComments
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderComments"] = this.OrderComments

						// 伝票
						if(this.OrderDocument !== null){
							this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Encode"] = "Base64"

							var imagetype = this.OrderDocument.type
							if(imagetype == "image/jpeg"){
								this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Format"] = "JPEG"
							}else if(imagetype == "image/png"){
								this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Format"] = "PNG"
							}

							this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Image"] = this.Base64text
						}else{
							this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Encode"] = ""
							this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Format"] = ""
							this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Image"] = ""
						}
						console.log("settingJson表示--------------------")
						console.log(this.orderJson)
						console.log("----------------------------")
						break;
				}
			},
			async sendJson(){
				let _this = this;
				if(this.postcount_json == 0){
					console.log("Json表示--------------------")
					console.log(this.orderJson)
					console.log("----------------------------")
				}
				let data = {"orderJson":this.orderJson}
				const JsonPost_url = this.$store.state.API_HOST + '/send_json'
				this.postcount_json += 1;
				console.log("Json送信開始")
				await axios.post(
					JsonPost_url,
					data,
					{
						params: {
							'app_name': _this.$store.state.APP_NAME,
							'user_id': _this.$store.state.userID,
						},
						headers: {
							'accept': 'application/json',
							'Content-Type': 'application/json',
						},
						withCredentials: true
					}
				).then(
					function(response) {
						console.log(response)
						if(typeof(response.data) != "string"){
							if(response.data.res.message == "UPLOAD_json OK"){
								this.orderUID_res = response.data.res.orderuid
								this.sendJson_cmp_check = true;
								for (let i=0;i < _this.ProgressDomList.length;i++){
									// 進捗25%追加
									this.ProgressDomList[i].value += _this.ProgressDomList[i].max / 4;
									this.ProgressDomList[i].message = Math.floor(_this.ProgressDomList[i].value / _this.ProgressDomList[i].max * 100) + "%完了"
								}
								console.log("Json送信完了")
							}else{
								// document.querySelector( '.loading' ).classList.add( 'hide' );
								this.orderErrorCount ++;
								for (let i=0;i < _this.ProgressDomList.length;i++){
									this.ProgressDomList[i].message = "エラー [13]";
									console.log("エラー [13]")
								}
							}
						}else{
							if(response.data != "NG"){
								_this.$store.dispatch('logout');
								_this.$store.commit('setLoginUser', '')
							}else{
								// document.querySelector( '.loading' ).classList.add( 'hide' );
								this.orderErrorCount ++;
								for (let i=0;i < _this.ProgressDomList.length;i++){
									this.ProgressDomList[i].message = "エラー [14]";
									console.log("エラー [14]")
								}
							}
						}
					}.bind(this)
				)
				.catch(
					function(error) {
						for (let i=0;i < _this.ProgressDomList.length;i++){
							this.ProgressDomList[i].message = "エラー [15]";
							console.log("エラー [15]")
						}
						this.result = "POSTエラー";
						console.log(error);
						console.log(error.response);
						console.log(this.result)
					}.bind(this)
				);
			},
			async storeDCM(){
				let _this = this;
				console.log("ストア開始")
				// this.message_store = "DICOMストア中・・・"
				var storeobj = {"orderuid":this.orderUID_res,"fileList":this.storeList}
				const post_url = this.$store.state.API_HOST + '/store_dicom'
				await axios.post(
					post_url,
					storeobj,
					{
						params: {
							'app_name': _this.$store.state.APP_NAME,
							'user_id': _this.$store.state.userID,
						},
						headers: {
							'accept': 'application/json',
							'Content-Type': 'application/json',
						},
						withCredentials: true
					}
				).then(
					function(response) {
						console.log(response)
						if(typeof(response.data) != "string"){
							if(!isNaN(response.data.res)){
								this.storeList.splice(0)
								this.storeData_cmp_check = true
								for (let i=0;i < _this.ProgressDomList.length;i++){
									_this.ProgressDomList[i].value = _this.ProgressDomList[i].max
									// 進捗100%
									_this.ProgressDomList[i].message = Math.floor(_this.ProgressDomList[i].value / _this.ProgressDomList[i].max * 100) + "%完了"
								}
							}else{
								this.orderErrorCount ++;
								for (let i=0;i < _this.ProgressDomList.length;i++){
									this.ProgressDomList[i].message = "エラー [16]";
									console.log("エラー [16]")
								}
								// this.$refs.loadingImage.classList.add( 'hide' );
								_this.$store.dispatch('logout');
								_this.$store.commit('setLoginUser', '')
							}
							// this.$refs.loadingImage.classList.add( 'hide' );
							console.log("ストア終了")
						}else{
							_this.$store.dispatch('logout');
							_this.$store.commit('setLoginUser', '')
						}
					}.bind(this)
				)
				.catch(
					function(error) {
						this.orderErrorCount ++;
						for (let i=0;i < _this.ProgressDomList.length;i++){
							this.ProgressDomList[i].message = "エラー [17]";
							console.log("エラー [17]")
						}
						this.result = "POSTエラー";
						// this.$refs.loadingImage.classList.add( 'hide' );
						console.log(error.response);
						console.log(this.result)
						console.log("ストア終了")
					}.bind(this)
				);
				// document.getElementById("head").classList.remove("disabled");
			},
			settingInputZone(){
				var date = new Date();
				var editDate = date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2)
				var editTime = ('0' + date.getHours()).slice(-2) + ('0' + date.getMinutes()).slice(-2) + ('0' + date.getSeconds()).slice(-2)
				this.GroupName = localStorage.getItem('userID') + "-" + editDate
				this.DoubleReadingMode = 0
				this.Applicant = "読影管理"
				this.InterpretationRequests = 0
				this.OutPatientHospital = -1
				this.Departments = "整形外科"
				// 台帳番号
				if(this.dataSetList[0].string("x00080015")){
					this.LedgerNumber = this.dataSetList[0].string("x00080015");
				}else{
					this.LedgerNumber = (editDate + editTime)
				}
				// 部位
				if(this.dataSetList[0].string("x00080015")){
					this.BodyPart = this.dataSetList[0].string("x00080015");
				}else{
					this.BodyPart = ""
				}
			},
			clearInputZone(){
				this.GroupName = "";
				this.DoubleReadingMode = "";
				this.Anonymously = "";
				this.GroupComment = "";
				this.Applicant = "";
				this.InterpretationRequests = "";
				this.OutPatientHospital = "";
				this.Departments = "";
				this.LedgerNumber = "";
				this.EditedPatientName = "";
				this.EditedPatientIdeogram = "";
				this.EditedPatinePhonetic = "";
				this.BodyPart = "";
				this.AttendingPhysician = "";
				this.Protocol = "";
				this.Operator = "";
				this.ContrastAgent = "";
				this.ClinicalInfo = "";
				this.StudyComments = "";
				this.OrderComments = "";

				this.settingInputZone();
				this.fileDelete();
			},
			// 伝票読込
			previewFile(file) {
				// プレビュー画像を追加する要素
				const preview = document.getElementById('preview');

				// FileReaderオブジェクトを作成
				const reader = new FileReader();

				// ファイルが読み込まれたときに実行する
				reader.onload = function (e) {
					const imageUrl = e.target.result; // 画像のURLはevent.target.resultで呼び出せる
					const img = document.createElement("img"); // img要素を作成
					img.src = imageUrl; // 画像のURLをimg要素にセット
					img.style.maxWidth = "260px"
					preview.textContent = ""; //一枚のみ有効とする
					preview.appendChild(img); // #previewの中に追加
				}

				// いざファイルを読み込む
				reader.readAsDataURL(file);
				if(document.getElementById("OrderDocument").files[0] !== undefined){
					this.OrderDocument = document.getElementById("OrderDocument").files[0]
					// 伝票をBase64文字列にエンコード
					var uploadImage = document.querySelector('#OrderDocument')
					var orderdocumentFile = uploadImage.files[0]
					var orderdocumentreader = new FileReader()
					orderdocumentreader.onload = (event) => {
						// Base64テキストをセット
						this.Base64text = event.currentTarget.result.split(',')[1]
					}
					orderdocumentreader.readAsDataURL(orderdocumentFile)
				}else{
					this.Base64text = ""
				}
			},
			// 伝票削除
			fileDelete(){
				const doc = document.getElementById('OrderDocument');
				const pre = document.getElementById('preview');
				doc.value = "";
				pre.textContent = "";
			},
		},
		mounted: function(){
			var _this = this;
			// DICOMファイル読み込み処理
			var dropZone = document.getElementById('dropZone');
			dropZone.addEventListener('dragover', this.handleDragOver, false);
			dropZone.addEventListener('drop', this.handleFileSelect, false);

			// 伝票ファイル読み込み処理
			const fileInput = document.getElementById('OrderDocument');
			var dropZone_OD = document.getElementById('dropZone_OD');
			dropZone_OD.addEventListener('dragover', function(e) {
				e.stopPropagation();
				e.preventDefault();
				this.style.background = '#e1e7f0';
			}, false);

			dropZone_OD.addEventListener('dragleave', function(e) {
				e.stopPropagation();
				e.preventDefault();
				this.style.background = '#ffffff';
			}, false);
			const HandleOrderDocumentSelect = () => {
				const files = fileInput.files;
				for (let i = 0; i < files.length; i++) {
					_this.previewFile(files[i]);
				}
				fileInput.addEventListener('change', HandleOrderDocumentSelect);
			}
			HandleOrderDocumentSelect();

			dropZone_OD.addEventListener('drop', function(e) {
				e.stopPropagation();
				e.preventDefault();
				this.style.background = '#ffffff'; //背景色を白に戻す
				var files = e.dataTransfer.files; //ドロップしたファイルを取得
				if (files.length > 1) return alert('アップロードできるファイルは1つだけです。');
				fileInput.files = files; //inputのvalueをドラッグしたファイルに置き換える。
				_this.previewFile(files[0]);
			}, false);
		},
		// deactivated() {
		// 	let targetdict = localStorage.getItem("loginOnly_reload_componentList")
		// 	// データが存在する場合
		// 	if (targetdict) {
		// 		// 文字列をJSONオブジェクトに変換
		// 		let dataObject = JSON.parse(targetdict);

		// 		if(!dataObject[this.$options.name]){
		// 			dataObject[this.$options.name] = true;
		// 			// 更新したデータをlocalStorageに保存（再びJSON文字列に変換）
		// 			localStorage.setItem('loginOnly_reload_componentList', JSON.stringify(dataObject));
		// 			// data() 内の変数を初期化
		// 			// Object.assign(this.$data, this.$options.data());
		// 			this.reloadPage()
		// 		}else{
		// 			console.log('初回リロード済');
		// 		}
		// 	} else {
		// 		console.error('データが見つかりません');
		// 	}
		// },
		// beforeRouteLeave(to, from, next) {
		// 	// コンポーネントが非アクティブになる前に実行される処理
		// 	// ここでDOM要素にアクセスしたり初期化処理を行ったりできます
		// 	next();
		// }
	}
</script>

<style>
	table, td, th{
		border: 1px solid black;
	}
	table {
		border-collapse: collapse;
	}
	thead {
		background-color: lightgray;
	}
	tbody {
		cursor: pointer;
		cursor: hand;
	}
	.mouse_hover:hover {
		background-color: rgb(118, 183, 239);
	}
	#message_json,#message_send,#message_send_error, #message_store {
		color:red
	}
	.allstudy {
		padding-top: 5px;
		padding-bottom: 5px;
		margin-right: 10px;
		margin-bottom: 10px;
	}
	.loading {
		margin: 0;
		padding: 0;
	}
	.loading.hide {
		opacity: 0;
		pointer-events: none;
		transition: opacity 500ms;
	}
	.loading .circle {
		display: block;
		position: relative;
		top: calc( 50% - 20px );
		width: 25px;
		height: 25px;
		margin: 0 auto;
		border: 7px solid #444444;
		border-top: 7px solid #5ae1e5;
		border-radius: 50px;
		animation: loading 700ms linear 0ms infinite normal both;
	}
	@keyframes loading {
		0% { transform: rotate( 0deg ); }
		100% { transform: rotate( 360deg ); }
	}
	.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
	.border {
		padding: 10px;
		margin: 20px;
	}
	.border-solid {
		border: 2px solid #090201;
	}
	#orderGroupInputZone > label, #orderInputZone > label {
		margin-left: 25px;
	}


	details {
		border: 1px solid #ccc;
	}
	details:not(:last-child) {
		margin-bottom: 20px;
	}

	/**
	* list-style: none; ←デフォルト三角削除（Chrome非対応）
	* cursor: pointer; ←カーソルをポインターに
	**/
	details summary {
		list-style: none;
		cursor: pointer;
		padding: 20px;
		background: #e4e4e4;
		color: #242323;
		font-size: 1rem;
		font-weight: bold;
		/* width: 50%; */
	}

	details span {
		margin: 0;
		padding: 20px;
	}
	#InputZone textarea{
		vertical-align:top;
	}

	.list-table {
		overflow: auto;
	}
	.list-table #studyTable, .list-table #seriesTable {
		width: max-content;
	}
	.hide {
		display: none !important;
	}
	.imagelist{
		display: flex;
	}
	.show {
		opacity: 1;
	}
	.showhide {
		opacity: 0;
	}
	.show_onetime {
		display: block !important;
	}
	#overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.8);
        z-index: 5;
    }

    .popup_progress {
        position: absolute;
        width: 30%;
        height: 30%;
        top: 25%;
        left: 25%;
        text-align: center;
        background: white;
    }
    .popup_progress h3 {
        font-size: 15px;
        height: 50px;
        line-height: 50px;
        background: white;
    }

	.popup_orderprogress {
		position: absolute;
        width: 80%;
        height: 60%;
        top: 25%;
        left: 10%;
        text-align: center;
        background: white;
	}

	.sticky-x2 {
		position: sticky;
		top: 0;
		right: 0;
	}
	.progressMessage {
		color: red;
	}


	.ProgressCheck{
		/* width: 150px;
		height: 50px; */
		background: #aaa;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	.ordering{
		animation: flash 4s ease infinite;
	}

	@keyframes flash {
		0%,100% {
			opacity: 1;
		}
		50% {
			opacity: 0.25;
		}
	}

	.progressZone {
		max-height: 60%;
	}
	.progressZone th {
		position: sticky;
		top: 0;
		left: 0;
		z-index: 3;
	}

</style>