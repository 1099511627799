export default {
	data() {
		return {
      resize_flg:false,
      sortKey:"",
      // sortClear_flg:false,
      flg_List:{},
      // currentPage: 1,
      genre:"",
			// set_conditions_flg:false,
      // set_sort_flg:false,
		}
	},
  methods: {
    // 検索条件設定（設定した検索条件はローカルストレージに保存し、ログイン時に各フォームにセットされる）
    set_conditions(itemlist,condlist,perPageNum,table_cond,genre){
			let _this = this
      let localSetList = {};
      let jsondata = "";
      let setName = "";
      let condName = ""
      let condTitle = "cond_" + genre
      let perPage_title = "perPage_" + genre
      this.genre = genre

      function set_condition(setName,condName,i) {
        if(!_this.flg_List[genre]){
          _this.flg_List[genre] = {}
          _this.flg_List[genre]['set_conditions_flg'] = false;
          _this.flg_List[genre]['set_sort_flg'] = false;
          _this.flg_List[genre]['sortClear_flg'] = false;
        }
        // データ参照が初回かを判断 初回：false, 初回以外：true
        if(_this.flg_List[genre].set_conditions_flg){
          // 初回以外のデータ参照時は各変数からローカルストレージに条件をセット
          // ※値が入っていない場合はその項目はundefinedと見做され、jsonにその項目は作成されない
          localSetList[setName] = table_cond[condName];
          if(i == 0){
            // 最大表示件数をセット
            localStorage.setItem(perPage_title,perPageNum);
          }
          if(localSetList[setName] !== ""){
            condlist[setName] = localSetList[setName]
          }
        // データ参照初回
        }else{
          if(localStorage.getItem(condTitle)){
            jsondata = JSON.parse(localStorage.getItem(condTitle))
            // 初回のデータ参照時はローカルストレージから各変数に条件をセット
            table_cond[condName] = jsondata[setName]
          }
          if(i == 0 && localStorage.getItem(perPage_title)){
            perPageNum = localStorage.getItem(perPage_title);
          }
          if(localStorage.getItem(condTitle)){
            jsondata = JSON.parse(localStorage.getItem(condTitle))
            // 空の場合は参照条件に設定しない
            if(jsondata[setName]){
              if(jsondata[setName] !== "" && jsondata[setName] !== null){
                condlist[setName] = jsondata[setName];
              }
            }
          }
        }
      }
      for (let i=0;i<itemlist.length;i++){
        // groupItemsにcondが設定されていない列はスルー
        if(itemlist[i].type !== "" && itemlist[i].type !== "date"){
          setName = itemlist[i].cond.replace("cond_","")
          condName = itemlist[i].cond
          set_condition(setName,condName,i)
        }else if (itemlist[i].type === "date"){
					for (let key in itemlist[i].cond) {
            setName = itemlist[i].cond[key].replace("cond_","")
            condName = itemlist[i].cond[key]
            set_condition(setName,condName,i)
          }
        }
      }
      if(Object.keys(localSetList).length > 0){
        localStorage.setItem(condTitle,JSON.stringify(localSetList))
      }
      console.log("検索条件セット完了")
      console.log(localStorage);
      // return JSON.stringify(condlist)
			return {cond:JSON.stringify(condlist),perPage:perPageNum}
    },
    // ローカルストレージからテーブル列幅を設定（データ参照初回のみ動作）
    set_width(Items,table_width,genre){
			const _this = this
      var jsondata = "";
      var timer = 0;
      var width_title = "width_" + genre
      // 幅が変更されるたびにリサイズを検知してしまうため、0.5秒に一回のみ呼ばれるように調整
      const someFunc = function(entries) {
        // entries.stopImmediatePropagation();
        // アンダーバーが含まれる項目があるため、最後のアンダーバーのみ削除した項目名を取得
        let targetName = ""
        let editArray = entries[0].target.id.split('_')
        for(let i=0;i<editArray.length;i++){
          if(i != (editArray.length-1)){
            if(i != (editArray.length-2)){
              targetName += editArray[i] + "_"
            }else{
              targetName += editArray[i]
            }
          }
        }
        // var throughFlg = false
        if(entries[0].target.offsetWidth !== 0){
          // if(JSON.parse(localStorage.getItem(width_title))){
          //   if((entries[0].target.offsetWidth + "px !important") !== JSON.parse(localStorage.getItem(width_title))["width_" + targetName]){
          //     throughFlg = true
          //   }
          // }
          if(entries.length == 1){
            _this.resize_flg = true
          }else{
            if(!_this.flg_List[genre].set_sort_flg){
              _this.resize_flg = true
            }else{
              _this.resize_flg = false
            }
          }
          console.log('resized');
          console.log(entries)
          console.log(targetName + "：" + entries[0].target.offsetWidth + "px")
          table_width["width_" + targetName] = entries[0].target.offsetWidth + "px !important"
          let localSetList = {}
          let itemlist = ""
          itemlist = Items
          for (let i=0;i<itemlist.length;i++){
              localSetList[itemlist[i].width] = table_width[itemlist[i].width]
          }
          if(Object.keys(localSetList).length > 0){
            localStorage.setItem(width_title,JSON.stringify(localSetList))
          }
          console.log(localStorage)
          setTimeout(function () {
						_this.resize_flg = false;
					},500 );
        }
      };
      const resizeObserver = new ResizeObserver((entries) => {
        if(timer > 0) clearTimeout(timer);
        timer = setTimeout(someFunc(entries),100)
      })

      if(!_this.flg_List[genre].set_conditions_flg){
        console.log("データ参照初回")
        for (let i=0;i<Items.length;i++){
          if(localStorage.getItem(width_title)){
            jsondata = JSON.parse(localStorage.getItem(width_title))
            table_width[Items[i].width] = jsondata[Items[i].width]
          }
          resizeObserver.observe(document.querySelector('#' + Items[i].code + "_" + genre));
        }
        setTimeout(function () {
          _this.resize_flg = false
        },1000 );
        _this.flg_List[genre].set_conditions_flg = true;
      }
    },
    // ローカルストレージからテーブルの高さを設定（データ参照初回のみ動作）
    set_height(tableHeight,setName,genre,element){
      const _this = this
      var timer = 0;
      // 幅が変更されるたびにリサイズを検知してしまうため、0.5秒に一回のみ呼ばれるように調整
      const someFunc = function(entries) {
        console.log('heightResized');
        console.log(entries)
        if(entries[0].target.offsetHeight != 0){
          tableHeight = entries[0].target.offsetHeight + "px"
          localStorage.setItem(setName,tableHeight)
          console.log(localStorage)
        }
      }
      const resizeObserver = new ResizeObserver((entries) => {
        if(timer > 0) clearTimeout(timer);
        timer = setTimeout(someFunc(entries),100)
      })
      if(!_this.flg_List[genre].set_conditions_flg){
        console.log("データ参照初回")
        if(localStorage.getItem(setName) && localStorage.getItem(setName) != "0px"){
          tableHeight = localStorage.getItem(setName)
        }
        resizeObserver.observe(element);
      }
      return tableHeight;
    },
    sortFunc(headData,bodyData,sortKey_list,sortOrders,perPage,currentPage,genre) {
      var data = bodyData
      console.log("ソート処理")
      console.log("元ジャンル：" + this.genre)
      console.log("ジャンル：" + genre)
      if(this.genre == genre){
        var sort_title = "sort_" + genre
        if(Object.keys(data).length > 0){
          console.log("ソートキー：" + this.sortKey)
          console.log(Object.keys(data).length)
          if(!this.flg_List[genre].set_sort_flg){
            // ローカルストレージからソート条件を設定
            // 初回のみ動作
            if(localStorage.getItem(sort_title)){
              sortKey_list = JSON.parse(localStorage.getItem(sort_title))
              for (var i = 0; i < sortKey_list.length; i++) {
                let key = sortKey_list[i].key
                this.sortBy(key,"first",sortKey_list[i].order,sortOrders,genre)
              }
            }
          }else{
            if(this.sortKey !== ""){
              // var sortKey = sortKey;
              var order = sortOrders[this.sortKey] || 1;
              let index = sortKey_list.findIndex((u) => u.key === this.sortKey);
              // ソート対象のものと、昇順または降順の条件を配列に格納（配列に項目が存在している場合は条件のみ格納）
              if(index == -1){
                sortKey_list.push({"key":this.sortKey,"order":order})
              }else{
                sortKey_list[index]["order"] = order
              }
            }
          }
          console.log("ソート対象リスト------------")
          console.log(sortKey_list)
          console.log("---------------------------")

          data = this.dataSort(data,headData,sortKey_list,sortOrders,genre);

          // ローカルストレージにソート条件を設定
          localStorage.setItem(sort_title,JSON.stringify(sortKey_list))
          console.log("ローカルストレージにソート条件セット")
          console.log(JSON.stringify(sortKey_list))
          console.log(localStorage)
          // data = this.getItems(data,perPage,currentPage);
        }else{
          console.log("ソートスルー2")
        }
      }else{
        console.log("ソートスルー1")
      }
      if(Object.keys(data).length > 0){
        data = this.getItems(data,perPage,currentPage);
      }
			return data;
    },
    dataSort(data,headData,sortKey_list,sortOrders,genre){
      const _this = this
      data = data.sort(function(a, b){
        for (var i = 0; i < sortKey_list.length; i++) {
          let item = sortKey_list[i]
          if(item.key !== ""){
            let typecheck = headData[headData.findIndex((u) => u.code === item.key)].type;
            if(typecheck == "number"){
              if (item.order === 1) {
                if (parseInt(a[item.key]) < parseInt(b[item.key])) return -1;
                if (parseInt(a[item.key]) > parseInt(b[item.key])) return 1;
              } else {
                if (parseInt(a[item.key]) > parseInt(b[item.key])) return -1;
                if (parseInt(a[item.key]) < parseInt(b[item.key])) return 1;
              }
            } else {
              if (item.order === 1) {
                if (a[item.key] < b[item.key]) return -1;
                if (a[item.key] > b[item.key]) return 1;
              } else {
                if (a[item.key] > b[item.key]) return -1;
                if (a[item.key] < b[item.key]) return 1;
              }
              // if (item.order === 1) {
              //   if(a.localeCompare(b) == 1) return 1
              //   if (a.localeCompare(b) == -1) return -1;
              // } else {
              //   if(a.localeCompare(b) == 1) return -1
              //   if (a.localeCompare(b) == -1) return 1;
              // }
            }
          }
        }
        return 0;
      })

      console.log("クリアソートフラグ：" + _this.flg_List[genre]['sortClear_flg'])

      if(_this.flg_List[genre]['sortClear_flg']){
        // 登録日時の降順にし、ソート条件はすべてクリア
        console.log("ソート条件はすべてクリア")
        sortKey_list.splice(0);
        this.sortKey = ""
        if(genre == "user"){
          sortOrders["verifydatetime"] = 0
        }else{
          sortOrders["insertdatetime"] = 0
        }
        // setTimeout(function () {
          _this.flg_List[genre]['sortClear_flg'] = false;
        // },1000 );
      }
      return data
    },
    addSortNumber(key,genre){
      console.log("ソート優先順位付与")
      // console.log(genre)
      let sortKey_list = JSON.parse(localStorage.getItem("sort_" + genre))
      // console.log(sortKey_list)
      if(sortKey_list !== null){
        let index = sortKey_list.findIndex((u) => u.key === key)
        if(index !== -1){
          return (index + 1)
        }else{
          return ''
        }
      }else{
        return ''
      }
    },
    sortBy(key,type,order,sortOrders,genre){
      let _this = this
      this.genre = genre
      let throughFlg = false
      // // リサイズ時にリサイズフラグがtrueになって直後にソートできなくなるのを防ぐため遅延
      // setTimeout(await function () {
      console.log("リサイズフラグ：" + _this.resize_flg)
      console.log("初回表示フラグ：" + _this.flg_List[genre].set_sort_flg)
      // console.log("ソートクリアフラグ：" + _this.sortClear_flg)
      // リサイズ処理でもクリックイベントが発火してしまうため、ソート処理を制御
      if(!_this.resize_flg || (!_this.flg_List[genre].set_sort_flg && !_this.resize_flg)){
        if(key != ""){
          if(type !== "first"){
            _this.flg_List[genre].set_sort_flg = true
          }
          _this.sortKey = key;
          if(sortOrders[key] == 0){
            sortOrders[key] = 1
          }
          if(type === "first"){
            sortOrders[key] = order;
          }else{
            sortOrders[key] = sortOrders[key] * -1;
          }
        }
      }else{
        throughFlg = true
      }
      console.log("ジャンル：" + genre)
      console.log(sortOrders)
      _this.resize_flg = false;
      return throughFlg
    // },50)
    },
    sortClear(headData,sortKey_list,sortOrders,genre){
      // ソート前後でソート条件をすべて削除
      sortKey_list.splice(0);
      for (let count = 0; count < headData.length; count++) {
        if(headData[count]['code'] == "insertdatetime" || headData[count]['code'] == "verifydatetime"){
          sortOrders[headData[count]['code']] = 1
        }else{
          sortOrders[headData[count]['code']] = 0
        }
      }
      this.flg_List[genre]['sortClear_flg'] = true;
      if(genre == "user"){
        this.sortBy("verifydatetime","","",sortOrders,genre);
      }else{
        this.sortBy("insertdatetime","","",sortOrders,genre);
      }
    },
    set_draggable(genre){
      if(!this.flg_List[genre].set_conditions_flg){
        if(localStorage.getItem(genre + 'Items')){
          this[genre + 'Items'] = JSON.parse(localStorage.getItem(genre + 'Items'))
        }
        if(localStorage.getItem(genre + 'Items2')){
          this[genre + 'Items2'] = JSON.parse(localStorage.getItem(genre + 'Items2'))
        }
      }
    },
    set_draggable_toLocal(genre){
      console.log("ストレージに並び順セット")
      // 「選択」と「操作」の列はgroupItemには不要のため削除
      var Items_edit = this[genre + 'Items2']
      for (let count = 0; count < this[genre + 'Items2'].length; count++) {
        if(Items_edit.code == "checked" || Items_edit == "listfunction"){
          let index = Items_edit.findIndex((u) => u.code === this[genre + 'Items2'][count].code)
          Items_edit = Items_edit.splice(index,1)
        }
      }
      localStorage.setItem(genre + 'Items',JSON.stringify(Items_edit))
      localStorage.setItem(genre + 'Items2',JSON.stringify(this[genre + 'Items2']))
    },
    // clickCallback(pageNum) {
    //   console.log("現在のページ：" + this.currentPage)
    //   this.currentPage = Number(pageNum);
    // },
    getItems(data,perPage,currentPage) {
      console.log("ページネーション処理")
      console.log(perPage)
      let current = currentPage * perPage;
      let start = current - perPage;
      return data.slice(start, current);
    },
    MathCeil(bodyData,perPage){
      console.log("合計ページ計算")
      console.log(perPage)
      return Math.ceil(Object.keys(bodyData).length / perPage)
    },
	}
};