import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'

/**
 * import Bootstrap5
 */
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

/**
 * Load Fontawesome 5
 */
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import cors from 'cors';

library.add(fas)


// Vue アプリケーションを作成します
const app = createApp(App)

app.component('font-awesome-icon', FontAwesomeIcon)

app.config.productionTip = false;

app.use(router)
app.use(store)
app.mount('#app');

// // app.use(cors({ origin: true, credentials: true }));
// app.mount('#app')