<template>
    <div class="container">
		<h2>
			<font-awesome-icon icon="clipboard-user" />&nbsp;{{ title }}
			<span  data-bs-toggle="tooltip" data-bs-placement="top" title="再読み込み" style="float: right;margin-right: 50px;cursor: pointer;">
				<font-awesome-icon icon="fa-redo-alt" @click="reloadPage" />
			</span>
		</h2>
		<header class="mt-3 mb-4">
			<div class="row justify-content-center">
				<div class="col-lg-8 row">
					<!-- <meta name="csrf-token" content="{{ csrf_token() }}"> -->
					<!-- <h1 class="col-md-4 col-lg-6"><img src="{{ asset('storage/images/logo_esloader_with_icon.png') }}" alt="EsLoader"></h1> -->
					<p>DICOMイメージをサーバーへ送信する前にクライアントサイドで匿名化処理し、エスフィルクラウドサーバーへアップロードします。</p>
				</div>
				<div id = "message">{{ message }}</div>
				<div id = "message_json">{{ message_json }}</div>
				<div id = "message_send">{{ message_send }}</div>
				<div id = "message_send_error">{{ message_send_error }}</div>
				<div id = "message_store">{{ message_store }}</div>
				<div class="loading" :class="{'hide':!ordering}">
					<span class="circle"></span>
				</div>
				<!-- <div id="orderProgress">
					<progress id="myProgress" class="loading22" value="0" max="100">0%</progress>
					<span id = "message_progress">{{ message_progress }}</span>
				</div> -->
			</div>
		</header>
		<div id="InputZone" class="popup">
			<span id="popup-inner_orderDetail">
				<div id="orderGroupInputZone" class="row justify-content-center border border-solid">
					<h2 style="color:black;">オーダーグループ情報入力欄</h2>
					<span>
						<label for="GroupName">グループ名：</label>
						<input name="GroupName" v-model="GroupName" style="border: 1px solid;">
					</span>
					<span>
						<label for="DoubleReadingMode">種別：</label>
						<select name="DoubleReadingMode" id="DoubleReadingMode" v-model="DoubleReadingMode" style="border: 1px solid;">
							<option value=0>1読影</option>
							<option value=3>２重読影（オープン,１レポート）</option>
							<option value=4>２重読影（ブラインド,１レポート）</option>
						</select>
						<label for="Anonymously" style="margin-left:15px;">匿名：</label>
						<label style="margin-left:0px;"><input type="checkbox" name="Anonymously" v-model="Anonymously" id="Anonymously" value=1></label>
					</span>
					<span>
						<label for="GroupComment">伝言：</label>
						<textarea name="GroupComment" v-model="GroupComment" class="messageArea"  style="border: 1px solid;"></textarea>
					</span>
				</div>

				<div id="orderInputZone" class="row justify-content-center border border-solid">
					<div>
						<h2 style="color:black; display:inline-block;">オーダー情報入力欄</h2>
						<button id = "AutoSet" class="btn btn-sm btn-info" style="display:inline-block; margin:0 0 10px 25px;"  @click="dialogControl()">ファイル名から入力</button>
					</div>
					<form id="dialog" name="dialog" style="display:none;">
						<div>ドロップしたファイル名よりオーダー情報入力を行いますか？</div>
						<div>行う場合、以下よりパターンを選択してOKをクリックしてください。</div>
						<div>
							<label for="pattern1">
								<input type="radio" id="pattern1" value="1" v-model="pattern">患者ID_検査日付_検査時間_xxxxxxxx.JPG
							</label>
						</div>
						<div>
							<label for="pattern2">
								<input type="radio" id="pattern2" value="2" v-model="pattern">患者ID_患者氏名_Retina_左右側_検査日付_検査時間.JPG
							</label>
						</div>
						<input type="button" name="yes" value="OK" @click="AutoSetFromFilename('yes')" />
						<input type="button" name="no" value="キャンセル" @click="AutoSetFromFilename('no')" />
					</form>
					<span>
						<label for="Applicant">作成者：</label>
						<input name="Applicant" v-model="Applicant"  style="border: 1px solid;">
					</span>
					<span>
						<label for="InterpretationRequests">読影要求：</label>
						<select name="InterpretationRequests" id="InterpretationRequests" v-model="InterpretationRequests" style="border: 1px solid;">
							<option value=0>通常</option>
							<option value=1>優先</option>
							<option value=2>緊急</option>
						</select>
						<label for="OutPatientHospital" style="margin-left:20px;">入院外来：</label>
						<select name="OutPatientHospital" id="OutPatientHospital" v-model="OutPatientHospital" style="border: 1px solid;">
							<option value=-1>なし</option>
							<option value=0>入院</option>
							<option value=1>外来</option>
							<option value=2>受託</option>
							<option value=3>健診</option>
						</select>
						<label for="Departments" style="margin-left:20px;">診療科：</label>
						<select name="Departments" id="Departments" v-model="Departments"  style="border: 1px solid;">
							<option value="整形外科">整形外科</option>
							<option value="内科">内科</option>
							<option value="外科">外科</option>
							<option value="健診科">健診科</option>
							<option value="放射線科">放射線科</option>
							<option value="その他">その他</option>
						</select>
					</span>
					<span>
						<label for="LedgerNumber">台帳番号：</label>
						<input name="LedgerNumber" v-model="LedgerNumber"  style="border: 1px solid;">
					</span>
					<span>
						<label for="PatientID" style="width:160px;">患者ID：<span style="color:red; padding:0;">【必須】</span></label>
						<input name="PatientID" v-model="PatientID" @change="setTable($event)" style="width:250px; border: 1px solid;">
					</span>
					<span>
						<label for="EditedPatientName_last">患者名苗字（ローマ字）：</label>
						<input name="EditedPatientName_last" v-model="EditedPatientName_last" @change="setTable($event)" style="width:250px; border: 1px solid;">
					</span>
					<span>
						<label for="EditedPatientName_first">患者名名前（ローマ字）：</label>
						<input name="EditedPatientName_first" v-model="EditedPatientName_first" @change="setTable($event)" style="width:250px; border: 1px solid;">
					</span>
					<span>
						<label for="EditedPatientIdeogram_last">患者名苗字（漢字）：</label>
						<input name="EditedPatientIdeogram_last" v-model="EditedPatientIdeogram_last" @change="setTable($event)" style="width:250px; border: 1px solid;">
					</span>
					<span>
						<label for="EditedPatientIdeogram_first">患者名名前（漢字）：</label>
						<input name="EditedPatientIdeogram_first" v-model="EditedPatientIdeogram_first" @change="setTable($event)" style="width:250px; border: 1px solid;">
					</span>
					<span>
						<label for="EditedPatinePhonetic_last">患者名苗字（フリガナ）：</label>
						<input name="EditedPatinePhonetic_last" v-model="EditedPatinePhonetic_last" @change="setTable($event)" style="width:250px; border: 1px solid;">
					</span>
					<span>
						<label for="EditedPatinePhonetic_first">患者名名前（フリガナ）：</label>
						<input name="EditedPatinePhonetic_first" v-model="EditedPatinePhonetic_first" @change="setTable($event)" style="width:250px; border: 1px solid;">
					</span>
					<span>
						<label for="Sex">性別：</label>
						<input name="Sex" v-model="Sex" @change="setTable($event)" style="width:50px; border: 1px solid;">
						<label for="BirthDate" style="margin-left: 15px;">生年月日：</label>
						<input name="BirthDate" v-model="BirthDate" type="date" @change="get_age($event)" style="border: 1px solid;">
						<label for="BirthDate" style="margin-left: 15px;">年齢：</label>
						<input name="BirthDate" v-model="Age" type="number" @change="setTable($event)" style="width:50px; border: 1px solid;">
					</span>
					<span>
						<label for="StudyDate">検査日付：<span style="color:red; padding:0;" >【必須】</span></label>
						<input name="StudyDate" v-model="StudyDate" type="date" @change="setTable($event)" style="width:150px; border: 1px solid;">
						<label for="StudyTime" style="margin-left: 15px;">検査時刻：</label>
						<input name="StudyTime" v-model="StudyTime" type="time" step="1" @change="setTable($event)" style="width:200px; border: 1px solid;">
					</span>
					<span>
						<label for="Modality">モダリティ：<span style="color:red; padding:0;">【必須】</span></label>
						<select name="Modality" id="Modality" v-model="Modality" @change="setTable($event)" style="width:100px; border: 1px solid;">
							<option value="CR">CR</option>
							<option value="CT">CT</option>
							<option value="DR">DR</option>
							<option value="ECG">ECG</option>
							<option value="ES">ES</option>
							<option value="MG">MG</option>
							<option value="MR">MR</option>
							<option value="OT">OT</option>
							<option value="PT">PT</option>
							<option value="RF">RF</option>
							<option value="SC">SC</option>
							<option value="US">US</option>
							<option value="XA">XA</option>
							<option value="OP">OP</option>
						</select>
						<label for="BodyPart" style="margin-left: 15px;">部位：</label>
						<input name="BodyPart" v-model="BodyPart" style="border: 1px solid;">
					</span>
					<label for="Laterality">ラテラリティ：</label>
					<span>
						<label v-for="(item,n) in JPEGlist" :key="n" style="margin-left: 20px;">{{ item.filename }}：
							<div name="Laterality">{{ Laterality['jpg'+(n+1)] }}</div>
						</label>
					</span>
					<span>
						<label for="AttendingPhysician">主治医：</label>
						<input name="AttendingPhysician" v-model="AttendingPhysician"  style="border: 1px solid;">
						<label for="Protocol" style="margin-left: 15px;">プロトコル：</label>
						<input name="Protocol" v-model="Protocol"  style="border: 1px solid;">
					</span>
					<span>
						<label for="Operator">オペレータ：</label>
						<input name="Operator" v-model="Operator"  style="border: 1px solid;">
					</span>
					<span>
						<label for="ContrastAgent">造影剤：</label>
						<textarea name="ContrastAgent" v-model="ContrastAgent" class="messageArea" style="border: 1px solid;"></textarea>
					</span>
					<span>
						<label for="ClinicalInfo">臨床情報：</label>
						<textarea name="ClinicalInfo" v-model="ClinicalInfo" class="messageArea" style="border: 1px solid;"></textarea>
					</span>
					<span>
						<label for="StudyComments">検査伝言：</label>
						<textarea name="StudyComments" v-model="StudyComments" class="messageArea" style="border: 1px solid;"></textarea>
					</span>
					<span>
						<label for="OrderComments">依頼伝言：</label>
						<textarea name="OrderComments" v-model="OrderComments" class="messageArea" style="border: 1px solid;"></textarea>
					</span>
					<span>
						<label for="OrderDocument">伝票：</label>
						<div id="dropZone_OD" style="border: 1px solid; padding: 30px; height: 300px;">
							<p>伝票ファイルをドラッグ＆ドロップもしくは</p>
							<input type="file" name="OrderDocument" id="OrderDocument" accept="image/png, image/jpeg" @change="changeFile()">
							<button id = "filedelete" @click="fileDelete()">ファイル削除</button>
							<div id="preview"></div>
						</div>
					</span>
				</div>
				<span style="float:right;">
					<button class="btn btn-sm btn-info" id="orderStart">オーダー</button>
					<button class="btn btn-sm btn-info" id="close-btn-orderDetail" style="margin-left: 10px;">キャンセル</button>
				</span>
			</span>
			<div class="black-background" id="black-bg-orderDetail" style="height: 1600px;"></div>
		</div>

		<div class="row justify-content-center">
			<div id="dropZone" class="container row justify-content-center col-lg-8 py-3"  style="border: 1px solid; padding: 30px;">
				<p style="color:gray;">こちらにDICOMファイルをドラッグ＆ドロップしてください</p>
				<div class="col-lg-12 px-0">
					<div id="status" class="alert alert-info">
						<div id="statusText">
							Status: ready_check (no file loaded)
						</div>
						<ul id="warnings"></ul>
					</div>

					<div id="dicon-preview-wrapper" class="row justify-content-center flex-column w-100 mx-auto hide-preview" style="position:relative; margin-bottom: 225px;">
						<!-- <div class="col p-1 bg-secondary text-white rounded-top">File Preview</div>
						<div class="col d-flex justify-content-center py-1" style="overflow-x: scroll;">
							<span v-for="n in filecount" :key="n" v-bind:id="'dicom-preview' + n" class='image' v-bind:filenumber="n" style="width: 200px; height: 200px;"></span>
						</div> -->

						<div class="col p-1 bg-secondary text-white rounded-top">File Preview</div>
						<div class="col d-flex center-block py-1 overflow-auto" id="ImagePreviewZone">
							<div 
								v-for="(item,index) in ImagePreviewList" 
								v-bind:key="index" class='imagelist' 
								v-bind:studynumber="item.stnum" 
								v-bind:seriesnumber_study="item.senum" 
								v-html="item.image" 
								@wheel.prevent="viewWheelControl_image($event)"
								style="min-width: 200px; min-height: 200px; position:relative; margin:0 5px 0 5px;">
							</div>
						</div>
						<!-- <div class="col d-flex justify-content-center py-1" id="ImageInputZone"> -->
						<div class="col d-flex center-block py-1 overflow-auto" id="ImageInputZone">
							<img v-for="(item,n) in JPEGlist" :key="n" v-bind:id="'dicom-preview' + n" class='image' v-bind:filenumber="n" style="max-width: 200px; max-height: 200px; min-width: 200px; min-height: 200px; position:relative; margin:0 5px 0 5px;" v-bind:src="item.src" v-bind:filename="item.filename_new" v-bind:sopUID="item.sopUID">
						</div>

					</div>
				</div>
				<div v-show="showGenre == 'dumpProgress'" id="overlay" @click.stop="showGenre=''">
					<div  class="popup_progress" @click="showGenre=''">
						<div style="float:right;">&#10006;</div> <!-- Shows Close Icon -->
						<h3>
							<div id="checkfile">
								<div>{{message_status_cf}}</div>
								<progress id="myProgress_cf" class="loading22" value="0" max="100">0%</progress>
								<span id="message_progress_cf"></span>
							</div>
							<!-- <div id="dumpfile">
								<div>{{message_status_df}}</div>
								<progress id="myProgress_df" class="loading22" value="0" max="100">0%</progress>
								<span id="message_progress_df"></span>
							</div> -->
						</h3>
					</div>
				</div>
			</div>
			<div class="text-end mt-3">
				<!-- <button id="allUpload" class="allstudy btn btn-sm btn-info" disabled="disabled" @click="select_storeData()">一括オーダー</button> -->
				<!-- <button id="OrderInput" class="allstudy btn btn-sm btn-info" disabled="disabled" @click="ViewInputZone()">オーダー情報入力</button> -->
				<button id="concludeUpload" class="allstudy btn btn-sm btn-info" disabled="disabled" @click="editOrder()">オーダー作成</button>
			</div>

			<div class="list-table mb-4">
				<table id="jpgTable" class="table table-striped table-bordered">
					<thead>
						<tr>
							<td v-bind:class="'px-2 py-1 align-middle ' + item.class" v-for="(item,index) in JPGitems" v-bind:key="index">
								{{ item.name }}
							</td>
							<!-- <td class="px-2 py-1 align-middle">
								<div class="text-primary" role="status">オーダー</div>
							</td> -->
						</tr>
					</thead>
					<tbody>
						<tr v-for="( data, n ) in JPGViewDatalist" :key="n" class='studyRow mouse_hover' v-bind:studynumber="n+1">
							<td v-bind:class="'col-sm-4 px-2 py-1 align-middle ' + item.class" v-for="(item,index) in JPGitems" v-bind:key="index" @click="viewControl_series($event,'study')">
								<span class="study" v-bind:data-dicom="item.code" v-bind:studynumber="n+1">{{ data[item.code] }}</span>
							</td>
							<!-- <td>
								<button class="study orderButton btn btn-sm btn-info" style="width: max-content;" v-bind:studynumber="n+1" @click="storeData($event,'study')">オーダー</button>
							</td> -->
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>


<script>
/* eslint no-irregular-whitespace: ["error", {"skipRegExps": true}] */

	import axios from "axios";
	// import {API_IP, API_PORT} from "../../public/const.json"
	// import Encoding from "../../node_modules/encoding-japanese/encoding.min";
	// import ce from "./checkExpired"
	import rd from "./readDicom"
	import { convertBytes } from 'dicom-character-set';


	export default {
		name: "JpegParser",
		data() {
			var twoDigit = function(value) {
				return ('0' + value).slice(-2);
			};
			var _today = new Date();
			return{
				filecount:0,
				okFileEntryList:[],
				okcmpFileNum:0,
				okFileList:[],
				cmpcount_check:0,
				ready_check:false,
				cmpcount_dump:0,
				ready_dump:false,
				noTargetcount:0,
				studycount:0,
				studylist:[],
				seriescount:0,
				serieslist:[],
				JPEGlist :[],
				dataSetList:[],
				StudydataSetList:[],
				SeriesdataSetList:[],
				JPGViewDatalist:[],
				SeriesViewDataList:[],
				JPGitems: [
					{ name: 'ファイル名', code: 'FileName', class:'' },
                    { name: '患者ID', code: 'PatientID', class:'' },
					{ name: '患者名', code: 'EditedPatientName', class:'' },
					{ name: '患者名（漢字）', code: 'EditedPatientIdeogram', class:'' },
					{ name: '患者名（カナ）', code: 'EditedPatinePhonetic', class:'' },
					{ name: '生年月日', code: 'BirthDate', class:'' },
					{ name: '年齢', code: 'Age', class:'' },
					{ name: '性別', code: 'Sex', class:'' },
					{ name: '検査日付', code: 'StudyDate', class:'' },
					{ name: '検査時間', code: 'StudyTime', class:'' },
					{ name: 'モダリティ', code: 'Modality', class:'' },
					{ name: 'Study Instance UID', code: 'StudyUID', class:'hide' }
                ],
				message: "",
				message_json: "",
				message_send: "",
				message_send_error: "",
				message_store: "",
				message_progress_cf:"",
				message_progress_df:"",
				message_status_cf:"",
				message_status_df:"",
				progres_finish_flg: false,
				progressCount_total_cf:0,
				progressCount_now_cf:0,
				progressCount_total_df:0,
				progressCount_now_df:0,
				postcount:0,
				sendJPG_cmp_check: false,
				sendJson_cmp_check: false,
				storeData_cmp_check: false,
				Base64text: "",
				dcm_CmpCount:0,
				seriesnumber_study:0,
				read_check:false,
				targetNum_study:0,
				targetNum_series:0,
				postMax_study:0,
				postMax_series:0,
				postMax_image:0,
				storeList:[],
				intervalID_checkfile:"",
				intervalID_dumpfile:"",
				orderUID_res:"",
				GroupName: "",
				DoubleReadingMode: "",
				Anonymously: "",
				GroupComment: "",
				Applicant: "",
				InterpretationRequests: "",
				OutPatientHospital: "",
				Departments: "",
				LedgerNumber: "",
				PatientID:"",
				EditedPatientName_last: "",
				EditedPatientName_first: "",
				EditedPatientIdeogram_last: "",
				EditedPatientIdeogram_first: "",
				EditedPatinePhonetic_last: "",
				EditedPatinePhonetic_first: "",
				Sex:"",
				BirthDate:"1980-01-01",
				Age:"",
				StudyDate: [
					_today.getFullYear(),
					twoDigit(_today.getMonth()+1),
					twoDigit(_today.getDate())
				].join('-'),
				// StudyDate:"",
				StudyTime:"00:00:00",
				Modality:"",
				BodyPart: "",
				AttendingPhysician: "",
				Protocol: "",
				Operator: "",
				ContrastAgent: "",
				ClinicalInfo: "",
				StudyComments: "",
				OrderComments: "",
				StudyUID:"",
				SeriesUID:"",
				OrderDocument: "",
				title: '読影オーダー（非DICOM）',
				btnHide: true,
				TargetModality: "",
				errorConclude:{},
				ImagePreviewList:[],
				ImagePreviewList_old:[],
				wheelCountFlg:false,
				imageCreated_check:false,
				now:"",
				Year: "",
				Month:"",
				dt:"",
				Hour:"",
				Min:"",
				Sec:"",
				MilliSec:"",
				now_edited:"",
				images:[],
				userID:"",
				pattern:"1",
				Laterality : {"jpg1":"","jpg2":""},
				showGenre:"",
				ordering:false,
			}
		},
		methods: {
			reloadPage() {
				// thisを使ってwindow.location.reload()を呼び出す
				window.location.reload();
			},
			async handleFileSelect(evt) {
				evt.preventDefault();
				const entries = [];
				// console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!")
				// console.log(evt.dataTransfer.files[0])
				for (var i = 0; i < evt.dataTransfer.items.length; i++) {
					// console.log(evt.dataTransfer.items[i])
					entries.push(evt.dataTransfer.items[i].webkitGetAsEntry());
				}

				const files = [];
				await this.readfile(files,entries)
				evt.stopPropagation();
				evt.preventDefault();
				document.getElementById("dropZone").style.background = '#ffffff';

				// $('#dicon-preview-wrapper').removeClass('hide-preview').addClass('show-preview')
				// $('#download').removeAttr('disabled')

				document.getElementById('dicon-preview-wrapper').classList.remove('hide-preview')
				document.getElementById('dicon-preview-wrapper').classList.add('show-preview')
				// document.getElementById('allUpload').disabled = false;
				document.getElementById('concludeUpload').disabled = false;
				document.getElementById('InputZone').style.display = "block";
				// document.getElementById('OrderInput').disabled = false;

				this.JPEGlist = [];

				// jpgファイル以外を除外
				this.checkFiles(files);
				this.settingInputZone()
				console.log("グループ名：" + this.GroupName)
				// var _this = this
			},

			handleDragOver(evt) {
				evt.stopPropagation();
				evt.preventDefault();
				evt.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
				document.getElementById('dropZone').style.background = '#e1e7f0';
			},
			async readfile(files,entries){
				for (const entry of entries) {
					await this.scanFiles(entry, files);
				}
				return;
			},
			async scanFiles(entry, tmpObject) {
				var _this = this
				async function readEntriesAsync(reader) {
					return new Promise((resolve, reject) => {
						reader.readEntries(entries => {
							resolve(entries);
						}, error => reject(error));
					})
				}
				async function enumerateDirectoryWithManyFiles(directoryEntry) {
					let reader = directoryEntry.createReader();
					let resultEntries = [];

					let read = async function() {
						let entries = await readEntriesAsync(reader);
						if (entries.length > 0) {
							resultEntries = resultEntries.concat(entries);
							await read();
						}
					}

					await read();
					return resultEntries;
				}
				switch (true) {
					case (entry.isDirectory) : {
						let entries = await enumerateDirectoryWithManyFiles(entry);
						await Promise.all(entries.map(async function (entry) {await _this.scanFiles(entry, tmpObject); }));
						break;
					}
					case (entry.isFile) : {
						tmpObject.push(entry);
						break;
					}
				}
				return;

            },
			// 正常なdcmファイル以外を除外
			checkFiles(files){
				console.log("ファイルチェック開始")
				this.JPGViewDatalist.length = 0;
				// 画像アップロード進捗表示
				this.okcmpFileNum = this.okFileEntryList.length
				this.progres_finish_flg = false
				this.OpenModal();
				this.message_status_cf = "アップロードファイルチェック中・・・"
				this.progressCount_now_cf = 0
				this.progressCount_total_cf = files.length
				// document.getElementById("checkfile").style.display = "block"
				// document.getElementById("dumpfile").style.display = "none"
				var _this = this
				_this.intervalID_checkfile = setInterval(function() {_this.updateProgress(_this.progressCount_now_cf,_this.progressCount_total_cf,document.getElementById('myProgress_cf'),document.getElementById('message_progress_cf'))}, 50);


				this.now = new Date();
				this.Year = String(this.now.getFullYear());
				this.Month = String(this.now.getMonth()+1);
				this.dt = String(this.now.getDate());
				this.Hour = String(this.now.getHours());
				this.Min = String(this.now.getMinutes());
				this.Sec = String(this.now.getSeconds());
				this.MilliSec = String(this.now.getMilliseconds());
				this.now_edited = this.Year + this.Month + this.dt + this.Hour + this.Min + this.Sec + this.MilliSec

				for( var i=0; i < files.length; i++ ){
					this.checkFile(files[i],files.length,i)
				}
			},
			// 正常なdcmファイル以外を除外
			checkFile(fileEntry,maxfile,count) {
				// jqueryもしくはjsのfunction使用中にthisの内容が変わってしまうため、変数に代入
				var _this = this
				// $('#status').removeClass('alert-warning alert-success alert-danger').addClass('alert-info');
				// $('#warnings').empty();

				document.getElementById('status').classList.remove('alert-warning')
				document.getElementById('status').classList.remove('alert-success')
				document.getElementById('status').classList.remove('alert-danger')
				document.getElementById('status').classList.add('alert-info')
				document.getElementById('statusText').innerHTML = 'Status: Loading file, please wait..';
				document.getElementById('warnings').innerHTML = ''

				fileEntry.file(function (file) {
					var reader = new FileReader();
					reader.onload = function(file) {
						var arrayBuffer = reader.result;
						// Here we have the file data as an ArrayBuffer.  dicomParser requires as input a
						// Uint8Array so we create that here
						var byteArray = new Uint8Array(arrayBuffer);
						// console.log(_this.base642ab(file.target.result))
						var kb = byteArray.length / 1024;
						var mb = kb / 1024;
						var byteStr = mb > 1 ? mb.toFixed(3) + " MB" : kb.toFixed(0) + " KB";
						document.getElementById('statusText').innerHTML = '<span class="glyphicon glyphicon-cog"></span>Status: Parsing ' + byteStr + ' bytes, please wait..';
						// set a short timeout to do the parse so the DOM has time to update itself with the above message
						// Invoke the paresDicom function and get back a DataSet object with the contents
						try {
							_this.progressCount_now_cf += 1;
							_this.cmpcount_check += 1;
							var searchResult_file = _this.JPEGlist.find((v) => v.fullPath === fileEntry["fullPath"]);
							// ファイル名のみ異なる同一ファイルを削除
							if(!searchResult_file){
								// jpgファイル以外は除外
								if(fileEntry["name"].split(".")[1] == "jpg" || fileEntry["name"].split(".")[1] == "JPG"){
									document.getElementById("dicon-preview-wrapper").style.marginBottom = "0px";
									_this.okFileEntryList.push(fileEntry);
									var base64 = file.target.result
									_this.userID = localStorage.getItem('userID')
									// ファイル名設定
									var filename = "1.2.392.200193.4.902." + _this.userID + "." + _this.now_edited + ".1." + (count+1) + ".jpg"
									_this.StudyUID = "1.2.392.200193.2.902." + _this.userID + "." + _this.now_edited
									_this.SeriesUID = "1.2.392.200193.2.902." + _this.userID + "." + _this.now_edited + ".1"
									var sopUID = "1.2.392.200193.4.902." + _this.userID + "." + _this.now_edited + ".1." + (count+1)
									_this.JPEGlist.push({fullPath: fileEntry["fullPath"], filename: fileEntry["name"],src: base64,filename_new: filename,sopUID:sopUID});
									_this.filecount += 1;
									document.getElementById('statusText').innerHTML = 'Status: ready_check (file of size '+ byteStr + ")";
									_this.set_jpegInfo(filename);
								}else{
									document.getElementById('statusText').innerHTML = 'Status: ready_check - no pixel data found (file of size ' + byteStr;
									console.log('NoTargetFile1');
								}
							}else{
								document.getElementById('statusText').innerHTML = 'Status: ready_check - no pixel data found (file of size ' + byteStr;
								console.log('NoTargetFile2');
							}
						}
						catch(err){
							console.log(err);
							console.log('NoTargetFile3');
						}
						finally{
							if((_this.cmpcount_check) == maxfile){
								_this.ready_check = true;
								_this.cmpcount_check = 0;
							}
						}

					}

					reader.readAsDataURL(file);
				});
			},
			set_jpegInfo(filename){
				let columnDict = {}
				console.log("ファイル情報追加")
				console.log("ファイル名：" + filename)
				console.log(this.JPGitems)

				// this.StudyDate = this.Year + "-" + this.Month + "-" + this.dt
				for (let j=0; j < this.JPGitems.length; j++){
					let code = this.JPGitems[j]["code"];
					let str = ""
					if(code == "FileName"){
						str = filename
					}else if(code.includes("Date")){
						str = this[code].replace(/-/g, '/')
					}else{
						str = this[code]
					}
					if(str==null){
						columnDict[code] = "";
					}else{
						columnDict[code] = str;
					}
				}
				// if(filenum == 0){
				// 	this.JPGViewDatalist.length = 0;
				// }
				this.JPGViewDatalist.push(columnDict);
				// console.log(this.JPGViewDatalist)
				this.dialogControl();
			},
			setTable(e){
				console.log("セットテーブル")
				console.log(e)
				console.log(this.JPGViewDatalist)
				var key = ""
				var value = ""
				if(e.target.name.includes("_last")){
					let lastname = e.target.name
					let fullname = e.target.name.replace("_last","")
					let firstname = fullname + "_first"
					key = fullname
					value = this[lastname] + " " + this[firstname]
					console.log(this[lastname])
					console.log(this[firstname])
				}else if(e.target.name.includes("_first")){
					let firstname = e.target.name
					let fullname = e.target.name.replace("_first","")
					let lastname = fullname + "_last"
					key = fullname
					value = this[lastname] + " " + this[firstname]
				}else if(e.target.name.includes("Date")){
					key = e.target.name
					value = e.target.value.replace(/-/g, '/')
				}else{
					key = e.target.name
					value = e.target.value
				}
				for(let i=0;i<this.JPGViewDatalist.length;i++){
					this.JPGViewDatalist[i][key] = value
					if(e.target.name == "BirthDate"){
						this.JPGViewDatalist[i]["Age"] = this.Age
					}
				}
			},
			async showImage_onetime(type){
				if(document.getElementById("ImagePreviewZone").querySelectorAll(".imagelist").length !== 0){
					var imagelists = document.getElementById("ImagePreviewZone").querySelectorAll(".imagelist")
					for( let i=0; i < imagelists.length; i++ ){
						if(type == "show"){
							imagelists[i].classList.add("show_onetime")
						}else{
							imagelists[i].classList.remove("show_onetime")
						}
					}
					return;
				}else{
					return;
				}
			},
			get_age(e){
				var BirthDay_year = this.BirthDate.split("-")[0]
				var BirthDay_month = this.BirthDate.split("-")[1]
				var BirthDay_date = this.BirthDate.split("-")[2]
				//今日
				var today = new Date();
				//今年の誕生日
				var thisYearsBirthday = new Date(today.getFullYear(), BirthDay_month-1, BirthDay_date);
				//年齢
				var Patient_Age = today.getFullYear() - BirthDay_year;
				if(today < thisYearsBirthday){
					//今年まだ誕生日が来ていない
					Patient_Age--;
				}
				this.Age = Patient_Age;
				this.setTable(e)
				return;
			},
			dialogControl(){
				if(localStorage.getItem("autoSetPattern")){
					this.pattern = localStorage.getItem("autoSetPattern")
				}
				if(document.getElementById("dialog").style.display == "none"){
					document.getElementById("dialog").style.display = "block"
				}else{
					document.getElementById("dialog").style.display = "none"
				}
			},
			// ファイル名からオーダー情報を自動入力する
			AutoSetFromFilename(answer){
				if(answer == "yes"){
					if(this.pattern == "1"){
						this.PatientID = this.JPEGlist[0]["filename"].split("_")[0]
						this.StudyDate = this.JPEGlist[0]["filename"].split("_")[1].substring(0,4) + "-" + this.JPEGlist[0]["filename"].split("_")[1].substring(4,6) + "-" + this.JPEGlist[0]["filename"].split("_")[1].substring(6)
						this.StudyTime = this.JPEGlist[0]["filename"].split("_")[2].substring(0,2) + ":" + this.JPEGlist[0]["filename"].split("_")[2].substring(2,4) + ":" + this.JPEGlist[0]["filename"].split("_")[2].substring(4)
					}else if(this.pattern == "2"){
						this.Laterality["jpg1"] = ""
						this.Laterality["jpg2"] = ""
						this.PatientID = this.JPEGlist[0]["filename"].split("_")[0]
						this.StudyDate = this.JPEGlist[0]["filename"].split("_")[4].substring(0,4) + "-" + this.JPEGlist[0]["filename"].split("_")[4].substring(4,6) + "-" + this.JPEGlist[0]["filename"].split("_")[4].substring(6)
						this.StudyTime = this.JPEGlist[0]["filename"].split("_")[5].split(".")[0].substring(0,2) + ":" + this.JPEGlist[0]["filename"].split("_")[5].split(".")[0].substring(2,4) + ":" + this.JPEGlist[0]["filename"].split("_")[5].split(".")[0].substring(4)
						for( let i=0; i < this.JPEGlist.length; i++ ){
							if(this.JPEGlist[i]["filename"].split("_")[3].indexOf("OS") != -1){
								this.Laterality["jpg" + (i+1)] = "L"
							}
							if(this.JPEGlist[i]["filename"].split("_")[3].indexOf("OD") != -1){
								this.Laterality["jpg" + (i+1)] = "R"
							}
						}
					}
				}
				localStorage.setItem("autoSetPattern",this.pattern);
				document.getElementById("dialog").style.display = "none"
			},
			// ImageInputZoneに入った画像をImagePreviewZoneに移動
			async moveImage(){
				// サムネイルのマウスホイールによる切り替え表示
				for( let i=0; i < this.studylist.length; i++ ){
					var imageObj_study = document.getElementById("ImageInputZone").querySelectorAll(".image[studynumber='" + (i+1) + "']")
					console.log(imageObj_study)
					var stnum = i + 1
					for( let k=0; k < imageObj_study.length; k++ ){
						var seriesnumber_study_list = {}
						var senum = imageObj_study[k].getAttribute("seriesnumber_study")
						var searchResult_image = this.ImagePreviewList.find((v) => v.stnum === stnum && v.senum === senum);
						if(searchResult_image){
							searchResult_image["image"] += imageObj_study[k].outerHTML
						}else{
							seriesnumber_study_list["stnum"] = stnum
							seriesnumber_study_list["senum"] = senum
							// htmlコンテンツをレンダリングしても表示できないため、outerHTML使用、
							seriesnumber_study_list["image"] = imageObj_study[k].outerHTML
							this.ImagePreviewList_old.push(seriesnumber_study_list)
							this.ImagePreviewList.push(seriesnumber_study_list)
						}
						this.progressCount_now_df += 1;
					}
				}
				this.progres_finish_flg = true

				document.getElementById("ImageInputZone").innerHTML = ""
				document.getElementById("dicon-preview-wrapper").style.marginBottom = "0px"

				return;
			},
			strEncode(SpecificCharacterSet,dataSet,byteArray){
				console.log("変換前：" + byteArray);
				console.log("予約語：" + SpecificCharacterSet)
				let patientNameStr = ""
				let patientNameBytes = rd.sharedCopyFile(dataSet,byteArray)
				patientNameStr = convertBytes(SpecificCharacterSet.replace(/'\\'/g,'\\\\'),patientNameBytes, {vr: 'PN'});
				console.log("変換後：" + patientNameStr);
				// 文字列が漢字のみか半角カナのみか、全角カナのみかをチェック
				patientNameStr = this.strCheck(patientNameStr)
				return patientNameStr
			},
			strCheck(str){
				let array = str.split("=")
				var str_edited = ""
				// 第二文字列が漢字のみかチェック
				let str_second = ""
				var regexp = /^([\u{3005}\u{3007}\u{303b}\u{3400}-\u{9FFF}\u{F900}-\u{FAFF}\u{20000}-\u{2FFFF}][\u{E0100}-\u{E01EF}\u{FE00}-\u{FE02}]?)+$/mu;
				if(array.length >= 2){
					let kanjiCheck = regexp.test(array[1].replace("^","").replace(/\s+/g,''))
					if(!kanjiCheck){
						str_second = ""
					}else{
						str_second = array[1]
					}
					str_edited = array[0] + "=" + str_second
				}else {
					str_edited = array[0]
				}

				let str_third = ""
				if(array.length == 3){
					console.log("チェック前第三文字列：" + array[2].replace("^","").replace(/\s+/g,''))
					// 第三文字列が全角カナ・半角カナのみかチェック
					regexp = new RegExp(/^[ァ-ヶー　]+$/);
					let ZenkakuKanaCheck = regexp.test(array[2].replace("^","").replace(/\s+/g,''))
					regexp = new RegExp(/^[ｦ-ﾟ]*$/);
					let HankakuKanaCheck = regexp.test(array[2].replace("^","").replace(/\s+/g,''))
					console.log(HankakuKanaCheck)
					if(!ZenkakuKanaCheck && !HankakuKanaCheck){
						str_third = ""
					}else{
						str_third = array[2]
					}
					str_edited = str_edited + "=" + str_third
				}
				console.log("チェック後：" + str_edited)
				return str_edited;
			},
			// シリーズ・画像項目の集計
			countSet(){
				for( var i=0; i < this.JPGViewDatalist.length; i++ ){
					console.log(document.querySelectorAll('span[data-dicom="seriescount"].study'))
					var studynumber = document.querySelectorAll('span[data-dicom="seriescount"].study')[i].getAttribute('studynumber');
					var count = document.querySelectorAll('tr[studynumber="' + studynumber + '"].seriesRow').length;
					this.JPGViewDatalist[i]["seriescount"] = count;
				}
				document.querySelectorAll('span[data-dicom="imagecount_study"].study').forEach(function(value)
				{
					var studynumber = value.getAttribute('studynumber');
					var count = document.querySelectorAll('div[studynumber="' + studynumber + '"].image').length;
					value.textContent = count;
				});
				document.querySelectorAll('span[data-dicom="imagecount_series"].series').forEach(function(value)
				{
					var studynumber = value.getAttribute('studynumber');
					var seriesnumber = value.getAttribute('seriesnumber_study');
					var count = document.querySelectorAll('div[studynumber="' + studynumber + '"][seriesnumber_study="' + seriesnumber + '"].image').length;
					value.textContent = count;
				});
			},
			makeRandomString(length){
				var text = "";
				var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

				for( var i=0; i < length; i++ )
					text += possible.charAt(Math.floor(Math.random() * possible.length));

				return text;
			},
			pad(num, size) {
				var s = num+"";
				while (s.length < size) s = "0" + s;
				return s;
			},
			makeDeIdentifiedValue(length, vr) {
				var now = ""
				if(vr === 'LO' || vr === "SH" || vr === "PN") {
					return this.makeRandomString(length);
				}
				else if(vr === 'DA') {
					now = new Date();
					return now.getYear() + 1900 + this.pad(now.getMonth() + 1, 2) + this.pad(now.getDate(), 2);
				} else if(vr === 'TM') {
					now = new Date();
					return this.pad(now.getHours(), 2) + this.pad(now.getMinutes(), 2) + this.pad(now.getSeconds(), 2);
				}
				console.log('unknown VR:' + vr);
			},
			// シリーズのDOM要素の表示切り替え
			viewControl_series(event){
				var studynumber = event.currentTarget.parentNode.getAttribute('studynumber')
				var elm = document.getElementsByClassName('seriesRow');
				for(var i=0;i<elm.length;i++){
					elm[i].style.display = 'none';
				}
				document.querySelectorAll('tr[studynumber].studyRow').forEach(function(value)
				{
					value.style.backgroundColor = 'white';
				})
				document.querySelectorAll('tr[studynumber="' + studynumber + '"].studyRow').forEach(function(value)
				{
					value.style.backgroundColor = 'rgb(118, 183, 239)';
				})
				document.querySelectorAll('tr[studynumber="' + studynumber + '"].seriesRow').forEach(function(value)
				{
					value.style.display = 'table-row';
				})

				this.viewControl_image(event,"study")
			},
			// 画像のDOM要素の表示切り替え
			viewControl_image(event,type){
				var studynumber = event.currentTarget.parentNode.getAttribute('studynumber')
				var elm = document.getElementsByClassName('imagelist')
				for(var i=0;i<elm.length;i++){
					// elm[i].classList.remove("show");
					// elm[i].classList.add("showhide");
					elm[i].style.display = "none"
				}
				var target_imagelist = ""
				if(type == "study"){
					target_imagelist = document.querySelectorAll('div[studynumber="' + studynumber + '"].imagelist')
				}else{
					var seriesnumber = event.currentTarget.parentNode.getAttribute('seriesnumber_study')
					document.querySelectorAll('tr[studynumber][seriesnumber_study].seriesRow').forEach(function(value)
					{
						value.style.backgroundColor = 'white';
					});
					document.querySelectorAll('tr[studynumber="' + studynumber + '"][seriesnumber_study="' + seriesnumber + '"].seriesRow').forEach(function(value)
					{
						value.style.backgroundColor = 'rgb(118, 183, 239)';
					});

					// 対象の画像リストのみ表示
					target_imagelist = document.querySelectorAll('div[studynumber="' + studynumber + '"][seriesnumber_study="' + seriesnumber + '"].imagelist')
				}
				target_imagelist.forEach(function(value)
				{
					// value.classList.add("show");
					// value.classList.remove("showhide");
					value.style.display = "block"
					// if(type == "study"){
					// 	value.style.margin = "0px 5px"
					// }else{
					// 	value.style.margin = "0px auto"
					// }
					value.childNodes.forEach(function(child,index)
					{
						if(index == 0){
							child.classList.add("show");
							child.classList.remove("showhide");
						}else{
							child.classList.remove("show");
							child.classList.add("showhide");
						}
					});
				});
			},
			viewWheelControl_image(event){
				var _this = this
				var count = 1;
				var contents = event.currentTarget.childNodes
				contents.forEach(function(target,index)
				{
					if(target.classList.contains('show') == true){
						count = index + 1
					}
				});
				// countFlgがfalseの場合だけ動く
				if (!_this.wheelCountFlg) {
					// ホイールが下方向だったら
					if (event.deltaY > 0) {
						// countの値をプラス
						count++;
						// countの値の上限をコンテンツの数とする
						if (count > contents.length) {
							count = 1;
						}
					}
					// ホイールが上方向だったら
					else if (event.deltaY < 0) {
						//countの値をマイナスにする
						count--;
						// countの値の下限を1とする
						if (count < 1) {
							// count = 1;
							count = contents.length;
						}
					}
					// countFlgをtrueにする
					_this.wheelCountFlg = true;
					
					// 数秒後、countFlgをfalseにして、またホイールのイベントで動くように
					setTimeout(function () {
						_this.wheelCountFlg = false;
					},100 ); // 秒数を指定。ミリ秒
					// 一旦コンテンツを全部非表示にし、
					for (var i = 0; i < contents.length; i++) {
						contents[i].classList.remove('show'); // showクラスを削除して非表示に
						contents[i].classList.add("showhide");
					}
					// 該当コンテンツのみ表示
					contents[count - 1].classList.add('show'); // showクラスを付与して表示
					contents[count - 1].classList.remove("showhide");
				}
			},
			// オーダー情報入力欄を開く
			editOrder(event,jenre){
				this.clearInputZone();
				var _this = this
				const eventDom = event
				var popup = document.getElementById("InputZone");
				popup.classList.add('is-show');
				var startBtn = document.getElementById('orderStart');
				var blackBg = document.getElementById('black-bg-orderDetail');
				var closeBtn = document.getElementById('close-btn-orderDetail');
				closePopUp(blackBg);
				closePopUp(closeBtn);
				closePopUp(startBtn);
				function closePopUp(elem) {
					if (!elem) return;

					function clickHandler(e) {
						e.stopPropagation();
						popup.classList.remove('is-show');
						if (elem.id === "orderStart") {
						_this.storeData(eventDom, jenre);
						}
						// イベントリスナーを削除する
						elem.removeEventListener('click', clickHandler);
					}
					elem.addEventListener('click', clickHandler);
				}
			},
			clearInputZone(){
				this.GroupName = "";
				this.DoubleReadingMode = "";
				this.Anonymously = "";
				this.GroupComment = "";
				this.Applicant = "";
				this.InterpretationRequests = "";
				this.OutPatientHospital = "";
				this.Departments = "";
				this.LedgerNumber = "";
				this.EditedPatientName = "";
				this.EditedPatientIdeogram = "";
				this.EditedPatinePhonetic = "";
				this.BodyPart = "";
				this.AttendingPhysician = "";
				this.Protocol = "";
				this.Operator = "";
				this.ContrastAgent = "";
				this.ClinicalInfo = "";
				this.StudyComments = "";
				this.OrderComments = "";

				this.settingInputZone();
				this.fileDelete();
			},
			// 画像、JSONをサーバーにアップロード
			async storeData(){
				if(this.PatientID != "" && this.StudyDate != "" && this.Modality != ""){
					console.log(localStorage);
					// _this.screenLock();
					this.ordering = true
					this.message = "アップロード中・・・"
					document.getElementById("head").classList.add("disabled");
					setTimeout(() =>{
						console.log(localStorage);

						var orderJson = {
								OrderGroup:{
									Name:"",
									Order:{
										OrderDate:"",
										OrderTime:"",
										Anonymously:1,
										ReadingSystem:"EsPACS",
										ReadingMode:"EsPACS",
										DoubleReadingMode:0,
										OrderParent:"",
										OrderInstitution:"",
										OrderDepartment:"",
										ImageInfo:[],
										OrderInfo:{
											LedgerNumber:"",
											Applicant:"読影管理",
											InterpretationRequests:0,
											OutPatientHospital:0,
											Departments:"整形外科",
											AttendingPhysician:"佐々木",
											Protocol:"",
											Operator:"",
											ContrastAgent:"",
											ClinicalInfo:"臨床情報1",
											StudyComments:"",
											OrderDocument:{
												Format:"",
												Encode:"",
												Image:""
											},
											OrderComments:"",
											EditedPatientName:"",
											EditedPatientIdeogram:"",
											EditedPatinePhonetic:"",
										}
									}
								}
						}

						this.message_json = "";
						this.message_send = "";
						this.message_send_error = "";
						this.message_store = "";

						this.dcm_CmpCount = 0;
						this.sendJPG_cmp_check = false;
						this.sendJson_cmp_check = false;

						var _this = this

						var imageElement = document.getElementsByClassName("image")
						this.postMax_image = imageElement.length
						for (let i=0;i<imageElement.length;i++){
							document.getElementById("head").classList.add("disabled");
							// オーダーJson作成
							_this.settingJson(orderJson,i,imageElement[i].getAttribute("sopUID"));
							_this.sendJPG(imageElement[i],i)
							document.getElementById("head").classList.remove("disabled");
						}

						var check_sendJson_cmp = function() {
							if (_this.sendJPG_cmp_check === true) {
								// ファイルの中身を見てDOM要素作成
								_this.sendJPG_cmp_check = false;
								_this.postcount_json = 0;
								// Json送信
								_this.sendJson(orderJson);
							}
							setTimeout(check_sendJson_cmp, 1000);
						}
						check_sendJson_cmp();
						var check_sendJPG_cmp = function() {
							if (_this.sendJson_cmp_check === true) {
								_this.sendJson_cmp_check = false;
								console.log(_this.dcm_CmpCount + "件のDICOMファイルが保存できました。");
								_this.storeDCM();
							}
							setTimeout(check_sendJPG_cmp, 1000);
						}
						check_sendJPG_cmp();
					},1000);
				}else{
					window.alert("必須項目の入力を行ってください。");
				}
				return;
			},
			// div削除関数
			delete_dom_obj( id_name ){
				var dom_obj = document.getElementById(id_name);
				var dom_obj_parent=dom_obj.parentNode;
				dom_obj_parent.removeChild(dom_obj);
			},
			// プログレスバーを更新する
			updateProgress(now,total,progress,message) {
				try{
					var _this = this;
					// プログレスバーの進捗値を更新し、プログレスバーに反映させる
					var val = Math.floor(now / total * 100);
					progress.value = val;
					progress.innerText = val + "%";
					message.textContent = "(" + val + "%)"
					console.log("進捗：" + val + "%")

					// 最大値まで達したら終了
					if (val == 100) {
						setTimeout(() =>{
							_this.CloseModal()
							clearInterval(_this.intervalID_checkfile)
						},100)
					}
				}catch(err){
					// $('#status').removeClass('alert-success alert-info alert-warning').addClass('alert-danger');
					// document.getElementById('statusText').innerHTML = 'Status: Error - ' + err + ' (file of size ' + byteStr + ' )';
					document.getElementById('status').classList.remove('alert-success')
					document.getElementById('status').classList.remove('alert-info')
					document.getElementById('status').classList.remove('alert-warning')
					document.getElementById('status').classList.add('alert-danger')
					document.getElementById('statusText').innerHTML = 'Status: Error - ' + err;
					_this.CloseModal();
					clearInterval(_this.intervalID_checkfile)
					// clearInterval(_this.intervalID_dumpfile)
				}
			},
			OpenModal() {
				let element = document.getElementById('overlay')
				element.style.display = 'block'
			},
			CloseModal() {
				let element = document.getElementById('overlay')
				element.style.display = 'none'
			},
			async sendJPG(img,itemcount){
				let _this = this
				const post_url = this.$store.state.API_HOST + '/send_jpg'

				// console.log("ファイルチェック")
				// console.log(_this.okFileEntryList[itemcount]["fullPath"])
				let jpgInfoDict = {filename:img.getAttribute("filename"), jpgdata:img.getAttribute("src"), modality:this.Modality,
					stuinsuid:this.StudyUID, seriesuid:this.SeriesUID,patid:this.PatientID, patnam:this.EditedPatientName_last + " " + this.EditedPatientName_first,
					studate:this.StudyDate, stutime:this.StudyTime, accnum:this.LedgerNumber,stuid:"",sopUID:img.getAttribute("sopuid"),
					refphy:this.AttendingPhysician, studesc:this.StudyComments, patbirdate:this.BirthDate, patsex:this.Sex, patage:this.Age,laterality:this.Laterality["jpg"+(itemcount+1)], imagenumber:String((1001+itemcount))
				}
				let data = {"jpgInfoDict":jpgInfoDict}

				await axios.post(
					post_url,
					data,
					{
					params: {
						'app_name': _this.$store.state.APP_NAME,
						'user_id': _this.$store.state.userID,
					},
					headers: {
						'accept': 'application/json',
						'Content-Type': 'application/json',
					},
					withCredentials: true
					}
				).then(
					async function(response) {
						if(typeof(response.data) != "string"){
							if(response.data.res.indexOf('.dcm') != -1){
								this.dcm_CmpCount += 1;
								this.message_send = "DICOM送信  " + this.dcm_CmpCount + "/" + this.postMax_image;
								console.log("送信成功")
								this.storeList.push(response.data.res)
							}else{
								let sopUID = img.getAttribute("sopUID")
								if(!this.errorConclude[sopUID]){
									this.errorConclude[sopUID] = 1
									await this.sendJPG(img,itemcount)
								}else{
									console.log(this.errorConclude[sopUID])
									if(this.errorConclude[sopUID] == 10){
										this.message_send_error += "エラー [11] [" + this.okFileEntryList[itemcount]["fullPath"] + "]\n";
										this.ordering = false
									}else{
										console.log("再送信：" + this.okFileEntryList[itemcount]["fullPath"])
										this.errorConclude[sopUID] = this.errorConclude[sopUID] + 1
										await this.sendJPG(img,itemcount)
									}
								}
							}
						}else{
							_this.$store.dispatch('logout');
							_this.$store.commit('setLoginUser', '')
						}
					}.bind(this)
				)
				.catch(
					async function(error) {
						let sopUID = img.getAttribute("sopUID")
						if(!this.errorConclude[sopUID]){
							this.errorConclude[sopUID] = 1
							await this.sendJPG(img,itemcount)
						}else{
							console.log(this.errorConclude[sopUID])
							if(this.errorConclude[sopUID] == 10){
								this.message_send_error += this.message_send_error += "エラー [12] [" + this.okFileEntryList[itemcount]["fullPath"] + "]\n";
								this.ordering = false
								console.log(error.response);
								console.log("POSTエラー");
							}else{
								console.log("エラー再送信：" + this.okFileEntryList[itemcount]["fullPath"])
								this.errorConclude[sopUID] = this.errorConclude[sopUID] + 1
								await this.sendJPG(img,itemcount)
							}
						}
					}.bind(this)
				)
				.finally(
					function() {
						console.log("jpgファイル送信完了：" + this.dcm_CmpCount)
						console.log("jpgファイル送信最大：" + this.postMax_image)
						if(this.dcm_CmpCount == this.postMax_image){
							console.log("sendJPG正常終了")
							setTimeout(this.sendJPG_cmp_check = true, 500);
						}
					}.bind(this)
				);
			},
			createImageData(img) {
				this.images.push({filename: img.getAttribute("filename"),jpgdata:img.getAttribute("src")});
				return;
			},
			storeDCM(){
				let _this = this;
				console.log("ストア開始")
				this.message_store = "DICOMストア中・・・"
				var storeobj = {"orderuid":this.orderUID_res,"fileList":this.storeList}
				const post_url = this.$store.state.API_HOST + '/store_dicom'
				axios.post(
					post_url,
					storeobj,
					{
						params: {
							'app_name': _this.$store.state.APP_NAME,
							'user_id': _this.$store.state.userID,
						},
						headers: {
							'accept': 'application/json',
							'Content-Type': 'application/json',
						},
						withCredentials: true
					}
				).then(
					function(response) {
						console.log(response)
						if(typeof(response.data) != "string"){
							if(!isNaN(response.data.res)){
								if(response.data == this.postMax_image){
									this.message = "アップロードが完了しました。";
									this.message_store = "DICOMストア完了  " + response.data.res + "/" + this.postMax_image
									this.ordering = false
									document.getElementById("head").classList.remove("disabled");
									this.storeList.splice(0)
									this.storeData_cmp_check = true
								}else{
									this.message_store = "DICOMストア完了  " + response.data.res + "/" + this.postMax_image
									this.ordering = false
								}
							}else{
								this.message_store = "画像更新できませんでした。(13)";
								this.ordering = false
								_this.$store.dispatch('logout');
								_this.$store.commit('setLoginUser', '')
							}
							console.log("ストア終了")
						}else{
							_this.$store.dispatch('logout');
							_this.$store.commit('setLoginUser', '')
						}
					}.bind(this)
				)
				.catch(
					function(error) {
						this.message_json = "画像更新できませんでした。(14)";
						this.result = "POSTエラー";
						this.ordering = false
						console.log(error.response);
						console.log(this.result)
						console.log("ストア終了")
					}.bind(this)
				);
				document.getElementById("head").classList.remove("disabled");
			},
			settingInputZone(){
				var date = new Date();
				var editDate = date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2)
				var editTime = ('0' + date.getHours()).slice(-2) + ('0' + date.getMinutes()).slice(-2) + ('0' + date.getSeconds()).slice(-2)
				this.GroupName = localStorage.getItem('userID') + "-" + editDate
				this.DoubleReadingMode = 0
				this.Applicant = "読影管理"
				this.InterpretationRequests = 0
				this.OutPatientHospital = -1
				this.Departments = "整形外科"
				// 台帳番号
				this.LedgerNumber = (editDate + editTime)
				// 部位
				this.BodyPart = ""
			},
			settingJson(orderJson,count,sopUID){
				var date = new Date();
				var editDate = date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2)
				var editTime = ('0' + date.getHours()).slice(-2) + ('0' + date.getMinutes()).slice(-2) + ('0' + date.getSeconds()).slice(-2)
				console.log('オーダー日時：' + date.getFullYear() + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' +('0' + date.getDate()).slice(-2) + ' ' +  ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2));
				if(count == 0){
					orderJson["OrderGroup"]["Name"] = this.GroupName
					orderJson["OrderGroup"]["Order"]["OrderDate"] = editDate
					orderJson["OrderGroup"]["Order"]["OrderTime"] = editTime
					orderJson["OrderGroup"]["Order"]["DoubleReadingMode"] = this.DoubleReadingMode
					if(document.getElementById("Anonymously").checked){
						orderJson["OrderGroup"]["Order"]["Anonymously"] = 1
					}else{
						orderJson["OrderGroup"]["Order"]["Anonymously"] = 0
					}
					var studyJson = {
						StudyInfo:{
							PatientID:"",
							PatientAE:"HOSP01",
							StudyInstanceUID:"",
							StudyModalities:"",
							StudyDate:0,
							SourceAETitle:"DICOMSRV",
							MoveImage:"yes",
							PastMoveImage:"yes",
							PastStudyFilter:{
								Modality:"",
								StudyDate:{
									From:"",
									To:""
								}
							},
							Sex:"",
							BirthDate:"",
							Age:"",
							StudyTime:"",
							Modality:"",
							BodyPart:"",
							SOPInstanceUID:[]
						}
					}
					studyJson["StudyInfo"]["PatientID"] = this.PatientID
					studyJson["StudyInfo"]["StudyInstanceUID"] = this.StudyUID
					studyJson["StudyInfo"]["StudyModalities"] = this.Modality
					studyJson["StudyInfo"]["StudyDate"] = this.StudyDate.replace(/-/g, '')
					studyJson["StudyInfo"]["StudyTime"] = this.StudyTime.replace(/:/g, '')
					studyJson["StudyInfo"]["Sex"] = this.Sex
					studyJson["StudyInfo"]["BirthDate"] = this.BirthDate.replace(/-/g, '')
					studyJson["StudyInfo"]["Age"] = this.Age
					studyJson["StudyInfo"]["BodyPart"] = this.BodyPart
					orderJson["OrderGroup"]["Order"]["ImageInfo"].push(studyJson)

					// console.log("レッジャー：" + dataSet.string("x00080050"))
					orderJson["OrderGroup"]["Order"]["OrderInfo"]["Applicant"] = this.Applicant
					orderJson["OrderGroup"]["Order"]["OrderInfo"]["InterpretationRequests"] = this.InterpretationRequests
					orderJson["OrderGroup"]["Order"]["OrderInfo"]["OutPatientHospital"] = this.OutPatientHospital
					orderJson["OrderGroup"]["Order"]["OrderInfo"]["Departments"] = this.Departments
					orderJson["OrderGroup"]["Order"]["OrderInfo"]["LedgerNumber"] = (editDate + editTime)
					orderJson["OrderGroup"]["Order"]["OrderInfo"]["EditedPatientName"] = this.EditedPatientName_last + " " + this.EditedPatientName_first
					orderJson["OrderGroup"]["Order"]["OrderInfo"]["EditedPatientIdeogram"] = this.EditedPatientIdeogram_last + " " + this.EditedPatientIdeogram_first
					orderJson["OrderGroup"]["Order"]["OrderInfo"]["EditedPatinePhonetic"] = this.EditedPatinePhonetic_last + " " + this.EditedPatinePhonetic_first
					orderJson["OrderGroup"]["Order"]["OrderInfo"]["AttendingPhysician"] = this.AttendingPhysician
					orderJson["OrderGroup"]["Order"]["OrderInfo"]["Protocol"] = this.Protocol
					orderJson["OrderGroup"]["Order"]["OrderInfo"]["Operator"] = this.Operator
					orderJson["OrderGroup"]["Order"]["OrderInfo"]["ContrastAgent"] = this.ContrastAgent
					orderJson["OrderGroup"]["Order"]["OrderInfo"]["ClinicalInfo"] = this.ClinicalInfo
					orderJson["OrderGroup"]["Order"]["OrderInfo"]["StudyComments"] = this.StudyComments
					orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderComments"] = this.OrderComments

					// 伝票
					if(this.OrderDocument !== null){
						orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Encode"] = "Base64"

						var imagetype = this.OrderDocument.type
						if(imagetype == "image/jpeg"){
							orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Format"] = "JPEG"
						}else if(imagetype == "image/png"){
							orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Format"] = "PNG"
						}

						orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Image"] = this.Base64text
					}else{
						orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Encode"] = ""
						orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Format"] = ""
						orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Image"] = ""
					}
				}

				orderJson["OrderGroup"]["Order"]["ImageInfo"][0]["StudyInfo"]["SOPInstanceUID"].push({"UID":sopUID});
				console.log(orderJson)
			},
			changeFile(){
				if(document.getElementById("OrderDocument").files[0] !== undefined){
					// 伝票をBase64文字列にエンコード
					var uploadImage = document.querySelector('#OrderDocument')
					var orderdocumentFile = uploadImage.files[0]
					var orderdocumentreader = new FileReader()
					orderdocumentreader.onload = (event) => {
						// Base64テキストをセット
						this.Base64text = event.currentTarget.result.split(',')[1]
					}
					orderdocumentreader.readAsDataURL(orderdocumentFile)
				}else{
					this.Base64text = ""
				}
			},
			sendJson(orderJson){
				let _this = this;
				if(this.postcount_json == 0){
					console.log("Json表示--------------------")
					console.log(orderJson)
					console.log("----------------------------")
				}
				let data = {"orderJson":orderJson}
				const JsonPost_url = this.$store.state.API_HOST + '/send_json'
				this.postcount_json += 1;
				axios.post(
					JsonPost_url,
					data,
					{
						params: {
							'app_name': _this.$store.state.APP_NAME,
							'user_id': _this.$store.state.userID,
						},
						headers: {
							'accept': 'application/json',
							'Content-Type': 'application/json',
						},
						withCredentials: true
					}
				).then(
					function(response) {
						console.log(response)
						if(typeof(response.data) != "string"){
							if(response.data.res.message == "UPLOAD_json OK"){
								this.orderUID_res = response.data.res.orderuid
								this.sendJson_cmp_check = true;
								console.log("Json送信完了")
								// _this.message_json = "Json保存しました。";
							}else{
								this.ordering = false
								this.message = ""
								this.message_json = "Json保存できませんでした。";
							}
						}else{
							if(response.data != "NG"){
								_this.$store.dispatch('logout');
								_this.$store.commit('setLoginUser', '')
							}else{
								this.ordering = false
								this.message = ""
								this.message_json = "サーバーエラーによりJson保存できませんでした。";
							}
						}
					}.bind(this)
				)
				.catch(
					function(error) {
						this.message_json = "Jsonを送信できませんでした。";
						this.result = "POSTエラー";
						console.log(error.response);
						console.log(this.result)
					}.bind(this)
				);
			},
			// 伝票読込
			previewFile(file) {
				// プレビュー画像を追加する要素
				const preview = document.getElementById('preview');

				// FileReaderオブジェクトを作成
				const reader = new FileReader();

				// ファイルが読み込まれたときに実行する
				reader.onload = function (e) {
					const imageUrl = e.target.result; // 画像のURLはevent.target.resultで呼び出せる
					const img = document.createElement("img"); // img要素を作成
					console.log(e)
					img.src = imageUrl; // 画像のURLをimg要素にセット
					img.style.maxWidth = "260px"
					preview.textContent = ""; //一枚のみ有効とする
					preview.appendChild(img); // #previewの中に追加
				}

				// いざファイルを読み込む
				reader.readAsDataURL(file);
				if(document.getElementById("OrderDocument").files[0] !== undefined){
					this.OrderDocument = document.getElementById("OrderDocument").files[0]
					// 伝票をBase64文字列にエンコード
					var uploadImage = document.querySelector('#OrderDocument')
					var orderdocumentFile = uploadImage.files[0]
					var orderdocumentreader = new FileReader()
					orderdocumentreader.onload = (event) => {
						// Base64テキストをセット
						this.Base64text = event.currentTarget.result.split(',')[1]
					}
					orderdocumentreader.readAsDataURL(orderdocumentFile)
				}else{
					this.Base64text = ""
				}
			},
			// 伝票削除
			fileDelete(){
				const doc = document.getElementById('OrderDocument');
				const pre = document.getElementById('preview');
				doc.value = "";
				pre.textContent = "";
			},
		},
		mounted: function(){
			var _this = this;
			// DICOMファイル読み込み処理
			var dropZone = document.getElementById('dropZone');
			dropZone.addEventListener('dragover', this.handleDragOver, false);
			dropZone.addEventListener('drop', this.handleFileSelect, false);

			// 伝票ファイル読み込み処理
			const fileInput = document.getElementById('OrderDocument');
			var dropZone_OD = document.getElementById('dropZone_OD');
			dropZone_OD.addEventListener('dragover', function(e) {
				e.stopPropagation();
				e.preventDefault();
				this.style.background = '#e1e7f0';
			}, false);

			dropZone_OD.addEventListener('dragleave', function(e) {
				e.stopPropagation();
				e.preventDefault();
				this.style.background = '#ffffff';
			}, false);
			const HandleOrderDocumentSelect = () => {
				const files = fileInput.files;
				for (let i = 0; i < files.length; i++) {
					_this.previewFile(files[i]);
				}
				fileInput.addEventListener('change', HandleOrderDocumentSelect);
			}
			HandleOrderDocumentSelect();

			dropZone_OD.addEventListener('drop', function(e) {
				e.stopPropagation();
				e.preventDefault();
				this.style.background = '#ffffff'; //背景色を白に戻す
				var files = e.dataTransfer.files; //ドロップしたファイルを取得
				if (files.length > 1) return alert('アップロードできるファイルは1つだけです。');
				fileInput.files = files; //inputのvalueをドラッグしたファイルに置き換える。
				_this.previewFile(files[0]);
			}, false);
		},
	}
</script>

<style>
	/* @import "../../public/css/stylesheets/stylesheet.css";
	@import "../../public/css/stylesheets/pygment_trac.css";
	@import "../../public/css/stylesheets/ui.jqgrid.css"; */
	table, td, th{
		border: 1px solid black;
	}
	table {
		border-collapse: collapse;
	}
	thead {
		background-color: lightgray;
	}
	tbody {
		cursor: pointer;
		cursor: hand;
	}
	.mouse_hover:hover {
		background-color: rgb(118, 183, 239);
	}
	#message_json,#message_send,#message_send_error, #message_store {
		color:red
	}
	.allstudy {
		padding-top: 5px;
		padding-bottom: 5px;
		margin-right: 10px;
		margin-bottom: 10px;
	}
	.loading {
		/* position: fixed; */
		/* z-index: 1000; */
		/* width: 100%; */
		/* height: 100vh; */
		margin: 0;
		padding: 0;
		/* background: #333333; */
	}
	.loading.hide {
		opacity: 0;
		pointer-events: none;
		transition: opacity 500ms;
	}
	.loading .circle {
		display: block;
		position: relative;
		top: calc( 50% - 20px );
		width: 25px;
		height: 25px;
		margin: 0 auto;
		border: 7px solid #444444;
		border-top: 7px solid #5ae1e5;
		border-radius: 50px;
		animation: loading 700ms linear 0ms infinite normal both;
	}
	@keyframes loading {
		0% { transform: rotate( 0deg ); }
		100% { transform: rotate( 360deg ); }
	}
	.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
	.border {
		padding: 10px;
		margin: 20px;
	}
	.border-solid {
		border: 2px solid #090201;
	}
	#orderGroupInputZone > label, #orderInputZone > label {
		margin-left: 25px;
	}


	details {
		border: 1px solid #ccc;
	}
	details:not(:last-child) {
		margin-bottom: 20px;
	}

	/**
	* list-style: none; ←デフォルト三角削除（Chrome非対応）
	* cursor: pointer; ←カーソルをポインターに
	**/
	details summary {
		list-style: none;
		cursor: pointer;
		padding: 20px;
		background: #e4e4e4;
		color: #242323;
		font-size: 1rem;
		font-weight: bold;
		/* width: 50%; */
	}
	/**
	* Chrome用のデフォルト三角削除
	**/
	/* details summary::-webkit-details-marker {
		display: none;
	} */
	/**
	* Font Awesomeのプラスアイコン使用
	**/
	/* details summary::before {
		font-family: FontAwesome;
		content: '\f067';
		margin-right: 20px;
	} */
	/**
	* アコーディオンがオープン時はマイナスアイコンに変更
	**/
	/* details[open] summary::before {
		content: '\f068';
	} */

	details span {
		margin: 0;
		padding: 20px;
	}
	#InputZone textarea{
		vertical-align:top;
	}

	.list-table {
		overflow: auto;
	}
	.list-table #studyTable, .list-table #seriesTable {
		width: max-content;
	}
	.hide {
		display: none !important;
	}
	.imagelist{
		display: flex;
	}
	.show {
		opacity: 1;
	}
	.showhide {
		opacity: 0;
	}
	.show_onetime {
		display: block !important;
	}
	#overlay {
        position: absolute;
        /* display: none; */
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.8);
        z-index: 5;
    }

    .popup_progress {
        position: absolute;
        width: 30%;
        height: 30%;
        top: 25%;
        left: 25%;
        text-align: center;
        background: white;
	}
	.popup_progress h3 {
        font-size: 15px;
        height: 50px;
        line-height: 50px;
        background: white;
	}
	#dialog {
		width: 500px;
		padding: 30px 20px;
		text-align: center;
		border: 1px solid #aaa;
		box-shadow: 2px 2px 4px #888;
		background-color: white;
		}
</style>