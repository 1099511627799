export default {
	data() {
		return {
			accesstoken_chat:""
		}
	},
  methods: {
		// ユーザー存在チェック
		async existCheckUser_chat(username){
			const params = { username: username };
			const qs = new URLSearchParams(params);
			let res = ""
			// yogumaユーザーで新規ユーザー作成
			await fetch(`https://rc.esfill.co.jp/api/v1/users.info?${qs}`, {
				mode: 'cors',
				method: 'GET',
				headers: {
					'X-Auth-Token': 'vVb9tjhzgYo8smY4DwNpOnqhOH_ECNNvXhwHT_0f9o0',
					'X-User-Id': 'N7ZBLTy4gvSFuyFNw',
					'Content-Type': 'application/json'
				}
			}).then((response) => {
					if(!response.ok) {
							console.log('error!');
					} 
					console.log('ok!');
					return response.json();
			}).then((data)  => {
					console.log(data);
					console.log("ユーザー存在チェック完了")
					res = data.success
			}).catch((error) => {
					console.log(error);
			});
			return res
		},


	// EsForumログイン成功時にロケットチャットにバックグラウンドでログインしておく
    async Login_chat_bg(loginname) {
		let uname = loginname;
		// let pword = "t" + this.loginname;
		// 1620825 → 固定値（自社郵便番号）
		let pword = loginname + "1620825";

		console.log ( "name= " + uname + "   password= " + pword );
		if(uname != ""){
			await fetch('https://rc.esfill.co.jp/api/v1/login', {
				method: 'POST',
				body: new URLSearchParams({
					'user': uname,
					'password': pword
				})
			}).then((response) => {
				if(!response.ok) {
					console.log('error!');
				}
				console.log('ok!');
				return response.json();
			}).then((data)  => {
				console.log(data);
				console.log('currrent chatUserID= ' + data.data.userId);
				console.log('currrent chatAuthtoken= ' + data.data.authToken);
				localStorage.setItem('ChatToken',data.data.authToken);
				localStorage.setItem('ChatUserID',data.data.userId);
			}).catch((error) => {
				console.log(error);
			});
		}
    },
	async startChat() {
		this.accesstoken_chat = localStorage.getItem('ChatToken')
		console.log(this.accesstoken_chat)
		// let userChannel = '%25E5%25B1%25B1%25E7%2594%25B0%25E3%2582%25AF%25E3%2583%25AA%25E3%2583%258B%25E3%2583%2583%25E3%2582%25AF';  // UTF8 の 山田クリニック のURLエンコード ← これでも動く
		// let userChannel = '%E5%B1%B1%E7%94%B0%E3%82%AF%E3%83%AA%E3%83%8B%E3%83%83%E3%82%AF'; //山田クリニック のURLエンコード vueでは使用不可
		// let userNews = '%E5%B1%B1%E7%94%B0%E3%82%AF%E3%83%AA%E3%83%8B%E3%83%83%E3%82%AF%E3%81%8A%E7%9F%A5%E3%82%89%E3%81%9B';  //山田クリニックお知らせ のURLエンコード
		await document.querySelector('#chatwindow').contentWindow.postMessage({
			externalCommand: 'login-with-token',
			token: this.accesstoken_chat
		}, 'https://rc.esfill.co.jp')
		console.log("チャットスタート済")
	},
	async gotoChannel(userChannel) {
		await document.querySelector('#chatwindow').contentWindow.postMessage({
		externalCommand: 'go',
		path: '/channel/' + userChannel + '?layout=embedded'
		}, 'https://rc.esfill.co.jp')
		console.log(userChannel + "チャンネル移動済")
	},

	// async get_ChannelInfo(username){
	// 	const params = { roomName: username };
	// 		const qs = new URLSearchParams(params);
	// 		let roomId = ""
	// 		// yogumaユーザーで新規ユーザー作成
	// 		await fetch(`https://rc.esfill.co.jp/api/v1/channels.info?${qs}`, {
	// 			mode: 'cors',
	// 			method: 'GET',
	// 			headers: {
	// 				'X-Auth-Token': localStorage.getItem('ChatToken'),
	// 				'X-User-Id': localStorage.getItem('ChatUserID'),
	// 				'Content-Type': 'application/json'
	// 			}
	// 		}).then((response) => {
	// 				if(!response.ok) {
	// 						console.log('error!');
	// 				} 
	// 				console.log('ok!');
	// 				return response.json();
	// 		}).then((data)  => {
	// 				console.log(data);
	// 				console.log("チャンネル存在チェック完了")
	// 				roomId = data['channel']['_id']
	// 		}).catch((error) => {
	// 				console.log(error);
	// 		});
	// 		return roomId
	// },
	// ロケットチャットログアウト
	async logout_chat() {
		// 一度チャットコンポーネントに遷移してからログアウト。コンポーネントにアクセスした際にstartChatが実行されないように制御
		// vuerouterのバージョンアップに伴い、router.pushではpropsには渡せなくなったので要注意
		// await this.$router.push({name:"chat",query:{status:"NoStart"}})
		// await this.$router.push('/chat')
		if(document.querySelector('#chatwindow')){
			await document.querySelector('#chatwindow').contentWindow.postMessage({
				externalCommand: 'logout'
			}, '*')
			console.log("チャットログアウト成功")
		}
	},

	// EsForumユーザー新規登録時に同時にロケットチャットのユーザーとチャンネルを新規作成
	async createUser_chat(email,dispname,password,username){
		let data = {
			'email': email,
			'name': dispname,          // 表示名
			'password':password,
			'username':username,      //ユーザー名
			'roles':['guest'],
			'verified':true
		}
		// yogumaユーザーで新規ユーザー作成
		await fetch('https://rc.esfill.co.jp/api/v1/users.create', {
			mode: 'cors',
			method: 'POST',
			headers: {
				'X-Auth-Token': 'vVb9tjhzgYo8smY4DwNpOnqhOH_ECNNvXhwHT_0f9o0',
				'X-User-Id': 'N7ZBLTy4gvSFuyFNw',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data) // JSON形式のデータ
		}).then((response) => {
				if(!response.ok) {
						console.log('error!');
				} 
				console.log('ok!');
				return response.json();
		}).then((data)  => {
				console.log(data);
				console.log("ロケットチャットユーザー登録完了")
		}).catch((error) => {
				console.log(error);
		});
	},

	// EsForumユーザー新規登録時に同時にロケットチャットのユーザーとチャンネルを新規作成
	async createChannel_chat(username){
		let data = {
			'name': username,          // チャンネル名
			'members': [username,'yoguma','esfill_manage','ysasaki']
		}
		// let roomId = ""
		await fetch('https://rc.esfill.co.jp/api/v1/channels.create', {
			mode: 'cors',
			method: 'POST',
			headers: {
				'X-Auth-Token': 'vVb9tjhzgYo8smY4DwNpOnqhOH_ECNNvXhwHT_0f9o0',
				'X-User-Id': 'N7ZBLTy4gvSFuyFNw',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(data) // JSON形式のデータ
		}).then((response) => {
				if(!response.ok) {
						console.log('error!');
				} 
				console.log('ok!');
				return response.json();
		}).then((data)  => {
				console.log(data);
				console.log("ロケットチャットチャンネル登録完了")
				// roomId = data['channel']['_id']
		}).catch((error) => {
				console.log(error);
		});
		// return roomId
	}
}};