<template>
    <div class="container">
        <h2><font-awesome-icon icon="fa-id-card" />&nbsp;{{ pageTitle }}</h2>
        <div class="row gy-3 mb-3">
            <button id = "userInfo" class="btn btn-sm btn-info" @click="showUserInfo()" style="width: 200px;height 50px;">クライアント情報表示</button>
            <div id = "messagePreview">
                <div v-for="(item,index) in messageList" v-bind:key="index">
                    {{ item }}
                </div>
            </div>
        </div>
        <div>
            <button class="btn btn-sm btn-info" @click="getFingerPrint()" style="width: 200px;height 50px;">フィンガープリント表示</button>
            <p>ブラウザのフィンガープリントテスト: {{ fingerprint }}</p>
            <ul>
                <li v-for="(component, index) in fingerprintComponents" :key="index">
                    {{ component.key }}: {{ component.value }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Fingerprint2 from 'fingerprintjs2';

export default {
    data() {
        return {
            btnName: 'Button',
            pageTitle: '管理者画面',
            postList: [],
            messageList: [],
            fingerprint: null,
            fingerprintComponents: [],
        }
    },
    // mounted: function() {
    //     this.msgs.reverse()
    // },
    methods: {
        getFingerPrint(){
            // Fingerprint2オブジェクトを生成
            const options = { excludes: { userAgent: true } }; // ユーザーエージェント情報を含めない場合
            Fingerprint2.get(options, (components) => {
                // フィンガープリントを生成
                const values = components.map((component) => component.value);
                const fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
                this.fingerprint = fingerprint;
            });
            // Fingerprint2オブジェクトを生成
            Fingerprint2.get((components) => {
                this.fingerprintComponents = components;
            });

            const userAgent = navigator.userAgent;

            if (userAgent.includes('Chrome')) {
            // Chromeブラウザ
            console.log('これはChromeブラウザです。');
            } else if (userAgent.includes('Firefox')) {
            // Firefoxブラウザ
            console.log('これはFirefoxブラウザです。');
            } else if (userAgent.includes('Safari')) {
            // Safariブラウザ
            console.log('これはSafariブラウザです。');
            } else if (userAgent.includes('Edge')) {
            // Edgeブラウザ (Chromiumベース)
            console.log('これはEdgeブラウザです。');
            } else if (userAgent.includes('MSIE') || userAgent.includes('Trident')) {
            // Internet Explorer (IE) ブラウザ
            console.log('これはInternet Explorer (IE) ブラウザです。');
            } else {
            // 他のブラウザ
            console.log('これは他のブラウザです。');
            }
        },
        async showUserInfo() {
            await this.settingData();
            console.log(this.postList)

            var _this = this
            var obj = {"data":this.postList}
            const post_url = this.$store.state.API_HOST + '/manage'
            axios.post(post_url, obj)
            .then(
                function(response) {
                    console.log(response["data"])
                    for(let i=0;i < response["data"].length; i++){
                        _this.messageList.push(response["data"][i])
                    }
                }.bind(this)
            )
            .catch(
                function(error) {
                    console.log(error.response);
                }.bind(this)
            );
        },
        async settingData() {
            this.postList.length = 0;
            this.messageList.length = 0;
            
            // URL
            this.postList.push("URL：" + location.href)

            // ホスト名
            this.postList.push("ホスト名：" + location.hostname)

            // IPアドレス
            const API_URL = 'https://api.ipify.org/?format=json'
            const res = await fetch(API_URL)
            const data = await res.json()
            this.postList.push("IPアドレス：" + data.ip)
            // fetch('https://ipinfo.io?callback')
            // .then(res => res.json())
            // .then(json => console.log("IPアドレス：" + json.ip))

            // ポート番号
            this.postList.push("ポート番号：" + location.port)

            // プロトコル
            this.postList.push("プロトコル：" + location.protocol)

            // 使用ブラウザ
            var userAgent = window.navigator.userAgent.toLowerCase();
            var browser = ""
            if(userAgent.indexOf('msie') != -1 || userAgent.indexOf('trident') != -1) {
                browser = 'Internet Explorer';
            } else if(userAgent.indexOf('edge') != -1) {
                browser = 'Edge';
            } else if(userAgent.indexOf('chrome') != -1) {
                browser = 'Google Chrome';
            } else if(userAgent.indexOf('safari') != -1) {
                browser = 'Safari';
            } else if(userAgent.indexOf('firefox') != -1) {
                browser = 'FireFox';
            } else if(userAgent.indexOf('opera') != -1) {
                browser = 'Opera';
            } else {
                browser = '不明';
            }
            this.postList.push("使用ブラウザ：" + browser)

            // ブラウザ名
            this.postList.push("ブラウザ名：" + navigator.appName)

            // ブラウザ使用言語
            this.postList.push("ブラウザ使用言語：" + navigator.language)

            // ブラウザプラットフォーム
            this.postList.push("ブラウザプラットフォーム：" + navigator.platform)

            // ブラウザバージョン
            this.postList.push("ブラウザバージョン：" + navigator.appVersion)

            // ブラウザユーザーエージェント
            this.postList.push("ブラウザユーザーエージェント：" + navigator.userAgent)

            // リファラー
            this.postList.push("リファラー：" + document.referrer)

            // ドメイン名
            this.postList.push("ドメイン名：" + document.domain)
            

            // 使用OS
            var ua = window.navigator.userAgent.toLowerCase();
            var osName = ""
            if(ua.indexOf("windows nt") !== -1) {
                osName = "Microsoft Windows"
            } else if(ua.indexOf("android") !== -1) {
                osName = "Android"
            } else if(ua.indexOf("iphone") !== -1 || ua.indexOf("ipad") !== -1) {
                osName = "iOS"
            } else if(ua.indexOf("mac os x") !== -1) {
                osName = "macOS"
            } else if(ua.indexOf("linux") !== -1) {
                osName = "Linux"
            } else {
                osName = "不明"
            }
            this.postList.push("使用OS：" + osName)

            // 位置情報
            await this.getLocation();

            // 画面横幅
            this.postList.push("画面横幅：" + screen.width)

            // 画面高さ
            this.postList.push("画面高さ：" + screen.height)

            // 画面色深度
            this.postList.push("画面色深度：" + screen.colorDepth)

            // ブラウザビューポート幅
            this.postList.push("ブラウザビューポート幅：" + window.innerWidth)

            // ブラウザビューポート高さ
            this.postList.push("ブラウザビューポート高さ：" + window.innerHeight)

            // デバイスピクセル比
            this.postList.push("デバイスピクセル比：" + window.devicePixelRatio)

            return;
        },
        getLocation () {
            console.log("位置情報取得")
            return new Promise((resolve, reject) => {
                // 現在地を取得
                navigator.geolocation.getCurrentPosition(
                    // 取得成功した場合
                    (position) => {
                        console.log(position.coords)
                        this.postList.push("緯度：" + position.coords.latitude);
                        this.postList.push("経度：" + position.coords.longitude);
                        this.postList.push("高度：" + position.coords.altitude);
                        this.postList.push("緯度・経度の誤差：" + position.coords.accuracy);
                        this.postList.push("高度の誤差：" + position.coords.altitudeAccuracy);
                        this.postList.push("方角（0が北、90が東、180が南、270が西）：" + position.coords.heading);
                        this.postList.push("速度（位置情報を追跡する場合に使用）：" + position.coords.speed);
                        // console.log(result); // ここは正しく表示
                        resolve(position.coords);
                    },
                    // 取得失敗した場合
                    (error) => {
                        switch (error.code) {
                            case 1: //PERMISSION_DENIED
                            this.postList.push("位置情報：位置情報の利用が許可されていません");
                            // alert("位置情報の利用が許可されていません");
                            break;
                            case 2: //POSITION_UNAVAILABLE
                            this.postList.push("位置情報：現在位置が取得できませんでした");
                            // alert("現在位置が取得できませんでした");
                            break;
                            case 3: //TIMEOUT
                            this.postList.push("位置情報：タイムアウトになりました");
                            // alert("タイムアウトになりました");
                            break;
                            default:
                            this.postList.push("位置情報：その他のエラー(エラーコード:" + error.code + ")");
                            // alert("その他のエラー(エラーコード:" + error.code + ")");
                            break;
                        }
                        reject(error.code);
                    }
                );
            }).catch(function(error) {

                console.log(error.code);

            }).then(() => console.log("Next successful handler runs"));
        },
    },
}
</script>