import { createRouter, createWebHistory } from 'vue-router';
// import Comments from './views/Comments.vue';
import LoginScreen from './views/LoginScreen.vue';
import RegisterScreen from './views/RegisterScreen.vue';
import DicomParser from './views/DicomParser.vue';
import DicomParserLocal from './views/DicomParserLocal.vue';
import DicomParserPacs from './views/DicomParserPacs.vue';
import JpegParser from './views/JpegParser.vue';
import ProfileEdit from './views/ProfileEdit.vue';
import OrderList from './views/OrderList.vue';
import HomeScreen from './views/HomeScreen.vue';
import TopScreen from './views/TopScreen.vue';
import store from './store';
import ManageScreen from './views/ManageScreen.vue';
import VerifyScreen from './views/VerifyScreen.vue';
// import ChatCommunication from './views/ChatCommunication';
import RocketChatWindow from './views/RocketChatWindow';



const routes = [
   {
    path:'/',
    component:TopScreen,
    beforeEnter(){
      if(store.getters.userID != 0){
        store.state.userID = 0
        localStorage.removeItem("useID")
        store.dispatch('logout');
        store.commit('setLoginUser', '')
      }
    }
   },
   {
    path:'/login',
    component:LoginScreen,
    beforeEnter(to, from, next){
     console.log(store.getters.userID)
     if (store.getters.userID != 0) {
      next('/');
     } else {
      next();
     }
    // next();
    }
   },
   {
    path:'/register',
    component:RegisterScreen,
    beforeEnter(to, from, next){
     if (store.getters.userID != 0) {
      next('/');
     } else {
      next();
     }
    }
   }, 
   {
    path:'/profileedit',
    component:ProfileEdit,
    beforeEnter(to, from, next){
      if (store.getters.userID != 0) {
        next();
      } else {
        if(localStorage.getItem("userID")){
          store.state.userID = localStorage.getItem("userID")
          next();
        }else{
          next('/');
        }
      }
    }
   },
   {
    path:'/dicomparser',
    component:DicomParser,
    name:'DicomParser',
    beforeEnter(to, from, next){
      if (store.getters.userID != 0) {
        next();
      } else {
        if(localStorage.getItem("userID")){
          store.state.userID = localStorage.getItem("userID")
          next();
        }else{
          next('/');
        }
      }
    }
   },
   {
    path:'/dicomparserlocal',
    component:DicomParserLocal,
    name:'DicomParserLocal',
    beforeEnter(to, from, next){
      if (store.getters.userID != 0) {
        next();
      } else {
        if(localStorage.getItem("userID")){
          store.state.userID = localStorage.getItem("userID")
          next();
        }else{
          next('/');
        }
      }
    }
   },
   {
    path:'/dicomparserpacs',
    component:DicomParserPacs,
    name:'DicomParserPacs',
    beforeEnter(to, from, next){
      if (store.getters.userID != 0) {
        next();
      } else {
        if(localStorage.getItem("userID")){
          store.state.userID = localStorage.getItem("userID")
          next();
        }else{
          next('/');
        }
      }
    }
   },
   {
   path:'/Jpegparser',
   component:JpegParser,
   name:'JpegParser',
   beforeEnter(to, from, next){
    if (store.getters.userID != 0) {
      next();
    } else {
      if(localStorage.getItem("userID")){
        store.state.userID = localStorage.getItem("userID")
        next();
      }else{
        next('/');
      }
    }
   }
  },
   {
    path:'/orderlist',
    component:OrderList,
    beforeEnter(to, from, next){
      if (store.getters.userID != 0) {
        next();
      } else {
        if(localStorage.getItem("userID")){
          store.state.userID = localStorage.getItem("userID")
          next();
        }else{
          next('/');
        }
      }
    }
   },
   {
    path:'/home',
    component:HomeScreen,
    beforeEnter(to, from, next){
      if (store.getters.userID != 0) {
        next();
      } else {
        if(localStorage.getItem("userID")){
          store.state.userID = localStorage.getItem("userID")
          next();
        }else{
          next('/');
        }
      }
    }
   },
   {
   path:'/manage',
    component:ManageScreen,
    beforeEnter(to, from, next){
      if (store.getters.userID != 0) {
        next();
      } else {
        if(localStorage.getItem("userID")){
          store.state.userID = localStorage.getItem("userID")
          next();
        }else{
          next('/');
        }
      }
    }
   },
   {
    path:'/verify/:token',
    component:VerifyScreen,
    beforeEnter(to, from, next){
     if (store.getters.userID != 0) {
      next('/');
     } else {
      next();
     }
    },
   }, 
   {
    path:'/chat',
    component:RocketChatWindow,
    name:"chat",
    // meta: { keepAlive: true }
    beforeEnter(to, from, next){
      if (store.getters.userID != 0) {
        next();
      } else {
        if(localStorage.getItem("userID")){
          store.state.userID = localStorage.getItem("userID")
          next();
        }else{
          next('/');
        }
      }
    }
   },
];

const router = createRouter({
  history: createWebHistory(),
  routes
})



export default router