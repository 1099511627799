import * as dicomParser from "../public/index_files/dicomParser";
import * as cornerstone from "../public/js/cornerstone";
import * as cornerstoneWADOImageLoader from "../public/js/cornerstoneWADOImageLoader.min";
import * as cornerstoneMath from "../public/js/cornerstoneMath.min";
import * as cornerstoneTools from "../public/js/cornerstoneTools.min";
import * as Hammer from "../public/js/hammer.js";


cornerstoneWADOImageLoader.external.cornerstone = cornerstone
cornerstoneWADOImageLoader.external.dicomParser = dicomParser
cornerstoneTools.external.cornerstone = cornerstone
cornerstoneTools.external.cornerstoneMath = cornerstoneMath
cornerstoneTools.external.Hammer = Hammer


async function readDicom(file,filenum){
    // 画像を描画
    const dom = document.getElementById('dicom-preview' + filenum)
    const url = cornerstoneWADOImageLoader.wadouri.fileManager.add(file)
    cornerstone.enable(dom)
    cornerstone.loadImage(url).then(function(image) {
        cornerstone.displayImage(dom, image)

        cornerstoneTools.mouseInput.enable(dom)
        // cornerstoneTools.mouseWheelInput.enable(dom)
        cornerstoneTools.wwwc.activate(dom, 2)
        cornerstoneTools.zoom.activate(dom, 1)
        cornerstoneTools.pan.activate(dom, 4)
        // cornerstoneTools.zoomWheel.activate(dom)
    })
    return;
}

function dicomparser(byteArray){
    return  dicomParser.parseDicom(byteArray);
}

function sharedCopyFile(dataSet,dicomFileAsBuffer){
    var patientNameElement = dataSet.elements.x00100010;
    var offset = patientNameElement.dataOffset;
    var length = patientNameElement.length;
    return  dicomParser.sharedCopy(dicomFileAsBuffer, offset, length);
}

export default {
    readDicom,
    dicomparser,
    sharedCopyFile,
};