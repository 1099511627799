<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 w-100 text-center">
                <p>遠隔画像診断コミュニケーションプラットフォーム</p>
                <h2>EsForum 〜エスフォーラム〜</h2>
            </div>
        </div>
        <div class="row flex-column justify-content-center">
            <div class="col-md-12 col-lg-10 col-xl-8 mx-auto">
                <h3 class="mb-2 mx-auto main-visual">
                    <img src="../assets/images/EsL_main_visual.png" alt="Welcome to EsForum" class="w-100 mx-auto h-auto mb-3">
                </h3>
            </div>
            <div class="col-md-12 col-lg-10 col-xl-8 mx-auto">
                <ul>
                    <li>お試し遠隔画像診断サービスをカンタン、スピーディに利用開始！</li>
                    <li>ブラウザ内で申込みから読影レポートの入手まで完結</li>
                </ul>
            </div>
        </div>
        <div class="row justify-content-center about mb-3">
            <div class="col-md-12 col-lg-10 col-xl-8">
                <h3 class="about">EsForumとは</h3>
                <div>
                    <ul>
                        <li>ESFILL遠隔画像診断サービスの導入をご検討いただくにあたって、完全無料でご利用いただける「お試し遠隔画像診断サービス」をご活用いただくことができます。</li>
                        <li>一般的に遠隔画像診断を行うためには、（たとえそれがテスト的なものであっても）万全なセキュリティが保証された環境下で行わなければなりません。</li>
                        <li>そのため、無料であるとは言っても、これまでは設置調整などに少なくない工数をかける必要がありました。</li>
                        <li>EsForumはこうした問題を効率的に処理するために独自に開発・ご提供するクラウドサービスです。</li>
                        <li>普段お使いになっているインターネットブラウザでサービスにアクセスし、アカウントの作成から、医用画像のローカル環境での匿名化、アップロード、読影レポートの受信まで、一貫して行うことが可能です。</li>
                        <li>もちろん無料で全ての機能をご利用いただくことができます。</li>
                        <li>弊社の画像診断サービスのサービス品質を評価していただき、ご納得いただいた上でサービスのお申し込みをしていただくことができます。</li>
                        <li>ぜひ皆様の遠隔画像診断活用の一助としてご活用ください。</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row justify-content-center feature">
            <div class="col-md-12 col-lg-10 col-xl-8">
                <h3 class="feature">EsForumの特長</h3>
                <div>
                    <ul>
                        <li>普段ご利用されているインターネットブラウザで申込みは数秒で完了。</li>
                        <li>インターネットブラウザからクラウドサーバーへアップロード。</li>
                        <li>AI診断結果は数秒で結果表示。</li>
                        <li>ローカル環境で匿名化処理を施してからアップロードするので情報漏えいの心配がありません。</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row justify-content-center process">
            <div class="col col-lg-10 col-xl-8">
                <h3 class="process">ご利用の流れ</h3>
                <div>
                    <img src="../assets/images/EsL_flow.png" alt="EsForumご利用の流れ" class="w-100">
                </div>
                <ol>
                    <li>アカウントを作成します。</li>
                    <li>
                        お試し遠隔画像診断をしてもらいたい画像をEsForumを用いて匿名化し、アップロードします。
                                        （アップロード可能な画像の枚数には制限はありません。）
                                        （アップロードしただけではお試し遠隔画像診断の利用回数は増えません。）
                    </li>
                    <li>アップロードした画像の中からお試し遠隔画像診断を適用したいものを選んで申込みをします。</li>
                    <li>AI診断支援の対象領域の画像は数秒で診断結果が返ってきます。</li>
                    <li>
                        2〜3営業日以内に読影医の診断レポートが届きます。
                                        （作成したアカウントのダッシュボードで確認します。）
                    </li>
                    <li>お試し遠隔画像診断などの評価を経て、当社営業窓口にご不明な点などのお問合せ、または本サービスへのお申込。</li>
                </ol>
            </div>
        </div>
        <div class="row justify-content-center pricing">
            <div class="col col-lg-10 col-xl-8">
                <h3 class="pricing">利用料金</h3>
                <ul>
                    <li>お試し遠隔画像診断のご利用には一切費用はかかりません。</li>
                    <li>お申込後お見積させていただきます。</li>
                    <li>課金が発生するのは、サービス利用契約締結後からになります。</li>
                </ul>
            </div>
        </div>
        <div class="row justify-content-center contact">
            <div class="col col-lg-10 col-xl-8">
                <h3 class="contact">お問合せ</h3>
                <ul>
                    <li>お問合せメールアドレス：<a href="mailto:info@esfill.co.jp">info@esfill.co.jp</a></li>
                    <li>お問合せ電話番号：<a href="tel:+81-3-6386-5767">03-6386-5767</a></li>
                </ul>
            </div>
        </div>
    </div>
</template>


<style scoped>
header h1 img {
	width: 100%;
}
header h2 {
    font-size: 15px;
}

.main-visual {
	padding-left: 0;
}

#filepattern {
	width: 100%;
}

#dropZone {
	border: dashed 2px rgba(128, 128, 128, 0.4);
	border-radius: 8px;
}
#dropZone table tr td {
    width: 33.33%;
}
#dropZone table tr td input {
    height: 2em;
}
#download + div.spinner-border {
    display: none;
    vertical-align: middle;
    width: 1.5rem;
    height: 1.5rem;
}

#dicon-preview-wrapper {
	overflow: hidden;
}

.hide-preview {
	height: 0px;
}

.show-preview {
	height: auto;
}

footer {
	background-color: #099cdd;
	font-weight: bold;
	color: #ffffff;
}
footer a {
    font-weight: bold;
    color: #ffffff;
}

.footer-menu {
	list-style: none;
}
.footer-menu li::after {
    content: " / ";
    display: inline-block;
    margin: 0 .5em;
}
.footer-menu li:last-child::after {
    content: "";
}

h3 {
	padding-left: 1.75em;
	position: relative;
}
h3::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: -0.25em;
    width: 1.5em;
    height: 1.5em;
}
h3.about::before {
    background: url('../assets/images/icons/info.png') no-repeat;
    background-size: contain;
}
h3.feature::before {
    background: url('../assets/images/icons/checkboxes.png') no-repeat;
    background-size: contain;
}
h3.process::before {
    background: url('../assets/images/icons/procedure.png') no-repeat;
    background-size: contain;
}
h3.pricing::before {
    background: url('../assets/images/icons/price-tag.png') no-repeat;
    background-size: contain;
}
h3.contact::before {
    background: url('../assets/images/icons/email.png') no-repeat;
    background-size: contain;
}

div.row.about {
	background: url(../assets/images/main-background.jpg) no-repeat;
	background-size: cover;
	background-position: center;
	padding: 1.5rem 0;
}
div.row.about > div {
    background-color: rgba(255,255,255,0.5);
    border-radius: 2rem;
    box-shadow: 0 0 1.5em rgb(255 255 255);
}
</style>