<template>
	<div class="container">
    <div>
      <h2>
        <font-awesome-icon icon="fa-comment" />&nbsp;{{ title }}
        <span  data-bs-toggle="tooltip" data-bs-placement="top" title="再読み込み" style="float: right;margin-right: 50px;cursor: pointer;">
          <font-awesome-icon icon="fa-redo-alt" @click="reloadPage" />
        </span>
      </h2>
    </div>
    <div id="chatFrame" style="width:98%;height:95%;">
      <!-- <template v-show="chatLoggedIn"> -->
      <iframe
          width="100%" height="100%"
          id="chatwindow"
          src="https://rc.esfill.co.jp/group/team11?layout=embedded"
          v-show="chatLoggedIn"
          @load="chatlogin()"
      ></iframe>
      <div v-show="!chatLoggedIn">読み込み中・・・</div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
// import crypto from 'crypto-js';
// import axios from "axios";
// import {API_IP, API_PORT} from "../../public/const.json"
// import ce from "./checkExpired"
// import crypto from 'crypto-js';
import ChatManage from './mixins/ChatManage'
// import VueFriendlyIframe from 'vue-friendly-iframe';
// import RocketChatWindow from './RocketChatWindow'

export default {
  name: "ChatCommunication",
  mixins: [ChatManage],
  // components:{
  //   VueFriendlyIframe
  // },
  props: {
    hideflg: Boolean,
  },
  // components:{RocketChatWindow},
  data(){
    return{
      title: "チャット",
      name:"",
      chatLoggedIn:false,
      startChatFlg:false,
      firstFlg:true,   //初回でチャットコンポーネントを開く場合
      startDateTime:new Date(),
    }
  },
  methods:{
    reloadPage() {
      // thisを使ってwindow.location.reload()を呼び出す
      window.location.reload();
    },
    async chatlogin(){
      this.iframestatus = "iframeロード完了"
      let _this = this
      setTimeout(async function() {
          // await _this.Login_chat_bg(_this.$store.state.loginUser)
          await _this.startChat();
          setTimeout(async function() {
            await _this.gotoChannel(_this.$store.state.loginUser);
            _this.chatLoggedIn = true
          }, 1000);
        }, 1000);
    }
  },
  // watch:{
  //   hideflg:async function(newVal,oldVal){
  //     console.log(newVal, oldVal)
  //     var _this = this
  //     //  1秒間隔で、executionメソッドを実行
  //     let intervalId = setInterval(async function(){
  //       let exeDateTime = new Date();
  //       let difference = (exeDateTime.getTime() - _this.startDateTime.getTime()) / 1000
  //       // DOM読み込み後三秒経過かつ初回の場合
  //       if(difference >= 4 && !_this.chatLoggedIn){
  //         await _this.startChat()
  //         _this.chatLoggedIn = true
  //       }
  //     }, 100)
  //       //3秒経過したあとに停止
  //       setTimeout(() => clearInterval(intervalId), 4000)
  //     // // チャットコンポーネントが開かれた時かつそれが二回目以降の場合
  //     // if(!newVal && _this.chatLoggedIn){
  //     //   _this.chatLoggedIn = false
  //     //   await _this.startChat();
  //     //   setTimeout(function() {
  //     //     _this.chatLoggedIn = true
  //     //   }, 500);
  //     //   // _this.chatLoggedIn = true
  //     // }else{
  //     //   //1秒間隔で、executionメソッドを実行
  //     //   let intervalId = setInterval(async function(){
  //     //     let exeDateTime = new Date();
  //     //     let difference = (exeDateTime.getTime() - _this.startDateTime.getTime()) / 1000
  //     //     // DOM読み込み後三秒経過かつ初回の場合
  //     //     if(difference >= 4 && !_this.chatLoggedIn){
  //     //       await _this.startChat()
  //     //       _this.chatLoggedIn = true
  //     //     }
  //     //   }, 100)
  //     //   //3秒経過したあとに停止
  //     //   setTimeout(() => clearInterval(intervalId), 4000)
  //     // }
  //   }
  // },
  mounted: function(){
    this.startDateTime = new Date();
  },
}
</script>

<style>
  #chatFrame {
    padding: 0.5em 1em;
    /* margin: 2em 0; */
    font-weight: bold;
    color: #6091d3;/*文字色*/
    background: #FFF;
    border: solid 3px #6091d3;/*線*/
    border-radius: 10px;/*角の丸み*/
    resize: vertical;
  }
</style>