<template>
<div class="status-indicator">
	<font-awesome-icon icon="user-cog" />
	<div class="login-user" v-if="loggedin">ログイン中：{{ loginUserName }}</div>
	<ul>
		<template v-for="(item, index) of items">
			<li :key="index" v-if="item.show">
				<router-link :to=" item.href ">{{ item.name }}</router-link>
			</li>
		</template>
        <li v-if="loggedin">
            <a href="#" @click.prevent="logout">{{ btnNameLogout }}</a>
        </li>
	</ul>
</div>
</template>

<script>
import ChatManage from '../views/mixins/ChatManage'

export default {
    mixins: [ChatManage],
    props: {
        login: Boolean,
    },
    data() {
        return {
            loginUserName: '',
            loggedin: false,
            btnNameLogout: 'ログアウト',
            isReload:false,
        }
    },
    computed: {
        items: function() {
            return [
                {
                    name: 'ログイン',
                    show: !this.loggedin,
                    href: '/login',
                },
                {
                    name: '新規登録',
                    show: !this.loggedin,
                    href: '/register',
               },
                {
                    name: 'プロフィール編集',
                    show: this.loggedin,
                    href: '/profileedit',
               },
           ]
        },
    },
    methods: {
        async logout(){
            // await this.logout_chat();
            this.$store.dispatch('logout','ManualLogout');
            this.$store.commit('setLoginUser', '')
        },
      },
    watch: {
        login: function( newData ) {
            console.log('login property changed as:'+newData)
            this.loggedin = newData
            setTimeout( ()=>{
                this.loginUserName = this.$store.state.loginUser
            }, 1100)
        },
        // loggedin:function(newdata){
        //     if(newdata){
        //         this.loginUserName = this.$store.state.loginUser
        //     }
        // }
    },
    // mounted: function(){
    //     let _this = this
    //     window.addEventListener('load', () => {
    //         const perfEntries = performance.getEntriesByType("navigation")
    //         _this.isReload = perfEntries[0].type === 'reload'
    //         if(_this.isReload){
    //             _this.$store.state.loginUser = localStorage.getItem("userID")
    //         }
    //     })
    // },
    // mounted: function () {
    //     window.removeEventListener('beforeunload', (event) => {
    //         // Cancel the event as stated by the standard.
    //         event.preventDefault();
    //         // Chrome requires returnValue to be set.
    //         event.returnValue = '';
    //     });
    // },
}
</script>

<style scoped>
.status-indicator {
    display: flex;
    align-items: center;
}
.status-indicator .login-user {
    font-size: 0.75rem;
}
.status-indicator ul {
    display: flex;
    margin: 0;
    padding-left: 1rem;
}
.status-indicator ul li {
    list-style: none;
    font-size: 0.75rem;
}
.status-indicator ul li::after {
    content: "|";
    display: inline-block;
    margin: 0 0.5em;
}
.status-indicator ul li:last-child::after {
    content: "";
}
.status-indicator svg {
    margin-left: 0.25rem;
    color: #808080;
}
</style>