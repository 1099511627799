<template>
	<div class="reports" v-for="(item,n) in contents" :key="n" v-bind:id="'report'+(n+1)">
		<div v-show="currentPage_report == (n+1)">
			<div class="modal-report onis_page_outline ">
				<template v-if="'ResultKensin' in item.reportjson.Root.OrderGroup.Order.Report">
					<div v-if="item.reportdomain=='CORSHY' || item.reportdomain=='TESTESFILL'" class="kenshin">
						<div xmlformmode="健診" xmltitle="健診レポート" xmlexamname="胸部X線" xmlexamcode="" xmlreadingmode="single" xmlreadingpart="A">
							<table cellspacing=0 cellpadding=0>
								<tr>
									<th align="center" valign="middle" style="width:40%;visibility:visible;">依頼施設</th>
									<th align="center" valign="middle" style="width:20%;visibility:visible;">レポートID</th>
									<th align="center" valign="middle" style="width:20%;visibility:visible;">オーダー日付</th>
									<th align="center" valign="middle" style="width:20%;visibility:visible;">検査種別</th>
								</tr>
								<tr>
								<td align="center" valign="middle" class="td0">
									<textarea readonly rows="1" class="INSTITUTION stdtxt" style="width:99%;" v-model="item.reportjson.Root.OrderGroup.Order.OrderInstitution"></textarea>
								</td>
								<td align="center" valign="middle" class="td0">
									<textarea readonly rows="1" class="REPORTID stdtxt" style="width:99%;" v-model="item.reportjson.Root.OrderGroup.Order.OrderID"></textarea>
								</td>
								<td align="center" valign="middle" class="td0">
									<textarea readonly rows="1" class="ORDERDATE stdtxt" style="width:99%;" v-model="item.reportjson.Root.OrderGroup.Order.OrderDate"></textarea>
								</td>
								<td align="center" valign="middle" class="td0">
									<textarea rows="1" class="EXAMNAME stdtxt" style="width:99%;" v-model="item.reportjson.Root.OrderGroup.Order.Report.ExamName"></textarea>
								</td>
								</tr>
							</table>
							<table cellspacing=0 cellpadding=0>
								<tr>
									<th align="center" valign="middle" style="width:20%;">受付番号</th>
									<th align="center" valign="middle" style="width:12%;">検査日付</th>
									<th align="center" valign="middle" style="width:20%;">検査ID</th>
									<th align="center" valign="middle" style="width:20%;">検査記述</th>
									<th align="center" valign="middle" style="width:10%;">モダリティ</th>
									<th align="center" valign="middle" style="width:18%;">主治医</th>
								</tr>
								<tr>
								<td align="center" valign="middle" class="td0" style="background:silver;">
									<textarea readonly rows="1" class="ACCNUM stdtxt" style="width:99%;" v-model="item.reportjson.Root.OrderGroup.Order.ImageInfo.StudyAccessionNumber"></textarea>
								</td>
								<td align="center" valign="middle" class="td0" style="background:silver;">
									<textarea readonly rows="1" class="STUDYDATE stdtxt" style="width:99%;" v-model="item.orderjson.OrderGroup.Order.ImageInfo[0].StudyInfo.StudyDate"></textarea>
								</td>
								<td align="center" valign="middle" class="td0" style="background:silver;">
									<textarea readonly rows="1" class="STUDYID stdtxt" style="width:99%;" v-model="item.orderjson.OrderGroup.Order.OrderInfo.LedgerNumber"></textarea>
								</td>
								<td align="center" valign="middle" class="td0" style="background:silver;">
									<textarea readonly rows="1" class="STUDYDESC stdtxt" style="width:99%;"></textarea>
								</td>
								<td align="center" valign="middle" class="td0" style="background:silver;">
									<textarea rows="1" class="MODALITIES stdtxt" style="width:99%" v-model="item.orderjson.OrderGroup.Order.ImageInfo[0].StudyInfo.StudyModalities"></textarea>
								</td>
								<td align="center" valign="middle" class="td0" style="background:silver;">
									<textarea rows="1" class="REFERRINGDOCTOR stdtxt" style="width:99%;" v-model="item.orderjson.OrderGroup.Order.OrderInfo.AttendingPhysician"></textarea>
								</td>
								</tr>
							</table>
							<table cellspacing=0 cellpadding=0>
								<tr>
								<th colspan="1" align="center" valign="middle" style="width:50%;">臨床情報</th>
								<th colspan="1" align="center" valign="middle" style="width:50%;">その他</th>
								</tr>
								<tr>
								<td colspan="1" align="left" valign="top" class="td0" style="background:grey;border:solid;border-width:1px;width:50%;">
									<textarea rows="2" class="CLINICALINFO stdtxt" style="padding:5px;text-align:left;overflow:auto;width:100%;height:40px;" v-model="item.orderjson.OrderGroup.Order.OrderInfo.ClinicalInfo"></textarea>
								</td>
								<td colspan="1" align="left" valign="top" class="td0" style="background:grey;border:solid;border-width:1px;width:50%;">
									<textarea rows="2" class="OTHERINFO stdtxt" style="padding:5px;text-align:left;overflow:auto;width:100%;height:40px;" v-bind:value="item.orderjson.OrderGroup.Order.OrderInfo.StudyComments + '/' + item.orderjson.OrderGroup.Order.OrderInfo.OrderComments"></textarea>
								</td>
								</tr>
							</table>
							<table cellspacing=0 cellpadding=0>
								<tr>
								<th align="center" valign="middle" style="width:25%;">患者ＩＤ</th>
								<th colspan="1" align="center" valign="middle" style="width:35%;">氏  名</th>
								<th align="center" valign="middle" style="width:20%;">年齢／性別</th>
								<th align="center" valign="middle" style="width:20%;">生年月日</th>
								</tr>
								<tr>
								<td align="center" valign="middle" class="td0" style="background:silver;">
									<textarea rows="1" class="PID stdtxt" style="width:99%;" v-model="item.reportjson.Root.OrderGroup.Order.ImageInfo.PatientID"></textarea>
								</td>
								<td colspan="1" align="center" valign="middle" class="td0" style="background:silver;">
									<textarea rows="1" class="PNAME stdtxt" style="width:99%;" v-model="item.orderjson.OrderGroup.Order.OrderInfo.EditedPatientName"></textarea>
								</td>
								<td align="center" valign="middle" class="td0" style="background:silver;">
									<textarea rows="1" class="PAGESEX stdtxt" style="width:99%;"></textarea>
								</td>
								<td align="center" valign="middle" class="td0" style="background:silver;">
									<textarea rows="1" class="PBIRTH stdtxt" style="width:99%;"></textarea>
								</td>
								</tr>
							</table>

							<div style="">
								<table cellspacing=0 cellpadding=0 style="width:100%;" >
									<tr v-if="item.orderjson.OrderGroup.Order.ImageInfo[0].StudyInfo.StudyModalities == 'OP'">
										<th align="center" valign="middle" style="width:6%" >番号</th>
										<th align="center" valign="middle" style="width:15%" >部  位</th>
										<th align="center" valign="middle" style="width:30%" >所  見</th>
										<th align="center" valign="middle" style="width:0%" >  </th>
										<th align="center" valign="middle" style="width:20%" >判定</th>
										<th align="center" valign="middle" style="width:auto" >コメント</th>
									</tr>
									<tr v-else>
										<th align="center" valign="middle" style="width:4%" >番号</th>
										<th align="center" valign="middle" style="width:25%" >部  位</th>
										<th align="center" valign="middle" style="width:25%" >所  見</th>
										<th align="center" valign="middle" style="width:25%" >病  名</th>
										<th align="center" valign="middle" style="width:5%" >判定</th>
										<th align="center" valign="middle" style="width:auto" >コメント</th>
									</tr>
									<template v-for="n of 9" :key="n">
										<tr  v-if="n+5 > 5 && n+5 <= 11">
											<!-- sheieやDAVISなどの所見欄 -->
											<td align="center" valign="top" class="td1" value="" >
												<textarea v-bind:class="'diagnosis'+n +' diagnosistxt'" readonly rows="1" xmltype="KensinResult" xmlexamname="眼底検査" xmlexamcode=""></textarea>
											</td>
											<td  align="center" valign="top" class="td1" value="" >
												<textarea v-bind:class="'diagnosis'+n+'a diagnosistxt'" rows="1" xmltype="KensinBody" v-bind:xmlnumber="n" xmlcode="" xmlname="" v-model="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.find(element => element.Number == n+5).BodyPart.Name">
												</textarea>
											</td>
											<td align="center" valign="top" class="td1" value="" >
												<textarea v-bind:class="'diagnosis'+n+'f diagnosistxt'" rows="1" xmltype="KensinFinding" v-bind:xmlnumber="n" xmlcode="" xmlname="" v-model="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.find(element => element.Number == n+5).Finding.Name">
												</textarea>
											</td>
											<td align="center" valign="top" class="td1" value="" >
												<textarea v-bind:class="'diagnosis'+n+'b diagnosistxt'" rows="1" xmltype="KensinDisease" v-bind:xmlnumber="n" xmlcode="" xmlname="" xmlsubitem="" xmlsubcode="" v-model="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.find(element => element.Number == n+5).Diagnosis.Name">
												</textarea>
											</td>
											<td align="center" valign="top" class="td1" value="" >
												<textarea v-bind:class="'diagnosis'+n+'c diagnosistxt'" rows="1" xmltype="KensinDiagnosis" v-bind:xmlnumber="n" xmlcode="" xmlname="">
												</textarea>
											</td>
											<td align="left" valign="top" class="td1" value="" >
												<textarea v-bind:class="'diagnosis'+n+'d diagnosistxt'" rows="1" xmltype="KensinComment" v-bind:xmlnumber="n" xmlcode="" style="ime-mode:active;" v-model="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.find(element => element.Number == n+5).Comment">
												</textarea>
											</td>
										</tr>
										<!-- 通常の所見欄 -->
										<tr v-else>
											<template v-if="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.findIndex(element => element.Number == n-6) != -1">
												<td align="center" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n +' diagnosistxt'" readonly rows="1" xmltype="KensinResult" xmlexamname="眼底検査" xmlexamcode="" v-bind:value="n-6"></textarea>
												</td>
												<td  align="center" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n+'a diagnosistxt'" rows="1" xmltype="KensinBody" v-bind:xmlnumber="n" xmlcode="" xmlname="" v-model="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.find(element => element.Number == n-6).BodyPart.Name">
													</textarea>
												</td>
												<td align="center" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n+'f diagnosistxt'" rows="1" xmltype="KensinFinding" v-bind:xmlnumber="n" xmlcode="" xmlname="" v-model="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.find(element => element.Number == n-6).Finding.Name">
													</textarea>
												</td>
												<td align="center" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n+'b diagnosistxt'" rows="1" xmltype="KensinDisease" v-bind:xmlnumber="n" xmlcode="" xmlname="" xmlsubitem="" xmlsubcode="" v-model="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.find(element => element.Number == n-6).Diagnosis.Name">
													</textarea>
												</td>
												<td align="center" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n+'c diagnosistxt'" rows="1" xmltype="KensinDiagnosis" v-bind:xmlnumber="n" xmlcode="" xmlname="" v-model="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.find(element => element.Number == n-6).Conclusion.Name">
													</textarea>
												</td>
												<td align="left" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n+'d diagnosistxt'" rows="1" xmltype="KensinComment" v-bind:xmlnumber="n" xmlcode="" style="ime-mode:active;" v-model="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.find(element => element.Number == n-6).Comment">
													</textarea>
												</td>
											</template>
											<!-- 通常所見（空白行） -->
											<template v-else>
												<td align="center" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n +' diagnosistxt'" readonly rows="1" xmltype="KensinResult" xmlexamname="眼底検査" xmlexamcode="" v-bind:value="n-6"></textarea>
												</td>
												<td  align="center" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n+'a diagnosistxt'" rows="1" xmltype="KensinBody" v-bind:xmlnumber="n" xmlcode="" xmlname="" ></textarea>
												</td>
												<td align="center" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n+'f diagnosistxt'" rows="1" xmltype="KensinFinding" v-bind:xmlnumber="n" xmlcode="" xmlname=""></textarea>
												</td>
												<td align="center" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n+'b diagnosistxt'" rows="1" xmltype="KensinDisease" v-bind:xmlnumber="n" xmlcode="" xmlname="" xmlsubitem="" xmlsubcode=""></textarea>
												</td>
												<td align="center" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n+'c diagnosistxt'" rows="1" xmltype="KensinDiagnosis" v-bind:xmlnumber="n" xmlcode="" xmlname=""></textarea>
												</td>
												<td align="left" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n+'d diagnosistxt'" rows="1" xmltype="KensinComment" v-bind:xmlnumber="n" xmlcode="" style="ime-mode:active;"></textarea>
												</td>
											</template>
										</tr>
									</template>
								</table>
							</div>
							<table cellspacing=0 cellpadding=0>
								<tr>
								<th align="center" valign="middle" border="1" style="width:100%">総合コメント</th>
								</tr>
								<tr>
								<td valign="top" class="ptxt" style="width:100%">
									<div class="th">
										<template v-if="'Conclusion' in item.reportjson.Root.OrderGroup.Order.Report.ResultKensin">
											<textarea class="CONCLUSION" xmltype="KensinComment" xmlnumber="101" rows="1" style="ime-mode:active;border:none;margin:-1 -7; text-align:left;width:100%;overflow:auto;"></textarea>
										</template>
										<template v-else-if="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.findIndex(element => element.Number == 101) != -1">
											<textarea class="CONCLUSION" xmltype="KensinComment" xmlnumber="101" rows="1" style="ime-mode:active;border:none;margin:-1 -7; text-align:left;width:100%;overflow:auto;" v-model="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.find(element => element.Number == 101).Comment"></textarea>
										</template>
										<template v-else>
											<textarea class="CONCLUSION" xmltype="KensinComment" xmlnumber="101" rows="1" style="ime-mode:active;border:none;margin:-1 -7; text-align:left;width:100%;overflow:auto;"></textarea>
										</template>
									</div>
								</td>
								</tr>
							</table>
							<table cellspacing=0 cellpadding=0>
								<tr>
								<th align="center" valign="middle" border="0" style="width:100%">キー画像</th>
								</tr>
								<tr>
								<td align="center" valign="middle" class="td0" style="display: flex;">
									<div class="kenshin_images" v-for="n of 4" :key="n" >
										<img v-if="item.reportjson.Root.OrderGroup.Order.Report.KeyImage[n-1].Image" alt="" v-bind:src="'data:image/png;base64,' + item.reportjson.Root.OrderGroup.Order.Report.KeyImage[n-1].Image" v-bind:class="'image_block'+n+' PRINT_IMAGE'+n" width="180" height="180">
										<div v-else v-bind:class="'PRINT_IMAGE'+n+' noimage'"></div>
									</div>
								</td>
								</tr>
							</table>
							<table cellspacing=0 cellpadding=0>
								<tr>
								<th align="center" valign="middle" style="width:10%;">読影種別</th>
								<th align="center" valign="middle" style="width:20%;">読影医</th>
								<th align="center" valign="middle" style="width:20%;">読影日</th>
								<th align="center" valign="middle" style="width:20%;">承認医</th>
								<th align="center" valign="middle" style="width:20%;">承認日</th>
								</tr>
								<tr>
								<td align="center" valign="middle" class="td0" style="width:10%;background:silver;">
									<textarea rows="1" class="READINGTYPEREQ stdtxt" style="width:99%;">通常</textarea>
								</td>
								<td align="center" valign="middle" class="td0" style="width:22.5%;background:silver;">
									<textarea rows="1" class="READINGDOCTOR stdtxt" style="width:99%;" v-model="item.reportjson.Root.OrderGroup.Order.Report.ReadingDoctor.Name"></textarea>
								</td>
								<td align="center" valign="middle" class="td0" style="width:22.5%;background:silver;">
									<textarea rows="1" class="READINGDATE stdtxt" style="width:99%;" v-model="item.reportjson.Root.OrderGroup.Order.Report.CreateDate"></textarea>
								</td>
								<td align="center" valign="middle" class="td0" style="width:22.5%;background:silver;">
									<textarea rows="1" class="VERIFYDOCTOR stdtxt" style="width:99%;" v-model="item.reportjson.Root.OrderGroup.Order.Report.VerifyDoctor.Name"></textarea>
								</td>
								<td align="center" valign="middle" class="td0" style="width:22.5%;background:silver;">
									<textarea rows="1" class="VERIFYDATE stdtxt" style="width:99%;" v-model="item.reportjson.Root.OrderGroup.Order.Report.VerifyDate"></textarea>
								</td>
								</tr>
							</table>
						</div>
					</div>

					<div v-else class="kenshin">
						<div xmlformmode="健診" xmltitle="健診レポート" xmlexamname="胸部X線" xmlexamcode="" xmlreadingmode="single" xmlreadingpart="A">
							<table cellspacing=0 cellpadding=0>
								<tr>
									<th align="center" valign="middle" style="width:40%;visibility:visible;">依頼施設</th>
									<th align="center" valign="middle" style="width:20%;visibility:visible;">レポートID</th>
									<th align="center" valign="middle" style="width:20%;visibility:visible;">オーダー日付</th>
									<th align="center" valign="middle" style="width:20%;visibility:visible;">検査種別</th>
								</tr>
								<tr>
								<td align="center" valign="middle" class="td0">
									<textarea readonly rows="1" class="INSTITUTION stdtxt" style="width:99%;" v-model="item.reportjson.Root.OrderGroup.Order.OrderInstitution"></textarea>
								</td>
								<td align="center" valign="middle" class="td0">
									<textarea readonly rows="1" class="REPORTID stdtxt" style="width:99%;" v-model="item.reportjson.Root.OrderGroup.Order.OrderID"></textarea>
								</td>
								<td align="center" valign="middle" class="td0">
									<textarea readonly rows="1" class="ORDERDATE stdtxt" style="width:99%;" v-model="item.reportjson.Root.OrderGroup.Order.OrderDate"></textarea>
								</td>
								<td align="center" valign="middle" class="td0">
									<textarea rows="1" class="EXAMNAME stdtxt" style="width:99%;" v-model="item.reportjson.Root.OrderGroup.Order.Report.ExamName"></textarea>
								</td>
								</tr>
							</table>
							<table cellspacing=0 cellpadding=0>
								<tr>
									<th align="center" valign="middle" style="width:20%;">受付番号</th>
									<th align="center" valign="middle" style="width:12%;">検査日付</th>
									<th align="center" valign="middle" style="width:20%;">検査ID</th>
									<th align="center" valign="middle" style="width:20%;">検査記述</th>
									<th align="center" valign="middle" style="width:10%;">モダリティ</th>
									<th align="center" valign="middle" style="width:18%;">主治医</th>
								</tr>
								<tr>
								<td align="center" valign="middle" class="td0" style="background:silver;">
									<textarea readonly rows="1" class="ACCNUM stdtxt" style="width:99%;" v-model="item.reportjson.Root.OrderGroup.Order.ImageInfo.StudyAccessionNumber"></textarea>
								</td>
								<td align="center" valign="middle" class="td0" style="background:silver;">
									<textarea readonly rows="1" class="STUDYDATE stdtxt" style="width:99%;" v-model="item.orderjson.OrderGroup.Order.ImageInfo[0].StudyInfo.StudyDate"></textarea>
								</td>
								<td align="center" valign="middle" class="td0" style="background:silver;">
									<textarea readonly rows="1" class="STUDYID stdtxt" style="width:99%;" v-model="item.orderjson.OrderGroup.Order.OrderInfo.LedgerNumber"></textarea>
								</td>
								<td align="center" valign="middle" class="td0" style="background:silver;">
									<textarea readonly rows="1" class="STUDYDESC stdtxt" style="width:99%;"></textarea>
								</td>
								<td align="center" valign="middle" class="td0" style="background:silver;">
									<textarea rows="1" class="MODALITIES stdtxt" style="width:99%" v-model="item.orderjson.OrderGroup.Order.ImageInfo[0].StudyInfo.StudyModalities"></textarea>
								</td>
								<td align="center" valign="middle" class="td0" style="background:silver;">
									<textarea rows="1" class="REFERRINGDOCTOR stdtxt" style="width:99%;" v-model="item.orderjson.OrderGroup.Order.OrderInfo.AttendingPhysician"></textarea>
								</td>
								</tr>
							</table>
							<table cellspacing=0 cellpadding=0>
								<tr>
								<th colspan="1" align="center" valign="middle" style="width:50%;">臨床情報</th>
								<th colspan="1" align="center" valign="middle" style="width:50%;">その他</th>
								</tr>
								<tr>
								<td colspan="1" align="left" valign="top" class="td0" style="background:grey;border:solid;border-width:1px;width:50%;">
									<textarea rows="2" class="CLINICALINFO stdtxt" style="padding:5px;text-align:left;overflow:auto;width:100%;height:40px;" v-model="item.orderjson.OrderGroup.Order.OrderInfo.ClinicalInfo"></textarea>
								</td>
								<td colspan="1" align="left" valign="top" class="td0" style="background:grey;border:solid;border-width:1px;width:50%;">
									<textarea rows="2" class="OTHERINFO stdtxt" style="padding:5px;text-align:left;overflow:auto;width:100%;height:40px;" v-bind:value="item.orderjson.OrderGroup.Order.OrderInfo.StudyComments + '/' + item.orderjson.OrderGroup.Order.OrderInfo.OrderComments"></textarea>
								</td>
								</tr>
							</table>
							<table cellspacing=0 cellpadding=0>
								<tr>
								<th align="center" valign="middle" style="width:25%;">患者ＩＤ</th>
								<th colspan="1" align="center" valign="middle" style="width:35%;">氏  名</th>
								<th align="center" valign="middle" style="width:20%;">年齢／性別</th>
								<th align="center" valign="middle" style="width:20%;">生年月日</th>
								</tr>
								<tr>
								<td align="center" valign="middle" class="td0" style="background:silver;">
									<textarea rows="1" class="PID stdtxt" style="width:99%;" v-model="item.reportjson.Root.OrderGroup.Order.ImageInfo.PatientID"></textarea>
								</td>
								<td colspan="1" align="center" valign="middle" class="td0" style="background:silver;">
									<textarea rows="1" class="PNAME stdtxt" style="width:99%;" v-model="item.orderjson.OrderGroup.Order.OrderInfo.EditedPatientName"></textarea>
								</td>
								<td align="center" valign="middle" class="td0" style="background:silver;">
									<textarea rows="1" class="PAGESEX stdtxt" style="width:99%;"></textarea>
								</td>
								<td align="center" valign="middle" class="td0" style="background:silver;">
									<textarea rows="1" class="PBIRTH stdtxt" style="width:99%;"></textarea>
								</td>
								</tr>
							</table>

							<div style="">
								<table cellspacing=0 cellpadding=0 style="width:100%;" >
									<tr v-if="item.orderjson.OrderGroup.Order.ImageInfo[0].StudyInfo.StudyModalities == 'OP'">
										<th align="center" valign="middle" style="width:6%" >番号</th>
										<th align="center" valign="middle" style="width:15%" >部  位</th>
										<th align="center" valign="middle" style="width:30%" >所  見</th>
										<th align="center" valign="middle" style="width:0%" >  </th>
										<th align="center" valign="middle" style="width:20%" >判定</th>
										<th align="center" valign="middle" style="width:auto" >コメント</th>
									</tr>
									<tr v-else>
										<th align="center" valign="middle" style="width:4%" >番号</th>
										<th align="center" valign="middle" style="width:25%" >部  位</th>
										<th align="center" valign="middle" style="width:25%" >所  見</th>
										<th align="center" valign="middle" style="width:25%" >病  名</th>
										<th align="center" valign="middle" style="width:5%" >判定</th>
										<th align="center" valign="middle" style="width:auto" >コメント</th>
									</tr>
									<template v-for="n of 10" :key="n">
										<tr  v-if="'Conclusion' in item.reportjson.Root.OrderGroup.Order.Report.ResultKensin">
											<template v-if="n == 1">
												<td align="center" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n +' diagnosistxt'" readonly rows="1" xmltype="KensinResult" xmlexamname="眼底検査" xmlexamcode="" v-bind:value="n"></textarea>
												</td>
												<td  align="center" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n+'a diagnosistxt'" rows="1" xmltype="KensinBody" v-bind:xmlnumber="n" xmlcode="" xmlname="" v-model="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.BodyPart.Name">
													</textarea>
												</td>
												<td align="center" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n+'f diagnosistxt'" rows="1" xmltype="KensinFinding" v-bind:xmlnumber="n" xmlcode="" xmlname="" v-model="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.Finding.Name">
													</textarea>
												</td>
												<td align="center" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n+'b diagnosistxt'" rows="1" xmltype="KensinDisease" v-bind:xmlnumber="n" xmlcode="" xmlname="" xmlsubitem="" xmlsubcode="" v-model="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.Diagnosis.Name">
													</textarea>
												</td>
												<td align="center" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n+'c diagnosistxt'" rows="1" xmltype="KensinDiagnosis" v-bind:xmlnumber="n" xmlcode="" xmlname="" v-model="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.Conclusion.Name">
													</textarea>
												</td>
												<td align="left" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n+'d diagnosistxt'" rows="1" xmltype="KensinComment" v-bind:xmlnumber="n" xmlcode="" style="ime-mode:active;" v-model="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.Comment"
													>
													</textarea>
												</td>
											</template>
											<template v-else-if="n > 1 && n <=5">
												<td align="center" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n +' diagnosistxt'" readonly rows="1" xmltype="KensinResult" xmlexamname="眼底検査" xmlexamcode="" v-bind:value="n"></textarea>
												</td>
												<td  align="center" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n+'a diagnosistxt'" rows="1" xmltype="KensinBody" v-bind:xmlnumber="n" xmlcode="" xmlname="" ></textarea>
												</td>
												<td align="center" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n+'f diagnosistxt'" rows="1" xmltype="KensinFinding" v-bind:xmlnumber="n" xmlcode="" xmlname=""></textarea>
												</td>
												<td align="center" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n+'b diagnosistxt'" rows="1" xmltype="KensinDisease" v-bind:xmlnumber="n" xmlcode="" xmlname="" xmlsubitem="" xmlsubcode=""></textarea>
												</td>
												<td align="center" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n+'c diagnosistxt'" rows="1" xmltype="KensinDiagnosis" v-bind:xmlnumber="n" xmlcode="" xmlname=""></textarea>
												</td>
												<td align="left" valign="top" class="td1" value="" >
													<textarea v-bind:class="'diagnosis'+n+'d diagnosistxt'" rows="1" xmltype="KensinComment" v-bind:xmlnumber="n" xmlcode="" style="ime-mode:active;"></textarea>
												</td>
											</template>
										</tr>
										<tr  v-else-if="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.findIndex(element => element.Number == n) != -1">
											<td align="center" valign="top" class="td1" value="" >
												<textarea v-bind:class="'diagnosis'+n +' diagnosistxt'" readonly rows="1" xmltype="KensinResult" xmlexamname="眼底検査" xmlexamcode="" v-bind:value="n"></textarea>
											</td>
											<td  align="center" valign="top" class="td1" value="" >
												<textarea v-bind:class="'diagnosis'+n+'a diagnosistxt'" rows="1" xmltype="KensinBody" v-bind:xmlnumber="n" xmlcode="" xmlname="" v-model="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.find(element => element.Number == n).BodyPart.Name">
												</textarea>
											</td>
											<td align="center" valign="top" class="td1" value="" >
												<textarea v-bind:class="'diagnosis'+n+'f diagnosistxt'" rows="1" xmltype="KensinFinding" v-bind:xmlnumber="n" xmlcode="" xmlname="" v-model="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.find(element => element.Number == n).Finding.Name">
												</textarea>
											</td>
											<td align="center" valign="top" class="td1" value="" >
												<textarea v-bind:class="'diagnosis'+n+'b diagnosistxt'" rows="1" xmltype="KensinDisease" v-bind:xmlnumber="n" xmlcode="" xmlname="" xmlsubitem="" xmlsubcode="" v-model="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.find(element => element.Number == n).Diagnosis.Name">
												</textarea>
											</td>
											<td align="center" valign="top" class="td1" value="" >
												<textarea v-bind:class="'diagnosis'+n+'c diagnosistxt'" rows="1" xmltype="KensinDiagnosis" v-bind:xmlnumber="n" xmlcode="" xmlname="" v-model="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.find(element => element.Number == n).Conclusion.Name">
												</textarea>
											</td>
											<td align="left" valign="top" class="td1" value="" >
												<textarea v-bind:class="'diagnosis'+n+'d diagnosistxt'" rows="1" xmltype="KensinComment" v-bind:xmlnumber="n" xmlcode="" style="ime-mode:active;" v-model="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.find(element => element.Number == n).Comment">
												</textarea>
											</td>
										</tr>
										<tr v-else-if="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.findIndex(element => element.Number == n) == -1 && n <= 5">
											<td align="center" valign="top" class="td1" value="" >
												<textarea v-bind:class="'diagnosis'+n +' diagnosistxt'" readonly rows="1" xmltype="KensinResult" xmlexamname="眼底検査" xmlexamcode="" v-bind:value="n"></textarea>
											</td>
											<td  align="center" valign="top" class="td1" value="" >
												<textarea v-bind:class="'diagnosis'+n+'a diagnosistxt'" rows="1" xmltype="KensinBody" v-bind:xmlnumber="n" xmlcode="" xmlname="" ></textarea>
											</td>
											<td align="center" valign="top" class="td1" value="" >
												<textarea v-bind:class="'diagnosis'+n+'f diagnosistxt'" rows="1" xmltype="KensinFinding" v-bind:xmlnumber="n" xmlcode="" xmlname=""></textarea>
											</td>
											<td align="center" valign="top" class="td1" value="" >
												<textarea v-bind:class="'diagnosis'+n+'b diagnosistxt'" rows="1" xmltype="KensinDisease" v-bind:xmlnumber="n" xmlcode="" xmlname="" xmlsubitem="" xmlsubcode=""></textarea>
											</td>
											<td align="center" valign="top" class="td1" value="" >
												<textarea v-bind:class="'diagnosis'+n+'c diagnosistxt'" rows="1" xmltype="KensinDiagnosis" v-bind:xmlnumber="n" xmlcode="" xmlname=""></textarea>
											</td>
											<td align="left" valign="top" class="td1" value="" >
												<textarea v-bind:class="'diagnosis'+n+'d diagnosistxt'" rows="1" xmltype="KensinComment" v-bind:xmlnumber="n" xmlcode="" style="ime-mode:active;"></textarea>
											</td>
										</tr>
									</template>
								</table>
							</div>
							<table cellspacing=0 cellpadding=0>
								<tr>
								<th align="center" valign="middle" border="1" style="width:100%">総合コメント</th>
								</tr>
								<tr>
								<td valign="top" class="ptxt" style="width:100%">
									<div class="th">
										<template v-if="'Conclusion' in item.reportjson.Root.OrderGroup.Order.Report.ResultKensin">
											<textarea class="CONCLUSION" xmltype="KensinComment" xmlnumber="101" rows="1" style="ime-mode:active;border:none;margin:-1 -7; text-align:left;width:100%;overflow:auto;"></textarea>
										</template>
										<template v-else-if="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.findIndex(element => element.Number == 101) != -1">
											<textarea class="CONCLUSION" xmltype="KensinComment" xmlnumber="101" rows="1" style="ime-mode:active;border:none;margin:-1 -7; text-align:left;width:100%;overflow:auto;" v-model="item.reportjson.Root.OrderGroup.Order.Report.ResultKensin.find(element => element.Number == 101).Comment"></textarea>
										</template>
										<template v-else>
											<textarea class="CONCLUSION" xmltype="KensinComment" xmlnumber="101" rows="1" style="ime-mode:active;border:none;margin:-1 -7; text-align:left;width:100%;overflow:auto;"></textarea>
										</template>
									</div>
								</td>
								</tr>
							</table>
							<table cellspacing=0 cellpadding=0>
								<tr>
								<th align="center" valign="middle" border="0" style="width:100%">キー画像</th>
								</tr>
								<tr>
								<td align="center" valign="middle" class="td0" style="display: flex;">
									<div class="kenshin_images" v-for="n of 4" :key="n" >
										<img v-if="item.reportjson.Root.OrderGroup.Order.Report.KeyImage[n-1].Image" alt="" v-bind:src="'data:image/png;base64,' + item.reportjson.Root.OrderGroup.Order.Report.KeyImage[n-1].Image" v-bind:class="'image_block'+n+' PRINT_IMAGE'+n" width="180" height="180">
										<div v-else v-bind:class="'PRINT_IMAGE'+n+' noimage'"></div>
									</div>
								</td>
								</tr>
							</table>
							<table cellspacing=0 cellpadding=0>
								<tr>
								<th align="center" valign="middle" style="width:10%;">読影種別</th>
								<th align="center" valign="middle" style="width:20%;">読影医</th>
								<th align="center" valign="middle" style="width:20%;">読影日</th>
								<th align="center" valign="middle" style="width:20%;">承認医</th>
								<th align="center" valign="middle" style="width:20%;">承認日</th>
								</tr>
								<tr>
								<td align="center" valign="middle" class="td0" style="width:10%;background:silver;">
									<textarea rows="1" class="READINGTYPEREQ stdtxt" style="width:99%;">通常</textarea>
								</td>
								<td align="center" valign="middle" class="td0" style="width:22.5%;background:silver;">
									<textarea rows="1" class="READINGDOCTOR stdtxt" style="width:99%;" v-model="item.reportjson.Root.OrderGroup.Order.Report.ReadingDoctor.Name"></textarea>
								</td>
								<td align="center" valign="middle" class="td0" style="width:22.5%;background:silver;">
									<textarea rows="1" class="READINGDATE stdtxt" style="width:99%;" v-model="item.reportjson.Root.OrderGroup.Order.Report.CreateDate"></textarea>
								</td>
								<td align="center" valign="middle" class="td0" style="width:22.5%;background:silver;">
									<textarea rows="1" class="VERIFYDOCTOR stdtxt" style="width:99%;" v-model="item.reportjson.Root.OrderGroup.Order.Report.VerifyDoctor.Name"></textarea>
								</td>
								<td align="center" valign="middle" class="td0" style="width:22.5%;background:silver;">
									<textarea rows="1" class="VERIFYDATE stdtxt" style="width:99%;" v-model="item.reportjson.Root.OrderGroup.Order.Report.VerifyDate"></textarea>
								</td>
								</tr>
							</table>
						</div>
					</div>
				</template>

				<template v-else>
					<div class="shinryo">
					<!-- <div v-if="'Result' in item.reportjson.Root.OrderGroup.Order.Report" class="shinryo"></div> -->
						<div class="header">
							<div class="report-row">
								<div><span class="ReportTitle">画像診断報告書</span></div>
								<div class="report-row institution-and-modality">
									<div><p class="PRINT_INSTITUTION ReportClient">{{ item.reportjson.Root.OrderGroup.Order.OrderInstitution }}</p></div>
									<div><span class="PRINT_CAPMODALITY ModalityTitle">{{ item.orderjson.OrderGroup.Order.ImageInfo[0].StudyInfo.StudyModalities }}</span></div>
								</div>
							</div>
						</div>

						<div class="edit-area">
							<div class="report-row mb-row">
								<div class="report-row">
									<div class="onis_title_text left-side-title">患者ID：</div>
									<div class="inputDiv PRINT_PATIENTID onis_patientid_field">{{ item.reportjson.Root.OrderGroup.Order.ImageInfo.PatientID }}</div>
								</div>
								<div class="report-row">
									<div class="onis_title_text">氏名：</div>
									<div class="inputDiv PRINT_PATIENTNAME onis_patientname_field">{{ item.orderjson.OrderGroup.Order.OrderInfo.EditedPatientName }}</div>
									<div class="inputDiv PRINT_PATIENTKANJI onis_patientkanji_field">{{ item.orderjson.OrderGroup.Order.OrderInfo.EditedPatientIdeogram }}</div>
									<div class="inputDiv PRINT_PATIENTKANA onis_patientkana_field">{{ item.orderjson.OrderGroup.Order.OrderInfo.EditedPatinePhonetic }}</div>
								</div>
							</div>
							<div class="report-row mb-row">
								<div class="report-row">
									<div class="onis_title_text left-side-title">生年月日：</div>
									<div class="inputDiv PRINT_PATIENTBIRTHDATE onis_patientbirthdate_field"></div>
									<div class="onis_title_text">性別：</div>
									<div class="inputDiv PRINT_PATIENTSEX onis_patientsex_field"></div>
									<div class="onis_title_text">診療科：</div>
									<div class="inputDiv PRINT_REQUESTDEPT onis_requestdept_field">{{ item.reportjson.Root.OrderGroup.Order.OrderInfo.Departments }}</div>
									<div class="inputDiv PRINT_INOUTPATIENT onis_inoutpatient_field" v-if="item.orderjson.OrderGroup.Order.OrderInfo.EditedPatinePhonetic == '0'" >入院</div>
									<div class="inputDiv PRINT_INOUTPATIENT onis_inoutpatient_field" v-else-if="item.orderjson.OrderGroup.Order.OrderInfo.EditedPatinePhonetic == '1'" >外来</div>
									<div class="inputDiv PRINT_INOUTPATIENT onis_inoutpatient_field" v-else-if="item.orderjson.OrderGroup.Order.OrderInfo.EditedPatinePhonetic == '2'" >受託</div>
									<div class="inputDiv PRINT_INOUTPATIENT onis_inoutpatient_field" v-else-if="item.orderjson.OrderGroup.Order.OrderInfo.EditedPatinePhonetic == '3'" >健診</div>
								</div>
							</div>
							<div class="report-row mb-row">
								<div class="report-row">
									<div class="onis_title_text left-side-title">モダリティ：</div>
									<div class="inputDiv PRINT_MODALITY onis_modality_field">{{ item.orderjson.OrderGroup.Order.ImageInfo[0].StudyInfo.StudyModalities }}</div>
								</div>
								<div class="report-row">
									<div class="onis_title_text">検査部位：</div>
									<div class="inputDiv PRINT_BODYPART onis_bodypart_field">{{ item.reportjson.Root.OrderGroup.Order.OrderInfo.EditedStudyBodyparts }}</div>
								</div>
								<div class="report-row">
									<div class="onis_title_text">検査日付：</div>
									<div class="inputDiv PRINT_STUDYDATE onis_studydate_field">{{ item.orderjson.OrderGroup.Order.ImageInfo[0].StudyInfo.StudyDate }}</div>
								</div>
								<div class="report-row">
									<div class="onis_title_text">番号：</div>
									<div class="inputDiv PRINT_STUDYID onis_studyid_field">{{ item.orderjson.OrderGroup.Order.OrderInfo.LedgerNumber }}</div>
								</div>
							</div>
							<div class="report-row mb-row align-items-start">
								<div class="onis_title_text left-side-title">臨床情報：</div>
								<div class="inputDiv PRINT_CLINICAL_INFO onis_clinicalinfo_field">{{ item.orderjson.OrderGroup.Order.OrderInfo.EditedStudyBodyparts }}</div>
							</div>
								<hr>
							<div class="report-row mb-row doctors">
								<div class="report-row">
									<div class="onis_title_text left-side-title">読影医：</div>
									<div class="inputDiv PRINT_READINGDOCTOR onis_readingdoctor_field">{{ item.reportjson.Root.OrderGroup.Order.ReadingDoctor.Name }}</div>
								</div>
								<div class="report-row">
									<div class="onis_title_text">承認医：</div>
									<div class="inputDiv PRINT_VERIFYDOCTOR onis_verifydoctor_field">{{ item.reportjson.Root.OrderGroup.Order.ReadingDoctor.Name }}</div>
								</div>
							</div>
							<div class="report-row mb-row">
								<div class="report-row">
									<div class="onis_title_text left-side-title">レポートID：</div>
									<div class="inputDiv PRINT_REPORTID onis_reportid_field">{{ item.reportjson.Root.OrderGroup.Order.OrderID }}</div>
								</div>
								<div class="report-row">
									<div class="onis_title_text">オーダー日時：</div>
									<div class="inputDiv PRINT_ORDERDATE onis_orderdate_field">{{ item.reportjson.Root.OrderGroup.Order.OrderDate }}</div>
								</div>
								<div class="report-row">
									<div class="onis_title_text">承認日付：</div>
									<div class="inputDiv PRINT_VERIFYDATE onis_verifydate_field">{{ item.reportjson.Root.OrderGroup.Order.ReportVerifyDate }}</div>
								</div>
							</div>

								<hr>
							<div class="Result">
								<div class="report-row mb-row">
									<div class="onis_title_text left-side-title">所見：</div>
									<div class="inputDiv PRINT_FINDINGS findings_field">{{ item.reportjson.Root.OrderGroup.Order.Report.Result.Finding }}</div>
								</div>
							</div>
							<div class="report-row mb-row">
								<div class="onis_title_text left-side-title">診断：</div>
								<div class="inputDiv PRINT_CONCLUSION conclusion_field">{{ item.reportjson.Root.OrderGroup.Order.Report.Result.Diagnosis }}</div>
							</div>
							<div class="report-row mb-row">
								<div class="onis_title_text left-side-title">コメント：</div>
								<div class="inputDiv PRINT_COMMENT comment_field">{{ item.reportjson.Root.OrderGroup.Order.Report.Result.Comment }}</div>
							</div>
							<div class="report-row key-images">
								<div class="onis_title_text left-side-title">キー画像：</div>
								<div class="report-row align-items-start">
									<div class="onis_image_block onis_image1_field">
										<div class="onis_title_images">
											<img v-if="item.reportjson.Root.OrderGroup.Order.Report.KeyImage[0].Image" class="PRINT_IMAGE1" alt="" v-bind:src="'data:image/png;base64,' + item.reportjson.Root.OrderGroup.Order.Report.KeyImage[0].Image" />
											<div v-else class="PRINT_IMAGE1 noimage"></div>
										</div>
										<div class="onis_image_comment_block onis_image1_field">
											<div class="inputDiv PRINT_IMAGE1_COMMENT">{{ item.reportjson.Root.OrderGroup.Order.Report.KeyImage[0].Comment }}</div>
										</div>
									</div>
									<div class="onis_image_block onis_image2_field">
										<div class="onis_title_images">
											<img v-if="item.reportjson.Root.OrderGroup.Order.Report.KeyImage[1].Image" class="PRINT_IMAGE2" alt="" v-bind:src="'data:image/png;base64,' + item.reportjson.Root.OrderGroup.Order.Report.KeyImage[1].Image" />
											<div v-else class="PRINT_IMAGE2 noimage"></div>
										</div>
										<div class="onis_image_comment_block onis_image2_field">
											<div class="inputDiv PRINT_IMAGE2_COMMENT">{{ item.reportjson.Root.OrderGroup.Order.Report.KeyImage[1].Comment }}</div>
										</div>
									</div>
									<div class="onis_image_block onis_image3_field">
										<div class="onis_title_images">
											<img v-if="item.reportjson.Root.OrderGroup.Order.Report.KeyImage[2].Image" class="PRINT_IMAGE3" alt="" v-bind:src="'data:image/png;base64,' + item.reportjson.Root.OrderGroup.Order.Report.KeyImage[2].Image" />
											<div v-else class="PRINT_IMAGE3 noimage"></div>
										</div>
										<div class="onis_image_comment_block onis_image3_field">
											<div class="inputDiv PRINT_IMAGE3_COMMENT">{{ item.reportjson.Root.OrderGroup.Order.Report.KeyImage[2].Comment }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div>
							<div class="footer">
								<div><label class="FooterTitle">&nbsp;&nbsp;&nbsp;</label></div>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: [
		'contents',
		'currentPage_report',
	],
	// data(){
	// 	maxlength:0
	// },
	methods: {
		PxTextareaAdjuster( textarea ){
			let finalHeight = textarea.offsetHeight
			textarea.style.overflow='hidden';
			if( textarea.scrollHeight > textarea.offsetHeight ){
				finalHeight = textarea.scrollHeight
			}
			return parseInt(finalHeight)
		},
		// 総合コメントの高さ自動調整
		PxTextareaAdjuster_ComprehensiveComment( textarea ){
			textarea.style.overflow='hidden';
			var defaultHeight = textarea.offsetHeight;

			textarea.style.height = defaultHeight+'px';
			var tmp_sh = textarea.scrollHeight;
			while( tmp_sh > textarea.scrollHeight ){
				tmp_sh = textarea.scrollHeight;
				textarea.scrollHeight ++;
			}
			if( textarea.scrollHeight > textarea.offsetHeight ){
				textarea.style.height = textarea.scrollHeight+'px';
			}
		},
	},
	watch:{
		contents:async function(newVal){
			if(newVal.length > 0){
				let _this = this
				let domList = ['a','f','b','c','d']
				setTimeout(function() {
					for (let n = 0; n < _this.contents.length; n++) {
						let reportElemnt = document.getElementById('report' + (n+1))
						let commentElements = reportElemnt.querySelectorAll('[xmltype="KensinComment"]:not(.CONCLUSION)')
						for (let i = 0; i < commentElements.length; i++) {
							let maxHeight = 0
							// 部位～コメント列の中の要素の高さ最大値を取得
							for (let k = 0; k < domList.length; k++) {
								let targetElement = reportElemnt.getElementsByClassName('diagnosis' + (i+1) +  domList[k])[0]
								// テキストエリアの高さを入力文字数に合わせて変更
								let Height = _this.PxTextareaAdjuster(targetElement)
								if (maxHeight < Height){
									maxHeight = Height
								}
							}
							// 取得した高さの最大値を部位～コメント列の高さに全て設定して、全ての列の高さを最大値にそろえる
							for (let k = 0; k < domList.length; k++) {
								let targetElement = reportElemnt.getElementsByClassName('diagnosis' + (i+1) +  domList[k])[0]
								targetElement.style.height = maxHeight + 'px'
							}
							// 番号列にも高さを設定
							document.getElementsByClassName('diagnosis' + (i+1))[0].style.height = maxHeight + 'px'
						}
						if(reportElemnt.getElementsByClassName('CONCLUSION')[0]){
							_this.PxTextareaAdjuster_ComprehensiveComment(reportElemnt.getElementsByClassName('CONCLUSION')[0])
						}
					}
				},100)
			}
		}
	},
}
</script>

<style scoped>
.break { page-break-before: auto; }
body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
hr {
	width: 100%;
	border: solid 1px 0 0 0 #666;
}
.mb-row {
	margin-bottom: 0.5rem;
}
.align-items-start {
	align-items: flex-start !important;
}
.modal-report input[type="text"],
.modal-report input[type="textarea"] {
	padding: 0.25em;
}
.modal-report .onis_clinicalinfo_field {
	flex: 1 1 auto;
}
.modal-report.onis_page_outline {
	width: 210mm;
	min-height: 297mm;
	border-width: 1px;
	border-style: dashed;
	border-color: #b3b3b3;
	padding: 5mm;
}
.modal-report .report-row {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
}
.modal-report .left-side-title {
	width: 6em;
}
.modal-report .header {
	margin-bottom: 5px;
	padding: 2px 5px 2px 20px;
	-webkit-print-color-adjust: exact; 
	background-color: #cccccc !important;
	border-bottom-width: 5px;
	border-bottom-style: solid;
	border-bottom-color: #3366ff;
	color: #ffffff;
}
.modal-report .edit-area {
	padding: 0 0.5em;
}
.modal-report .ReportTitle {
	font-weight: bold;
	left: 20px;
	top: 1px;
	color: #000000;
	font-size: 16pt;
}
.modal-report .institution-and-modality {
	align-items: baseline;
}
.modal-report .ReportClient {
	margin-right: 2em;
	font-weight: lighter;
	color: #000000;
	font-size: 9pt;
}
.modal-report .ModalityTitle {
	font-family: "serif, monospace";
	font-weight: bold;
	font-size: 26pt;
	background-color: #3366ff;
	color: #ffffff;
}
.modal-report .onis_title_text {
	flex: 0 0 auto;
	font-size: 14px;
	font-weight: lighter;
}
.modal-report .findings_field,
.modal-report .conclusion_field,
.modal-report .comment_field {
	white-space: pre-wrap;
	flex: 1 1 auto
}
.modal-report .key-images {
	align-items: flex-start;
}
.modal-report .key-images .report-row {
	flex: 1 1 auto;
}
.modal-report .onis_title_images {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 200px;
	height: 200px;
	margin-bottom: 0.5em;
	overflow: hidden;
	border: solid 1px #808080;
}
.modal-report .onis_title_images img {
	max-width: 100%;
	max-height: 100%;
}
.modal-report .onis_image_comment_block textarea {
	width: 100%;
	box-sizing: border-box;
}
.modal-report .footer {
	background-color: #cccccc;
	border-bottom-width: 5px;
	border-bottom-style: solid;
	border-bottom-color: #3366ff;
	color: #ffffff;
}
.inputDiv {
	border: solid 1px #808080;
	min-height: 1rem;
	margin-right: 0.5em;
}
.PRINT_PATIENTNAME, .PRINT_PATIENTKANJI, .PRINT_PATIENTKANA {
	width: 11em;
}
.PRINT_PATIENTKANA {
	margin: 0;
}
.PRINT_PATIENTBIRTHDATE {
	width: 7em;
}
.PRINT_PATIENTSEX {
	width: 3em;
}
.PRINT_REQUESTDEPT {
	width: 10em;
}
.PRINT_INOUTPATIENT {
	width: 5em;
}
.PRINT_REFERRINGDOCTOR {
	width: 10em;
	margin: 0;
}
.PRINT_MODALITY {
	width: 4em;
}

.PRINT_BODYPART {
	min-width: 65px;
}

.PRINT_STUDYDATE {
	width: 7em;
}
.PRINT_STUDYID {
	width: 10.5em;
	margin: 0;
}
.PRINT_ORDERDATE {
	width: 10em;
}
.PRINT_VERIFYDATE {
	width: 10em;
	margin: 0;
}

.PRINT_PATIENTKANA, .PRINT_PATIENTNAME, .PRINT_PATIENTKANJI,.PRINT_READINGDOCTOR, .PRINT_VERIFYDOCTOR {
  width: 180px;
}
.PRINT_REFERRINGDOCTOR{
  width: 120px;
}

.PRINT_REPORTID {
	min-width: 65px;
}

.title_findings, .title_conclusion, .title_comment, .title_keyimage {
  position: absolute;
  width: 7%;
  border-style: none;
  resize: none;
}
.PRINT_FINDINGS {
  width: 99%;
  min-height: 18rem;
}
.PRINT_CONCLUSION {
  width: 99%;
  min-height: 10rem;
}

.key-images {
	margin-bottom: 10px;
}

.PRINT_COMMENT {
	min-height: 2rem;
}
.PRINT_CLINICAL_INFO, .PRINT_COMMENT, .PRINT_IMAGECOMMENT, .onis_image_comment_block {
  width: 99%;
}
.onis_image_comment_block {
  text-align: center;
}

.conc {
  vertical-align: top;
}




/* 健診 */
.kenshin .itemtxt{overflow:hidden;border:none;font-size:12;padding: 3 5;width:210px;}
.kenshin .itemtxt_bodypart{overflow:hidden;border:none;font-size:12;padding: 3 5;width:115px;}
.kenshin .itemtxt_disease{overflow:hidden;border:none;font-size:12;padding: 3 5;width:150px;}
.kenshin .itemtxt_decision{overflow:hidden;border:none;font-size:12;padding: 3 5;width:45px;}
.kenshin .itemtxt_finding{overflow:hidden;border:none;font-size:12;padding: 3 5;width:135px;}
.kenshin .diagnosistxt{
    /* overflow:hidden; */
    border:none;
    font-size:12;
    padding: 2 10;
    width:100%;
    min-height:30px;
    /* height:30px */
}

.kenshin .ptxt{
    overflow:hidden;
    border-width: 1px;
    border:none;
    border-color: #b3b3b3;
    font-size:14;
    padding: 2 10;
    width:400px;
}
.kenshin .stdtxt{
    overflow:hidden;
    border-width: 1px;
    border:none;
    border-color: #b3b3b3;
    font-size:14;
    padding: 2 0;
    text-align: center;
    width:100%;
}
.kenshin table{
    border-width: 1px;
    border-style: solid;
    border-color: #b3b3b3;
    padding: 0px;
    margin: 0px;
    width: 100%;
		font-size:14px;
    table-layout: fixed;
}
.kenshin th{
    border-width: 1px;
    border-style: solid;
    border-color: #b3b3b3;
    font-size:12;
    font-weight:normal;
    padding: 2px 0px 1px 0px;
    margin: 0px;
		-webkit-print-color-adjust: exact;
    background:lightgrey !important;
    vertical-align:baseline;
    height:17px;
    color:#000099;
		text-align: center;
}
.kenshin .td0{
    border-width: 1px;
    border-style: none;
    border-color: #b3b3b3;
    font-size:12;
    padding: 0px 0px 0px 0px;
    word-break:break-all;
}
.kenshin .td1{
    border-width: 1px;
    border-style: solid;
    border-color: #b3b3b3;
    font-size:12;
    padding: 0px 0px 0px 0px;
}
.kenshin .td2{
    border-width: 0px;
    font-size:12;
    padding: 5px 0px 5px 15px;
}
.kenshin .td21{
    border-width: 1px;
    border-style: solid;
    border-color: #b3b3b3;
    font-size:12;
    padding: 5px 0px 5px 15px;
}
.kenshin .td3{
    border-width: 1px;
    border-style: solid;
    border-color: #b3b3b3;
    font-size:12;
    padding: 5px 5px 5px 15px;
}
.kenshin .image_block1 {
    position: relative;
    float:left;
    top: 0px;
    left:-2px;
    width: 180px;
    height: 180px;
    margin-bottom: 0px;
    margin-left: 3px;
    margin-right: 4px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #b3b3b3;
    border-right-color: #b3b3b3;
    border-bottom-color: #b3b3b3;
    border-left-color: #b3b3b3;
}
.kenshin .image_block2 {
    position: relative;
    float:left;
    top: 0px;
    left:-8px;
    width: 180px;
    height: 180px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #b3b3b3;
    border-right-color: #b3b3b3;
    border-bottom-color: #b3b3b3;
    border-left-color: #b3b3b3;
}
.kenshin .image_block3 {
    position: relative;
    float:left;
    top: 0px;
    left:-13px;
    width: 180px;
    height: 180px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #b3b3b3;
    border-right-color: #b3b3b3;
    border-bottom-color: #b3b3b3;
    border-left-color: #b3b3b3;
}
.kenshin .image_block4 {
    position: relative;
    float:left;
    top: 0px;
    left:0px;
    width: 180px;
    height: 180px;
    margin-bottom: 0px;
    margin-left: -18px;
    margin-right: 0px;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #b3b3b3;
    border-right-color: #b3b3b3;
    border-bottom-color: #b3b3b3;
    border-left-color: #b3b3b3;
}

.kenshin_images{
	justify-content: center;
	align-items: center;
	width: 180px;
	height: 180px;
	margin-bottom: 0.5em;
	overflow: hidden;
	border: solid 1px #808080;
}

.modal-report .doctors {
	justify-content: flex-start;
}

.kenshin textarea{
	font-size: 14px !important;
	display: block;
	resize: none;  /* リサイズを無効化 */
	overflow: hidden; /* コンテンツのオーバーフローを隠す */
}

@page {
	margin: 1.2cm;
}
@media print {
	.modal-report.onis_page_outline {
		border: none;
	}
	.inputDiv {
		page-break-inside: avoid;
	}
	html, body {
    height:auto;
    overflow: hidden;
	}
	.kenshin th{
		background-color: lightgray;
	}
	.kenshin table .kenshin tbody,.kenshin th, .kenshin tr, .kenshin textarea {
		border:1px solid #b3b3b3 !important;
	} 
}

.modal-report {
    font-size: 13px;
    line-height: 1.25em;
}

.modal-report .header .report-row {
    line-height: normal;
}

.inputDiv .key-images {
	page-break-inside: avoid;
}

.reports{
	display:block;
	/* page-break-after: always; */
}

.reports:last-child{
    page-break-after: auto;
}

.modal-report:last-child{
    page-break-after: auto;
}

</style>