<template>
<div class="container">
	<h2><font-awesome-icon icon="address-card" />&nbsp;新規登録</h2>
	<div id="RegisterInfo" class="container">
		<div class="row mb-2">
			<label for="loginname" class="registercols col-sm-2 col-form-label">{{ itemNames.loginname }}<span style="color:red; padding:0;">【必須】</span></label>
			<div class="col-sm-10">
				<input
					type="text"
					id="loginname"
					name="loginname"
					v-model="userInfo.loginname"
					class="form-control"
					:placeholder="userInfoDefault.loginname">
			</div>
		</div>
		<div class="row mb-2">
			<label for="kanjiname" class="registercols col-sm-2 col-form-label">{{ itemNames.kanjiname }}</label>
			<div class="col-sm-10">
				<input
					type="text"
					id="kanjiname"
					name="kanjiname"
					v-model="userInfo.kanjiname"
					class="form-control"
					@change="inputCheck($event,'ZenkakuKanji')"
					:placeholder="userInfoDefault.kanjiname">
			</div>
			<span class="errorMessage" style="color:red;margin-left:10px;width: auto;margin-top: 10px;"></span>
		</div>
		<div class="row mb-2">
			<label for="kananame" class="registercols col-sm-2 col-form-label">{{ itemNames.kananame }}</label>
			<div class="col-sm-10">
					<input
					type="text"
					id="kananame"
					name="kananame"
					v-model="userInfo.kananame"
					class="form-control"
					@change="inputCheck($event,'ZenkakuKana')"
					:placeholder="userInfoDefault.kananame">
				</div>
				<span class="errorMessage" style="color:red;margin-left:10px;width: auto;margin-top: 10px;"></span>
		</div>
		<div class="row mb-2">
			<label for="email" class="registercols col-sm-2 col-form-label">{{ itemNames.email }}<span style="color:red; padding:0;">【必須】</span></label>
			<div class="col-sm-10">
				<input
					type="email"
					id="email"
					name="email"
					v-model="userInfo.email"
					class="form-control"
					:placeholder="userInfoDefault.email">
			</div>
		</div>
		<div class="row mb-2">
			<label for="password" class="registercols col-sm-2 col-form-label">{{ itemNames.password }}<span style="color:red; padding:0;">【必須】</span></label>
			<div class="col-sm-10">
				<form>
					<div class="form-wrapper">
						<input
							v-bind:type="passwordType"
							id="password"
							v-model="userInfo.password"
							class="form-control"
							autocomplete="new-password"
							:placeholder="userInfoDefault.password">
						<span id="buttonEye" @click="pushHideButtons($event)" v-if="eyeFlg === true"><font-awesome-icon icon="fa-solid fa-eye" class="passwordEye" /></span>
						<span id="buttonEye_slash" @click="pushHideButtons($event)" v-else><font-awesome-icon icon="fa fa-eye-slash"  class="passwordEye" /></span>
					</div>
				</form>
			</div>
		</div>
		<div class="row mb-2">
			<label for="password_confirm" class="registercols col-sm-2 col-form-label">{{ itemNames.password_confirm }}</label>
			<div class="col-sm-10">
				<form>
					<div class="form-wrapper">
						<input
							v-bind:type="passwordConfirmType"
							id="password_confirm"
							v-model="userInfo.password_confirm"
							class="form-control"
							autocomplete="new-password"
							:placeholder="userInfoDefault.password_confirm">
						<span id="buttonEye_confirm" @click="pushHideButtons($event)" v-if="eyeConfirmFlg === true"><font-awesome-icon icon="fa-solid fa-eye" class="passwordEye" /></span>
						<span id="buttonEye_slash_confirm" @click="pushHideButtons($event)" v-else><font-awesome-icon icon="fa fa-eye-slash"  class="passwordEye" /></span>
					</div>
				</form>
			</div>
		</div>
	</div>
	<button @click="register" class="btn btn-primary">仮登録</button>
	<p id="message" class="message">{{ message }}</p>
</div>
</template>

<script>
// import axios from "axios";
import crypto from 'crypto-js';

export default {
	data(){
		return{
			message: "",
			itemNames: {
			loginname: "ユーザー名",
			kanjiname: "氏名 (漢字)",
			kananame: "氏名 (カタカナ)",
			email: "メールアドレス",
			password: "パスワード",	
			password_confirm: "パスワード（確認用）",
			category: "契約カテゴリー",
			},
			userInfo: {
			loginname: "",
			kanjiname: "",
			kananame: "",
			email: "",
			password: "",
			password_confirm: "",
			user_category: "1",
			},
			userInfoDefault: {
			loginname: "ログイン時に使用する名前を半角英数字でご入力ください",
			kanjiname: "申込される方の氏名を漢字でご入力ください",
			kananame: "申込される方の氏名をカタカナでご入力ください",
			email: "正しいメールアドレスをご入力ください",
			password: "任意の半角英数字のパスワードをご入力ください",
			password_confirm: "確認のため、パスワードをもう一度ご入力ください。",
			},
			eyeFlg:true,
			eyeConfirmFlg:true,
			passwordType:"password",
			passwordConfirmType:"password",
			NGlist:{},
			}
		},
	methods:{
		async register(){
			// console.log(this.userInfo.password)
			// console.log(this.userInfo.password_confirm)
			if(this.userInfo.password == this.userInfo.password_confirm){
				if(this.userInfo.loginname !== "" && this.userInfo.email !== "" && this.userInfo.password !== ""){
					// 漢字名、カナ名入力チェックNGがない場合
					if(Object.keys(this.NGlist).length == 0){
						var _this = this

						// let params = new FormData()
						let data = {'username': this.userInfo.loginname};
						if(this.userInfo.kanjiname == null || this.userInfo.kanjiname == ""){
							data['kanjiname'] = "-";
						}else{
							data['kanjiname'] = this.userInfo.kanjiname;
						}
						if(this.userInfo.kananame == null || this.userInfo.kananame == ""){
							data['kananame'] =  "-";
						}else{
							data['kananame'] =  this.userInfo.kananame;
						}
						data['email'] = this.userInfo.email;

						// パスワード暗号化
						const hash = crypto.SHA3(this.userInfo.password);
						data['password'] = hash.toString();

						// 選択されたユーザーカテゴリー取得
						// let elements = document.getElementsByName('user_category');
						// let len = elements.length;
						// let checkValue = '';
						// for (let i = 0; i < len; i++){
						// 	if (elements[i].checked){
						// 		checkValue = elements[i].value;
						// 	}
						// }
						data['user_category'] = this.userInfo.user_category;

						// // フォームデータの中身確認
						// for (let value of params.entries()) {
						//     console.log(value);
						// }
						_this.message = "登録中・・・"
						await this.$store.dispatch('register',data);
						setTimeout(() =>{
							console.log(_this.$store.getters.registerResult)
							if(_this.$store.getters.registerResult != 0 && _this.$store.getters.registerResult != -1 && parseInt(_this.$store.getters.registerResult)){
								_this.message = "仮登録完了しました。\nメールより本登録を完了してください。"
							}else if (_this.$store.getters.registerResult == 0){
								_this.message = "入力されたユーザーはすでに登録されています。"
							}else{
								_this.message = "仮登録に失敗しました。\n入力内容を再度ご確認してください。"
							}
						},1000);
					}else{
						this.message = "入力に誤りのある項目があります。"
					}
				}else{
					this.message = "仮登録に失敗しました。\n必須項目を入力してください。"
				}
			}else{
				this.message = "パスワードが一致していません。再度ご確認ください。"
			}
		},
		pushHideButtons(event) {
			var targetId = event.currentTarget.id
			if (targetId.indexOf('_confirm') != -1) {
				if(this.eyeConfirmFlg === true){
					this.eyeConfirmFlg = false
					this.passwordConfirmType = "text"
				}else{
					this.eyeConfirmFlg = true
					this.passwordConfirmType = "password"
				}
			} else {
				if(this.eyeFlg === true){
					this.eyeFlg = false
					this.passwordType = "text"
				}else{
					this.eyeFlg = true
					this.passwordType = "password"
				}
			}
		},
		inputCheck(event,inputcheck){
			var str = event.currentTarget.value;
			var eleID = event.currentTarget.id;
			var NGflg = false
			let pattern = ""
			if(str != ""){
				switch (inputcheck){
					case "Hankaku":
						pattern = /[A-Z|a-z|0-9]/;
						break;
					case "HankakuOmoji":
						pattern = /[A-Z|0-9]/;
						break;
					case "ZenkakuKanji":
						pattern = /([\s+\u{3005}\u{3007}\u{303b}\u{3400}-\u{9FFF}\u{F900}-\u{FAFF}\u{20000}-\u{2FFFF}][\u{E0100}-\u{E01EF}\u{FE00}-\u{FE02}]?)/mu;
						break;
					case "ZenkakuKana":
						pattern = /[\s+\u{301C}\u{30A1}-\u{30F6}\u{30FB}-\u{30FE}]/mu;
						break;
					case "yubinbango":
						pattern = /[0-9]/;
				}
			}
			var reg = new RegExp(pattern)
			var strArray = str.split("")
			for (let i=0;i<strArray.length;i++){
			if(!strArray[i].match(reg) && str != ""){
				NGflg = true
			}
			}
			if(!NGflg){
				// editedstr += strArray[i]
				console.log(eleID + "：入力問題なし")
				event.currentTarget.style.backgroundColor = null
				if(this.NGlist[eleID] >= 1){
					delete this.NGlist[eleID]
				}
				event.currentTarget.parentNode.parentNode.querySelector(".errorMessage").textContent = ""
			}else{
				event.currentTarget.style.backgroundColor = "red"
				this.NGlist[eleID] = 1
				event.currentTarget.parentNode.parentNode.querySelector(".errorMessage").textContent = "入力に誤りがあります。"
			}
		},
	}
}
</script>

<style>
#RegisterInfo .registercols {
	width:250px;
}
.form-wrapper {
	display: flex;
	justify-content: center;
	gap: 10px;
	margin: 10px 0;
}

.passwordEye {
	display: flex;
	align-items: center;
	width: 25px;
	height: 25px;
	cursor: pointer;
	margin-top:5px;
}
</style>