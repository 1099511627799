<template>
	<div ref="menu" class="main-menu" :class="{close: isClose}">
		<div class="close-button" @click="toggleMenu" :style="{ transform: `rotate( ${btnRotation} )` }">
			<font-awesome-icon icon="square-caret-left" />
		</div>
		<div class="menu-title">MENU</div>
		<ul class="nav">
			<li v-for="(item, i) of menuItems" :key="i" class="nav-item">
				<router-link :to="item.href" class="nav-link">
					<span class="list-icon"><font-awesome-icon :icon="item.icon" /></span>&nbsp;{{ item.name }}
				</router-link>
			</li>
		</ul>
		<div id="app_version">バージョン：{{ version }}</div>
	</div>
</template>

<script>
// import ChatCommunication from '../views/ChatCommunication.vue';
export default {
    props: {
        menuItems: Array,
    },
	// components:{ChatCommunication},
    data() {
        return {
            isClose: false,
            btnRotation: '0deg',
			backgroundMessage:"",
			version: "1.2.3",
        }
    },
    methods: {
        toggleMenu: function() {
            this.isClose = !this.isClose
            if( this.btnRotation === '0deg' ) {
                this.btnRotation = '180deg'
            } else {
                this.btnRotation = '0deg'
            }
        },
    },
	// mounted(){
	// 	const iframe = this.$refs.iframe
	// 	this.$nextTick(() => {
	// 		iframe.addEventListener('load', () => {
	// 		// iframe の読み込みが完了したら何か処理をする
	// 		// 例: iframe の中身を表示するためのフラグをセットする
	// 			console.log("iframe読み込み完了")
	// 		})
	// 	})
	// 	// console.log(iframe)
	// },
}
</script>

<style scoped>
.main-menu {
	position: relative;
	height: 100%;
	width: auto;
	padding: 0.75rem;
	background-color: #000000;
	border-radius: 1rem;
	box-shadow: -0.5rem -0.5rem 0.2rem rgb(255, 255, 255), 0.25rem 0.25rem 0.75rem rgba(0,0,0,0.5);
	transition: all 0.5s ease;
}
.main-menu.close {
    width: 2.7rem;
    overflow: hidden;
}
.main-menu.close ul.nav {
    width: max-content;
}

.main-menu div.menu-title {
	position: absolute;
	top: 0.75rem;
	left: 2.6rem;
	color: #ffffff;
}

.nav {
    flex-direction: column;
	font-size: 0.91rem;
}

.nav-item {
    margin-bottom: 0.5em;
	text-align: left;
}
.nav-item .list-icon {
    display: inline-block;
    width: 1.5em;
    margin-right: 0.24rem;
    text-align: center;
}

.nav-link {
    padding: 0;
	text-decoration: none;
	color: #fff;
}
.nav-link:focus {
    color: #fff;
}

.router-link-active.nav-link {
    border-bottom: solid 2px #0d6efd;
}

.close-button {
    display: flex;
    justify-content: center;
    align-content: center;
	width: 1.25rem;
	margin-bottom: 0.75rem;
	text-align: center;
	font-size: 1.25rem;
	transform: rotate(0deg);
	color: #ffffff;
	transition: all 0.3s ease;
}

#app_version{
	position: fixed;
    bottom: 25px;
	color: #fff;
}
</style>