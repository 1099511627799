<template>
    <div class="container">
		<h2>
			<font-awesome-icon icon="clipboard-user" />&nbsp;{{ title }}
			<span  data-bs-toggle="tooltip" data-bs-placement="top" title="再読み込み" style="float: right;margin-right: 50px;cursor: pointer;">
				<font-awesome-icon icon="fa-redo-alt" @click="reloadPage" />
			</span>
		</h2>
		<header class="mt-3 mb-4">
			<div class="row justify-content-center">
				<div class="col-lg-8 row">
					<p>DICOMイメージをサーバーへ送信する前にクライアントサイドで匿名化処理し、エスフィルクラウドサーバーへアップロードします。</p>
				</div>
				<div id = "message">{{ message }}</div>
				<div id = "message_json">{{ message_json }}</div>
				<div id = "message_send">{{ message_send }}</div>
				<div id = "message_send_error">{{ message_send_error }}</div>
				<div id = "message_store">{{ message_store }}</div>
				<div class="loading hide">
					<span class="circle"></span>
				</div>
			</div>
		</header>
        <div id="cfindCondition_studyZone">
            <p>DICOM情報の検索条件を入力してください。</p>
            <span>
                <div v-for="(item,index) in cfindCondition_study" v-bind:key="index" style="margin-bottom: 5px;">
                    <template v-if="item.type=='text'">
						<label v-bind:for="item.name">{{item.disp + ":"}}</label>
                        <input v-bind:name="item.name" v-model="item.value" v-bind:type="item.type">
                    </template>
                    <template v-else-if="item.type=='date'">
                        <span>
							<label v-bind:for="item.name">{{item.disp + ":"}}</label>
                            <input v-bind:name="item.name" v-model="item.value.start" v-bind:type="item.type">～
                            <input v-bind:name="item.name" v-model="item.value.end" v-bind:type="item.type">
                        </span>
                    </template>
                    <template v-else-if="item.type=='select'">
						<label v-bind:for="item.name">{{item.disp + ":"}}</label>
                        <select v-model="item.value">
                            <option v-for="(modality,key) in modalityList" v-bind:key="key" v-bind:value="modality">{{ modality }}</option>
                        </select>
                    </template>
                </div>
                <button class="btn btn-sm btn-info" style="margin: 0px 0px 5px 5px;" @click="cFindDICOM()">問い合わせ</button>
            </span>
        </div>
		<div id="InputZone" class="popup" ref="InputZone" :class="{'is-show':showFlg}">
			<span id="popup-inner_orderDetail">
				<div id="orderGroupInputZone" class="row justify-content-center border border-solid">
					<h2 style="color:black;">オーダーグループ情報入力欄</h2>
					<span>
						<label for="GroupName">グループ名：</label>
						<input name="GroupName" v-model="GroupName" style="border: 1px solid;">
					</span>
					<span>
						<label for="DoubleReadingMode">種別：</label>
						<select name="DoubleReadingMode" id="DoubleReadingMode" v-model="DoubleReadingMode" style="border: 1px solid;">
							<option value=0>1読影</option>
							<option value=3>２重読影（オープン,１レポート）</option>
							<option value=4>２重読影（ブラインド,１レポート）</option>
						</select>
					</span>
					<span>
						<label for="Anonymously">匿名：</label>
						<label style="margin-left:0px;"><input type="checkbox" name="Anonymously" v-model="Anonymously" id="Anonymously" value=1></label>
						<label for="PastImage" style="margin-left:25px;">過去画像：</label>
						<label style="margin-left:0px;"><input type="checkbox" name="PastImage" v-model="PastImage" id="PastImage"></label>
					</span>
					<span>
						<label for="GroupComment">伝言：</label>
						<textarea name="GroupComment" v-model="GroupComment" class="messageArea"  style="border: 1px solid;"></textarea>
					</span>
				</div>

				<div id="orderInputZone" class="row justify-content-center border border-solid">
					<h2 style="color:black;">オーダー情報入力欄</h2>
					<span>
						<label for="Applicant">作成者：</label>
						<input name="Applicant" v-model="Applicant"  style="border: 1px solid;">
					</span>
					<span>
						<label for="InterpretationRequests">読影要求：</label>
						<select name="InterpretationRequests" id="InterpretationRequests" v-model="InterpretationRequests" style="border: 1px solid;">
							<option value=0>通常</option>
							<option value=1>優先</option>
							<option value=2>緊急</option>
						</select>
						<label for="OutPatientHospital" style="margin-left:20px;">入院外来：</label>
						<select name="OutPatientHospital" id="OutPatientHospital" v-model="OutPatientHospital" style="border: 1px solid;">
							<option value=-1>なし</option>
							<option value=0>入院</option>
							<option value=1>外来</option>
							<option value=2>受託</option>
							<option value=3>健診</option>
						</select>
						<label for="Departments" style="margin-left:20px;">診療科：</label>
						<select name="Departments" id="Departments" v-model="Departments"  style="border: 1px solid;">
							<option value="整形外科">整形外科</option>
							<option value="内科">内科</option>
							<option value="外科">外科</option>
							<option value="健診科">健診科</option>
							<option value="放射線科">放射線科</option>
							<option value="その他">その他</option>
						</select>
					</span>
					<span>
						<label for="LedgerNumber">台帳番号：</label>
						<input name="LedgerNumber" v-model="LedgerNumber"  style="border: 1px solid;">
					</span>
					<span>
						<label for="EditedPatientName">患者名：</label>
						<input name="EditedPatientName" v-model="EditedPatientName"  style="border: 1px solid;">
					</span>
					<span>
						<label for="EditedPatientIdeogram">漢字：</label>
						<input name="EditedPatientIdeogram" v-model="EditedPatientIdeogram"  style="border: 1px solid;">
					</span>
					<span>
						<label for="EditedPatinePhonetic">フリガナ：</label>
						<input name="EditedPatinePhonetic" v-model="EditedPatinePhonetic"  style="border: 1px solid;">
					</span>
					<span>
						<label for="BodyPart">部位：</label>
						<input name="BodyPart" v-model="BodyPart" style="border: 1px solid;">
					</span>
					<br>
					<span>
						<label for="AttendingPhysician">主治医：</label>
						<input name="AttendingPhysician" v-model="AttendingPhysician"  style="border: 1px solid;">
					</span>
					<span>
						<label for="Protocol">プロトコル：</label>
						<input name="Protocol" v-model="Protocol"  style="border: 1px solid;">
					</span>
					<span>
						<label for="Operator">オペレータ：</label>
						<input name="Operator" v-model="Operator"  style="border: 1px solid;">
					</span>
					<span>
						<label for="ContrastAgent">造影剤：</label>
						<textarea name="ContrastAgent" v-model="ContrastAgent" class="messageArea" style="border: 1px solid;"></textarea>
					</span>
					<span>
						<label for="ClinicalInfo">臨床情報：</label>
						<textarea name="ClinicalInfo" v-model="ClinicalInfo" class="messageArea" style="border: 1px solid;"></textarea>
					</span>
					<span>
						<label for="StudyComments">検査伝言：</label>
						<textarea name="StudyComments" v-model="StudyComments" class="messageArea" style="border: 1px solid;"></textarea>
					</span>
					<span>
						<label for="OrderComments">依頼伝言：</label>
						<textarea name="OrderComments" v-model="OrderComments" class="messageArea" style="border: 1px solid;"></textarea>
					</span>
					<span>
						<label for="OrderDocument">伝票：</label>
						<div id="dropZone_OD" style="border: 1px solid; padding: 30px; height: 300px;">
							<p>伝票ファイルをドラッグ＆ドロップもしくは</p>
							<input type="file" name="OrderDocument" id="OrderDocument" accept="image/png, image/jpeg" @change="changeFile()">
							<button id = "filedelete" @click="fileDelete()">ファイル削除</button>
							<div id="preview"></div>
						</div>
					</span>
				</div>
				<span style="float:right;">
					<button class="btn btn-sm btn-info" id="orderStart" ref="orderStart" @click="orderStart()">オーダー</button>
					<button class="btn btn-sm btn-info" id="close_btn_orderDetail" ref="close_btn_orderDetail" style="margin-left: 10px;" @click="showFlg=false">キャンセル</button>
				</span>
			</span>
			<div class="black-background" id="black_bg_orderDetail" ref="black_bg_orderDetail" @click="showFlg=false"></div>
		</div>

		<div v-show="showGenre !== ''" id="overlay" @click.stop="showGenre=''">
			<div v-show="showGenre == 'orderProgress'" class="popup_orderprogress">
				<div @click="showGenre=''" style="float:right;">&#10006;</div>
				<div class="list-table mb-4 mt-5 progressZone">
					<table class="table table-striped table-bordered">
						<thead>
							<tr>
								<template v-for="(item,index) in studyitems_progress" v-bind:key="index">
									<th v-if="item.code!='progress'" v-bind:class="'px-2 py-1 align-middle ' + item.class" style="background-color: #D3D3D3;">
										{{ item.name }}
									</th>
									<th v-else ref="orderProgressHeader" class='px-2 py-1 align-middle sticky-x progressColumn' style="background-color: #D3D3D3;width:50px;">進捗</th>
								</template>
							</tr>
						</thead>
						<tbody ref="studyBody_progress">
							<tr v-for="( data, n ) in StudyProgressDataList" :key="n" class='studyRow mouse_hover'>
								<template v-for="(item,index) in studyitems_progress" v-bind:key="index">
									<td v-if="item.code=='progress'" class='col-sm-4 px-2 py-1 align-middle sticky-x progressColumn' style="background-color: #E6E6E6;">
										<progress :max="data[item.code].max" :value="data[item.code].value" class="progressBar" style="width:6rem;"></progress>
										<div class="progressMessage">{{ data[item.code].message }}</div>
									</td>
									<td v-if="item.code=='current_orderDate'">
										<span class="study" v-bind:data-dicom="item.code">{{ data[item.code] }}</span>
									</td>
									<td v-else v-bind:class="'col-sm-4 px-2 py-1 align-middle ' + item.class" >
										<template v-if="data[item.code] && data[item.code].Value">
											<template v-if="item.code=='00100010'">
												<div>
													<template v-if="typeof data[item.code].Value[0] == 'string'">
														<span class="study" v-bind:data-dicom="item.code" v-bind:studynumber="n+1">{{ data[item.code].Value[0] }}</span>
													</template>
													<template v-else-if="data[item.code].Value[0] == null">
														<span class="study" v-bind:data-dicom="item.code" v-bind:studynumber="n+1"></span>
													</template>
													<template v-else>
														<!-- Check if Alphabetic value exists -->
														<template v-if="data[item.code].Value[0].Alphabetic">
														<span class="study" v-bind:data-dicom="item.code" v-bind:studynumber="n+1">{{ data[item.code].Value[0].Alphabetic }}</span>
														</template>

														<!-- Check if Ideographic value exists -->
														<template v-if="data[item.code].Value[0].Ideographic">
														<span v-if="data[item.code].Value[0].Alphabetic">/</span>
														<span class="study" v-bind:data-dicom="item.code" v-bind:studynumber="n+1">{{ data[item.code].Value[0].Ideographic }}</span>
														</template>

														<!-- Check if Phonetic value exists -->
														<template v-if="data[item.code].Value[0].Phonetic">
														<span v-if="data[item.code].Value[0].Alphabetic || data[item.code].Value[0].Ideographic">/</span>
														<span class="study" v-bind:data-dicom="item.code" v-bind:studynumber="n+1">{{ data[item.code].Value[0].Phonetic }}</span>
														</template>

														<!-- Handle the case when none of the values exist -->
														<template v-if="!data[item.code].Value[0].Alphabetic && !data[item.code].Value[0].Ideographic && !data[item.code].Value[0].Phonetic">
														<span class="study">-</span>
														</template>
													</template>
												</div>
											</template>
											<template v-else-if="item.code=='ordered'">
												<template v-if="data[item.code].Value[0]">
													<font-awesome-icon icon="fa-solid fa-file-circle-check" style="color: #41D025;" />
												</template>
												<template v-else>
													<font-awesome-icon icon="fa-solid fa-file-circle-xmark" style="color: #BBBCBE;" />
												</template>
											</template>
											<template v-else>
												<span class="study" v-bind:data-dicom="item.code" v-bind:studynumber="n+1">{{ getCombinedString(data[item.code].Value) }}</span>
											</template>
										</template>
										<template v-else>
											<span class="study" v-bind:data-dicom="item.code" v-bind:studynumber="n+1"></span>
										</template>
									</td>
								</template>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<div class="row justify-content-center tableView">
			<span>
				<div class="text-end mt-3">
					<span style="float:left;margin-top:5px;">スタディ件数：{{ studycount }}</span>
					<button id="allUpload" class="allstudy btn btn-sm btn-info" style="display:none;" disabled="disabled" @click="select_storeData()" data-bs-toggle="tooltip" data-bs-placement="top" title="表示されている全ての検査を個別にオーダーします。">一括個別オーダー</button>
					<button id="concludeUpload" class="allstudy btn btn-sm btn-info" style="display:none;" disabled="disabled" @click="storeData('','conclude')" data-bs-toggle="tooltip" data-bs-placement="top" title="表示されている全ての検査を１つにまとめてオーダーします。">まとめオーダー</button>
					<button id="getAllImage" class="allstudy btn btn-sm btn-info" disabled="disabled" @click="sendImage('','conclude')" data-bs-toggle="tooltip" data-bs-placement="top" title="表示されている全ての検査をDICOMサーバーにまとめて画像送信します。">一括画像送信</button>
					<div id="progressShow" class='allstudy btn  btn-sm btn-info ProgressCheck' :class="{ 'ordering': ordering }" disabled="disabled" @click="showGenre='orderProgress'">進捗確認</div>
				</div>
			</span>

			<div class="list-table mb-4 studytableview" style="height:30vh;padding-right: 0px;padding-left: 0px;">
				<table id="studyTable" class="table table-striped table-bordered">
					<thead>
						<tr>
							<td class="px-2 py-1 align-middle checked-item cell-checked" style="background-color: #D3D3D3;">
								選択
								<div>
									<input type="checkbox" id="all-check" class="form-check-input" disabled="disabled" @change="allCheck()">
								</div>
							</td>
							<template v-for="(item,index) in studyitems" v-bind:key="index">
								<td v-bind:class="'px-2 py-1 align-middle ' + item.class" >
									{{ item.name }}
								</td>
							</template>
							<td class="px-2 py-1 align-middle sticky-x" style="background-color: #D3D3D3;">操作</td>
							<!-- <td class="px-2 py-1 align-middle sticky-x sticky-x2" style="background-color: #D3D3D3;">
								<div class="text-primary" role="status">オーダー</div>
							</td>
							<td id="imageOnly" class="px-2 py-1 align-middle sticky-x" style="background-color: #D3D3D3;">
								<div class="text-primary" role="status">画像送信</div>
							</td> -->
						</tr>
					</thead>
					<tbody>
						<tr v-for="( data, n ) in cfind_result_study" :key="n" class='studyRow mouse_hover' v-bind:studynumber="n+1">
							<!-- <td>
								<input type="checkbox" class="form-check-input selectOrders">
							</td> -->
							<td class="col-sm-4 px-2 py-1 align-middle checked-item cell-checked" :class="[selectedRow === n ? 'selected': 'normal']">
								<div class="form-check">
									<input type="checkbox" class="form-check-input single-check" v-bind:studynumber="n+1">
								</div>
							</td>
							<td class="col-sm-4 px-2 py-1 align-middle" :class="item.class + [selectedRow === n ? 'selected': 'normal']" v-for="(item,index) in studyitems" v-bind:key="index" @click="viewControl_series(n,data['0020000D'].Value[0])">
								<template v-if="data[item.code] && data[item.code].Value">
									<!-- <template v-if="data[item.code].Value[0].Alphabetic">
										<span class="study" v-bind:data-dicom="item.code" v-bind:studynumber="n+1">{{ data[item.code].Value[0].Alphabetic }}</span>
									</template> -->
									<template v-if="item.code=='00100010'">
										<div>
											<template v-if="typeof data[item.code].Value[0] == 'string'">
												<span class="study" v-bind:data-dicom="item.code" v-bind:studynumber="n+1">{{ data[item.code].Value[0] }}</span>
											</template>
											<template v-else-if="data[item.code].Value[0] == null">
												<span class="study" v-bind:data-dicom="item.code" v-bind:studynumber="n+1"></span>
											</template>
											<template v-else>
												<!-- Check if Alphabetic value exists -->
												<template v-if="data[item.code].Value[0].Alphabetic">
												<span class="study" v-bind:data-dicom="item.code" v-bind:studynumber="n+1">{{ data[item.code].Value[0].Alphabetic }}</span>
												</template>

												<!-- Check if Ideographic value exists -->
												<template v-if="data[item.code].Value[0].Ideographic">
												<span v-if="data[item.code].Value[0].Alphabetic">/</span>
												<span class="study" v-bind:data-dicom="item.code" v-bind:studynumber="n+1">{{ data[item.code].Value[0].Ideographic }}</span>
												</template>

												<!-- Check if Phonetic value exists -->
												<template v-if="data[item.code].Value[0].Phonetic">
												<span v-if="data[item.code].Value[0].Alphabetic || data[item.code].Value[0].Ideographic">/</span>
												<span class="study" v-bind:data-dicom="item.code" v-bind:studynumber="n+1">{{ data[item.code].Value[0].Phonetic }}</span>
												</template>

												<!-- Handle the case when none of the values exist -->
												<template v-if="!data[item.code].Value[0].Alphabetic && !data[item.code].Value[0].Ideographic && !data[item.code].Value[0].Phonetic">
												<span class="study">-</span>
												</template>
											</template>
										</div>
									</template>
									<template v-else-if="item.code=='ordered'">
										<template v-if="data[item.code].Value[0]">
											<font-awesome-icon icon="fa-solid fa-file-circle-check" style="color: #41D025;" />
										</template>
										<template v-else>
											<font-awesome-icon icon="fa-solid fa-file-circle-xmark" style="color: #BBBCBE;" />
										</template>
									</template>
									<template v-else>
										<span class="study" v-bind:data-dicom="item.code" v-bind:studynumber="n+1">{{ getCombinedString(data[item.code].Value) }}</span>
									</template>
                                </template>
                                <template v-else>
									<span class="study" v-bind:data-dicom="item.code" v-bind:studynumber="n+1"></span>
                                </template>
							</td>
							<td class="sticky-x normal" @click.stop="showFunction(n+1)" :style="{'text-align': 'center', 'z-index': clicknumber === n+1 ? 2 : 'auto'}">
								<font-awesome-icon icon="fa-solid fa-ellipsis-vertical" />
								<div class="btn-group-vertical orderlist-function-group" v-show="clicknumber === n+1" :style="{'position': 'absolute', 'right': '0px', 'z-index': clicknumber === n+1 ? 1 : 'auto', 'important': '1'}">
									<button v-for="(item, i) of olgButtonList" :key="i" type="button" class="btn btn-outline-secondary btn-sm" @click.stop="handle_function_call('study',item.action,$event)">{{ item.name }}</button>
								</div>
							</td>
							<!-- <td class="sticky-x sticky-x2" :class="[selectedRow === n ? 'selected': 'normal']">
								<button class="study orderButton btn btn-sm btn-info" style="width: max-content;" v-bind:studynumber="n+1" @click="editOrder($event,'study')">オーダー</button>
							</td>
							<td class="sticky-x" :class="[selectedRow === n ? 'selected': 'normal']">
								<button class="study btn btn-sm btn-info" style="width: max-content;" v-bind:studynumber="n+1" @click="sendImage($event,'study')">画像送信</button>
							</td> -->
						</tr>
					</tbody>
				</table>
			</div>


			<div class="list-table seriestableview" style="height:30vh">
				<table id="seriesTable" class="table table-striped table-bordered">
					<thead>
						<tr>
							<td v-bind:class="'px-2 py-1 align-middle ' + item.class" v-for="(item,index) in seriesitems" v-bind:key="index">
								{{ item.name }}
							</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="( data, n ) in cfind_result_series[current_study]" :key="n" class='seriesRow'>
							<td v-bind:class="'col-sm-4 px-2 py-1 align-middle ' + item.class" v-for="(item,index) in seriesitems" v-bind:key="index">
								<template v-if="data[item.code].Value">
									<span class="series" v-bind:data-dicom="item.code">{{ data[item.code].Value[0] }}</span>
								</template>
								<template v-else>
									<span class="series" v-bind:data-dicom="item.code"></span>
								</template>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>


<script>
/* eslint no-irregular-whitespace: ["error", {"skipRegExps": true}] */

	import axios from "axios";
	// import {API_IP, API_PORT} from "../../public/const.json"
	// import ce from "./checkExpired"
	import rd from "./readDicom"
	import { convertBytes } from 'dicom-character-set';


	export default {
		name: "DicomParserLocal",
		data() {
			var studyCollumns = [
				{ name: '依頼済', code: 'ordered', class:'' },
				{ name: '患者名', code: '00100010', class:'' },
				{ name: '患者ID', code: '00100020', class:'' },
				// { name: '生年月日', code: 'x00100030', class:'' },
				// { name: '年齢', code: 'x00101010', class:'' },
				// { name: '性別', code: 'x00100040', class:'' },
				{ name: '検査日付', code: '00080020', class:'' },
				{ name: '検査時間', code: '00080030', class:'' },
				{ name: '受付番号', code: '00080050', class:'' },
				{ name: '記述', code: '00081030', class:'' },
				{ name: 'モダリティ', code: '00080061', class:'' },
				// { name: '部位', code: 'x00180015', class:'' },
				{ name: 'シリーズ', code: 'seriescount', class:'' },
				{ name: '画像', code: 'imagecount_study', class:'' },
				{ name: 'Study Instance UID', code: '0020000D', class:'' }
			]
			return{
                title: '読影オーダー（ローカル)',
				studyitems: studyCollumns,
				olgButtonList: [
					{ name: '画像送信', action: "imageMoveStart" , class: "imageMoveStart" },
					{ name: '画像削除', action: "deleteImageConfirm" , class: "deleteImageConfirm" },
					{ name: 'オーダー', action: "editOrder" , class: "editOrder" },
					{ name: '閉じる', action: "closeFunction", class:"closeFunction" },
				],
				seriesitems: [
					// { name: 'モダリティ', code: 'x00080060', class:'' },
					{ name: 'シリーズ番号', code: '00200011', class:'' },
					// { name: '部位', code: 'x00180015', class:'' },
					// { name: '記述', code: 'x0008103e', class:'' },
					// { name: 'シリーズ日付', code: 'x00080021', class:'' },
					// { name: 'シリーズ時間', code: 'x00080031', class:'' },
					// { name: 'コメント', code: 'x00204000', class:'' },
					{ name: '画像', code: 'imagecount_series', class:'' },
					{ name: 'Series Instance UID', code: '0020000E', class:'' }
                ],
				studyitems_progress: [
					{ name: 'オーダー日時', code: 'current_orderDate', class:'' },
					...studyCollumns,
					{ name: '進捗', code: 'progress', class:'' },
                ],
				clicknumber: 0,
				message: "",
				message_json: "",
				message_send: "",
				message_send_error: "",
				message_store: "",
				message_progress_cf:"",
				message_progress_df:"",
				message_status_cf:"",
				message_status_df:"",
				progres_finish_flg: false,
				progressCount_total_cf:0,
				progressCount_now_cf:0,
				progressCount_total_df:0,
				progressCount_now_df:0,
				postcount:0,
				sendDCM_cmp_check: false,
				sendJson_cmp_check: false,
				storeData_cmp_check: false,
				Base64text: "",
				dcm_CmpCount:0,
				seriesnumber_study:0,
				read_check:false,
				targetNum_study:0,
				targetNum_series:0,
				postMax_study:0,
				postMax_series:0,
				postMax_image:0,
				storeList:[],
				intervalID_checkfile:"",
				intervalID_dumpfile:"",
				orderUID_res:"",
				GroupName: "",
				DoubleReadingMode: "",
				Anonymously: "",
				PastImage:false,
				GroupComment: "",
				Applicant: "",
				InterpretationRequests: "",
				OutPatientHospital: "",
				Departments: "",
				LedgerNumber: "",
				EditedPatientName: "",
				EditedPatientIdeogram: "",
				EditedPatinePhonetic: "",
				BodyPart: "",
				AttendingPhysician: "",
				Protocol: "",
				Operator: "",
				ContrastAgent: "",
				ClinicalInfo: "",
				StudyComments: "",
				OrderComments: "",
				OrderDocument: "",
				btnHide: true,
				TargetModality: "",
				errorConclude:{},
				ImagePreviewList:[],
				ImagePreviewList_old:[],
				wheelCountFlg:false,
				imageCreated_check:false,
				orderJson:"",
                cfindCondition_study:[
                    {name:"PatientID",disp:"患者ID",value:"",type:"text",ordername:"patientid"},
                    {name:"PatientName",disp:"患者名",value:"",type:"text",ordername:"EditedPatientName"},
                    {name:"AccessionNumber",disp:"受付番号",value:"",type:"text",ordername:"LedgerNumber"},
                    {name:"StudyDate",disp:"検査日付",value:{start:"",end:""},type:"date",ordername:"StudyDate"},
                    {name:"StudyTime",disp:"",value:"",type:"",ordername:""},
                    {name:"StudyInstanceUID",disp:"",value:"",type:"",ordername:""},
                    {name:"QueryRetrieveLevel",disp:"",value:"STUDY",type:"",ordername:""},
					{name:"StudyDescription",disp:"",value:"",type:"",ordername:""},
                    // {name:"ModalitiesInStudy",disp:"モダリティ",value:"",type:"select"},
                ],
				selectMaxNum_order:10000,
				resultList_order: {},
                modalityList:["","CR","CT","DR","ECG","ES","MG","MR","OT","PT","RF","SC","US","XA","OP"],
                cfind_result_study_tmp: [],
                cfind_result_series_tmp: {},
                cfind_result_image_tmp: {},
                cfind_result_study: [],
                cfind_result_series: {},
                cfind_result_image: {},
                current_study:"",
                selectedRow: null,
				ae_title: "",
				studycount:0,
				ProgressDomList:[],
				orderErrorCount:0,
				showGenre:"",
				StudyProgressDataList:[],
				ordering:false,
				showFlg:false,
				eventDom:"",
				genre:"",
				queue: [], // キュー
				queue_exe_check:false,
				nextEventDom: "",
			}
		},
		watch: {
			// cfind_result_study:{
			// 	handler(){
			// 		// DOM要素が作成されてから処理。最右列から２列目（オーダー列）をスクロール固定
			// 		this.$nextTick(() => {
			// 			let width = document.getElementById("imageOnly").offsetWidth
			// 			let elements = document.getElementsByClassName("sticky-x2");
			// 			for(let i = 0; i < elements.length; i++){
			// 				elements[i].style.right = width + "px"
			// 			}
			// 		});
			// 	}
			// }
		},
		methods: {
			reloadPage() {
				// thisを使ってwindow.location.reload()を呼び出す
				window.location.reload();
			},
			handle_function_call(genre,function_name,event) {
				var _this = this
				if(function_name != "closeFunction"){
					_this.closeFunction(event,genre);
				}
				_this[function_name](event,genre);
			},
			showFunction(num) {
				var _this = this
				console.log(num)
				_this.clicknumber = 0
				_this.clicknumber = num
				closePopUp(document.body);
				function closePopUp(elem) {
					if(!elem) return;
					elem.addEventListener('click', function() {
					_this.clicknumber = 0;
					})
				}
			},
			closeFunction(){
				var _this = this
				_this.clicknumber = 0
				// _this.clicknumber_order = 0
			},
			strEncode(SpecificCharacterSet,dataSet,byteArray){
				console.log("変換前：" + byteArray);
				console.log("予約語：" + SpecificCharacterSet)
				let patientNameStr = ""
				if(SpecificCharacterSet){
					let patientNameBytes = rd.sharedCopyFile(dataSet,byteArray)
					patientNameStr = convertBytes(SpecificCharacterSet.replace(/'\\'/g,'\\\\'),patientNameBytes, {vr: 'PN'});
					console.log("変換後：" + patientNameStr);
					// 文字列が漢字のみか半角カナのみか、全角カナのみかをチェック
					patientNameStr = this.strCheck(patientNameStr)
				}else{
					console.log("変換なし患者名---------")
					console.log(dataSet.string('x00100010'))
					console.log("---------------")
					// 予約語がない場合はイコール区切りの最初の文字列のみ抽出（大抵はローマ字氏名）
					patientNameStr = dataSet.string('x00100010').split("=")[0]
				}
				return patientNameStr
			},
			strCheck(str){
				let array = str.split("=")
				var str_edited = ""
				// 第二文字列が漢字のみかチェック
				let str_second = ""
				var regexp = /^([\u{3005}\u{3007}\u{303b}\u{3400}-\u{9FFF}\u{F900}-\u{FAFF}\u{20000}-\u{2FFFF}][\u{E0100}-\u{E01EF}\u{FE00}-\u{FE02}]?)+$/mu;
				if(array.length >= 2){
					let kanjiCheck = regexp.test(array[1].replace("^","").replace(/\s+/g,''))
					if(!kanjiCheck){
						str_second = ""
					}else{
						str_second = array[1]
					}
					str_edited = array[0] + "=" + str_second
				}else {
					str_edited = array[0]
				}

				let str_third = ""
				if(array.length == 3){
					console.log("チェック前第三文字列：" + array[2].replace("^","").replace(/\s+/g,''))
					// 第三文字列が全角カナ・半角カナのみかチェック
					regexp = new RegExp(/^[ァ-ヶー　]+$/);
					let ZenkakuKanaCheck = regexp.test(array[2].replace("^","").replace(/\s+/g,''))
					regexp = new RegExp(/^[ｦ-ﾟ]*$/);
					let HankakuKanaCheck = regexp.test(array[2].replace("^","").replace(/\s+/g,''))
					console.log(HankakuKanaCheck)
					if(!ZenkakuKanaCheck && !HankakuKanaCheck){
						str_third = ""
					}else{
						str_third = array[2]
					}
					str_edited = str_edited + "=" + str_third
				}
				console.log("チェック後：" + str_edited)
				return str_edited;
			},
			// オーダー情報入力欄を開く
			async editOrder(event, genre) {
				this.clearInputZone();
				this.showFlg = true
				this.eventDom = event;
				this.genre = genre
			},
			orderStart(){
				this.showFlg = false
				this.queue.push({ process: "storeData",nexteventDom:this.eventDom });
				this.executeQueue(this.genre);
			},
			imageMoveStart(event,genre){
				this.eventDom = event;
				this.genre = genre
				this.queue.push({ process: "sendImage",nexteventDom:this.eventDom });
				this.executeQueue(this.genre);
			},
			deleteImageConfirm(event,genre){
				this.eventDom = event;
				this.genre = genre
				let confirmResult = window.confirm('削除してよろしいですか？');
				if(!confirmResult){
					return false;
				}else{
					this.queue.push({ process: "deleteImage",nexteventDom:this.eventDom });
					this.executeQueue(this.genre);
				}
			},
			// Axiosの処理をキューから実行
			async executeQueue(genre) {
				if (this.queue.length > 0) {
					if(!this.queue_exe_check){
						console.log("残キュー：" + this.queue.length)
						this.queue_exe_check = true
						const { process,nexteventDom } = this.queue.shift();
						await this[process](nexteventDom, genre)
						this.queue_exe_check = false
						// 連続してオーダーが発生した場合、前のオーダーの完了を待って次のオーダーを処理
						this.executeQueue(genre)
					}
				}
			},
			// 一括オーダーするものを選定
			select_storeData(){
				let _this = this
				let targets = document.getElementById("studyTable").getElementsByClassName("orderButton")
				_this.storeData_cmp_check = true;
				for(let i=0;i<targets.length;i++){
					console.log("forターゲット：" + i)
					let check_storeData_cmp = function() {
						if (_this.storeData_cmp_check === true) {
							console.log("ターゲット：" + i)
							_this.storeData_cmp_check = false;
							targets[i].click()
							let timerId = setTimeout(check_storeData_cmp, 1000);
							clearTimeout(timerId)
						}else{
							setTimeout(check_storeData_cmp, 1000);
						}
					}
					check_storeData_cmp();
				}
			},
			getCurrentDateTime() {
				const now = new Date();

				const year = now.getFullYear();
				const month = this.padZero(now.getMonth() + 1); // 月は0から始まるため+1する
				const day = this.padZero(now.getDate());
				const hours = this.padZero(now.getHours());
				const minutes = this.padZero(now.getMinutes());
				const seconds = this.padZero(now.getSeconds());

				return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
			},
			padZero(num) {
				return num < 10 ? `0${num}` : num;
			},
			// 画像、JSONをサーバーにアップロード
			async storeData(event,item){
				document.getElementById('progressShow').disabled = false;
				this.ordering = true
				let orderJson = {
					OrderGroup:{
						Name:"",
						Order:{
							OrderDate:"",
							OrderTime:"",
							Anonymously:1,
							ReadingSystem:"EsPACS",
							ReadingMode:"EsPACS",
							DoubleReadingMode:0,
							OrderParent:"",
							OrderInstitution:"",
							OrderDepartment:"",
							ImageInfo:[],
							OrderInfo:{
								LedgerNumber:"",
								Applicant:"読影管理",
								InterpretationRequests:0,
								OutPatientHospital:0,
								Departments:"整形外科",
								AttendingPhysician:"佐々木",
								Protocol:"",
								Operator:"",
								ContrastAgent:"",
								ClinicalInfo:"臨床情報1",
								StudyComments:"",
								OrderDocument:{
									Format:"",
									Encode:"",
									Image:""
								},
								OrderComments:"",
								EditedPatientName:"",
								EditedPatientIdeogram:"",
								EditedPatinePhonetic:""
							}
						}
					}
				}
				this.orderJson = orderJson


				if (item == "study") {
					this.targetNum_study = event.target.closest("tr").getAttribute('studynumber');
					this.progressCount_total = parseInt(event.target.closest("tr").querySelector("span[data-dicom='imagecount_study']").textContent)
					console.log('スタディプログレス：' + this.progressCount_total)
				}else if(item == "series"){
					this.targetNum_study = event.target.closest("tr").getAttribute('studynumber');
					this.targetNum_series =  event.target.closest("tr").getAttribute('seriesnumber_study');
					this.progressCount_total = parseInt(event.target.closest("tr").querySelector("span[data-dicom='imagecount_series']").textContent)
					console.log('シリーズプログレス：' + this.progressCount_total)
				}
				console.log(localStorage);
				// _this.screenLock();
				// document.querySelector( '.loading' ).classList.remove( 'hide' )
				// this.message = "アップロード中・・・"
				console.log(localStorage);

				// _this.message = "";
				this.message_json = "";
				this.message_send = "";
				this.message_send_error = "";
				this.message_store = "";
				// _this.message_progress = ""
				this.postMax_study = 0
				this.postMax_series = 0
				this.postMax_image = 0
				// this.progressCount_now = 0

				// オーダーJson作成
				this.settingJson("","group",0);
				let target_studyIUID_list = []
				let imagecount_list = {}
				this.ProgressDomList = [];
				// 個別オーダーの場合
				if(item == "study"){
					let studyIUID = event.target.closest("tr").querySelector("span[data-dicom='" + "0020000D" +"']").textContent
					target_studyIUID_list.push(studyIUID)
					imagecount_list[studyIUID] = event.target.closest("tr").querySelector("span[data-dicom='imagecount_study']").textContent
					let copyRow = Object.assign({}, this.cfind_result_study[event.target.closest("tr").getAttribute("studynumber")-1]);
					this.StudyProgressDataList.push(copyRow)
					let currentDateTime = this.getCurrentDateTime();
					this.StudyProgressDataList[this.StudyProgressDataList.length - 1]["current_orderDate"] = currentDateTime
					this.StudyProgressDataList[this.StudyProgressDataList.length - 1]["progress"] = {"max":100,"value":0,"message":""}
					// current_orderDate プロパティに基づいて降順にソート
					this.StudyProgressDataList.sort((a, b) => new Date(b.current_orderDate) - new Date(a.current_orderDate));
					await this.$nextTick(); // 次のTickまで待つ
					this.ProgressDomList[0] = this.StudyProgressDataList[0]["progress"]
					this.ProgressDomList[0].max = imagecount_list[studyIUID] * 2
					this.ProgressDomList[0].message = Math.floor(this.ProgressDomList[0].value / this.ProgressDomList[0].max * 100) + "%完了"
				}
				// まとめオーダーの場合
				else if(item == "conclude"){
					for(let i=0; i < this.cfind_result_study.length; i++ ){
						let studyIUID = this.cfind_result_study[i]["0020000D"].Value[0]
						target_studyIUID_list.push(studyIUID)
						imagecount_list[studyIUID] = this.cfind_result_study[i]["imagecount_study"].Value[0]
						let copyRow = Object.assign({}, this.cfind_result_study[i]);
						this.StudyProgressDataList.push(copyRow)
						let currentDateTime = this.getCurrentDateTime();
						this.StudyProgressDataList[this.StudyProgressDataList.length - 1]["current_orderDate"] = currentDateTime
						this.StudyProgressDataList[this.StudyProgressDataList.length - 1]["progress"] = {"max":100,"value":0,"message":""}
						// current_orderDate プロパティに基づいて降順にソート
						this.StudyProgressDataList.sort((a, b) => new Date(b.current_orderDate) - new Date(a.current_orderDate));
						await this.$nextTick(); // 次のTickまで待つ
						this.ProgressDomList[i] = this.StudyProgressDataList[0]["progress"]
						this.ProgressDomList[i].max = imagecount_list[studyIUID] * 2
						this.ProgressDomList[i].message = Math.floor(this.ProgressDomList[i].value / this.ProgressDomList[i].max * 100) + "%完了"
						// 遅延を実現するPromiseを作成して待機
						await new Promise(resolve => setTimeout(resolve, 1000));
					}
				}
				// 画像（SOPIUID）ごとにループさせてjsonを編集
				for(let i=0; i < target_studyIUID_list.length; i++ ){
					let target_studyIUID = target_studyIUID_list[i]
					for(let key in this.cfind_result_image[target_studyIUID]){
						for( var k=0; k < this.cfind_result_image[target_studyIUID][key].length; k++ ){
							let index = this.cfind_result_study.findIndex(item => item["0020000D"].Value[0] === target_studyIUID);
							console.log("スタディリストインデックス番号：" + index)
							this.settingJson(this.cfind_result_study[index],"image",k,this.cfind_result_image[target_studyIUID][key][k]["00080018"].Value[0])
						}
					}
				}
				if(this.orderErrorCount == 0){
					// Json送信
					await this.sendJson()
				}
				if(this.orderErrorCount == 0){
					await this.storeDCM(target_studyIUID_list,imagecount_list,"")
				}
				this.ordering = false


				return;
			},
			async sendJson(){
				let _this = this;
				if(this.postcount_json == 0){
					console.log("Json表示--------------------")
					console.log(this.orderJson)
					console.log("----------------------------")
				}
				let data = {"orderJson":this.orderJson}
				const JsonPost_url = this.$store.state.API_HOST + '/send_json'
				this.postcount_json += 1;
				console.log("Json送信開始")
				await axios.post(
					JsonPost_url,
					data,
					{
						params: {
							'app_name': _this.$store.state.APP_NAME,
							'user_id': _this.$store.state.userID,
						},
						headers: {
							'accept': 'application/json',
							'Content-Type': 'application/json',
						},
						withCredentials: true
					}
				).then(
					function(response) {
						console.log(response)
						if(typeof(response.data) != "string"){
							if(response.data.res.message == "UPLOAD_json OK"){
								this.orderUID_res = response.data.res.orderuid
								this.ae_title = response.data.ae
								this.sendJson_cmp_check = true;
								for (let i=0;i < _this.ProgressDomList.length;i++){
									// 進捗25%追加
									this.ProgressDomList[i].value += _this.ProgressDomList[i].max / 2;
									this.ProgressDomList[i].message = Math.floor(_this.ProgressDomList[i].value / _this.ProgressDomList[i].max * 100) + "%完了"
								}
								console.log("Json送信完了")
							}else{
								this.orderErrorCount ++;
								for (let i=0;i < _this.ProgressDomList.length;i++){
									this.ProgressDomList[i].message = "エラー [14]";
									console.log("エラー [14]")
								}
							}
						}else{
							if(response.data != "NG"){
								_this.$store.dispatch('logout');
								_this.$store.commit('setLoginUser', '')
							}else{
								this.orderErrorCount ++;
								for (let i=0;i < _this.ProgressDomList.length;i++){
									this.ProgressDomList[i].message = "エラー [15]";
									console.log("エラー [15]")
								}
							}
						}
					}.bind(this)
				)
				.catch(
					function(error) {
						this.message_json = "Jsonを送信できませんでした。";
						this.result = "POSTエラー";
						console.log(error);
						console.log(error.response);
						console.log(this.result)
					}.bind(this)
				);
			},
			async storeDCM(studyList,imagecountList,status){
				let _this = this;
				console.log("ストア開始")
				// this.message_store = "DICOMストア中・・・"
				let cond = {"QueryRetrieveLevel":"STUDY","StudyInstanceUID":studyList}
				let data = {cond:cond,ae:this.ae_title,imagecountList:imagecountList,"PastImage":this.PastImage}
				const post_url = this.$store.state.SITEAPI_HOST + '/store_local'
				await axios.post(post_url, data)
				.then(
					async function(response) {
						console.log(response)
						if(response.data["result"] == "success"){
							if(status == "imageOnly"){
								for (let i=0;i < _this.ProgressDomList.length;i++){
									_this.ProgressDomList[i].value = _this.ProgressDomList[i].max
									// 進捗100%
									_this.ProgressDomList[i].message = "画像送信完了"
								}
								console.log("ストア終了")
							}else{
								if(this.orderErrorCount == 0){
									for (let i=0;i < _this.ProgressDomList.length;i++){
										// 進捗25%追加
										this.ProgressDomList[i].value += _this.ProgressDomList[i].max / 4;
										this.ProgressDomList[i].message = Math.floor(_this.ProgressDomList[i].value / _this.ProgressDomList[i].max * 100) + "%完了"
									}
									// 画像受付完了したオーダーのステータスを0から1に更新
									await _this.image_accepted(studyList)
								}
							}
						}else{
							this.orderErrorCount ++;
							// document.querySelector( '.loading' ).classList.add( 'hide' );
							for (let i=0;i < _this.ProgressDomList.length;i++){
								this.ProgressDomList[i].message = "エラー [16]";
								console.log("エラー [16]")
							}
						}
					}.bind(this)
				)
				.catch(
					function(error) {
						this.orderErrorCount ++;
						for (let i=0;i < _this.ProgressDomList.length;i++){
							this.ProgressDomList[i].message = "エラー [17]";
							console.log("エラー [17]")
						}
						this.result = "POSTエラー";
						// this.$refs.loadingImage.classList.add( 'hide' );
						console.log(error.response);
						console.log(this.result)
						console.log("ストア終了")
					}.bind(this)
				);
			},
			async image_accepted(studyList){
				let _this = this;
				var storeobj = {"orderuid":this.orderUID_res}
				const post_url = this.$store.state.API_HOST + '/image_accepted'
				await axios.post(
					post_url,
					storeobj,
					{
						params: {
							'app_name': _this.$store.state.APP_NAME,
							'user_id': _this.$store.state.userID,
						},
						headers: {
							'accept': 'application/json',
							'Content-Type': 'application/json',
						},
						withCredentials: true
					}
				).then(
					function(response) {
						console.log(response)
						if(typeof(response.data) != "string"){
							if(response.data.res == "success"){
								for (let i=0;i < _this.ProgressDomList.length;i++){
									_this.ProgressDomList[i].value = _this.ProgressDomList[i].max
									// 進捗100%
									_this.ProgressDomList[i].message = "オーダー完了"
								}
								for (let i=0;i < studyList.length;i++){
									// スタディテーブルの未依頼から依頼済みアイコンに変更
									let index = this.cfind_result_study.findIndex(item => item["0020000D"].Value[0] === studyList[i]);
									this.cfind_result_study[index]["ordered"].Value[0] = true;
									// 進捗テーブルの未依頼から依頼済みアイコンに変更
									let index_progress = this.StudyProgressDataList.findIndex(item => item["0020000D"].Value[0] === studyList[i]);
									this.StudyProgressDataList[index_progress]["ordered"].Value[0] = true;
								}
								this.storeList.splice(0)
								this.storeData_cmp_check = true
							}else{
								for (let i=0;i < _this.ProgressDomList.length;i++){
									this.ProgressDomList[i].message = "エラー [18]";
									console.log("エラー [18]")
								}
							}
							console.log("ストア終了")
						}else{
							_this.$store.dispatch('logout');
							_this.$store.commit('setLoginUser', '')
						}
					}.bind(this)
				)
				.catch(
					function(error) {
						for (let i=0;i < _this.ProgressDomList.length;i++){
							this.ProgressDomList[i].message = "エラー [19]";
							console.log("エラー [19]")
						}
						console.log(error);
						console.log(this.result)
						console.log("ストア終了")
					}.bind(this)
				);
			},
			// 画像をDICOMサーバーに送信
			async sendImage(event,item){
				this.ordering = true
				let _this = this
				console.log("画像送信開始")
				if (item == "study") {
					this.targetNum_study = event.target.closest("tr").getAttribute('studynumber');
					this.progressCount_total = parseInt(event.target.closest("tr").querySelector("span[data-dicom='imagecount_study']").textContent)
					console.log('スタディプログレス：' + this.progressCount_total)
				}else if(item == "series"){
					this.targetNum_study = event.target.closest("tr").getAttribute('studynumber');
					this.targetNum_series =  event.target.closest("tr").getAttribute('seriesnumber_study');
					this.progressCount_total = parseInt(event.target.closest("tr").querySelector("span[data-dicom='imagecount_series']").textContent)
					console.log('シリーズプログレス：' + this.progressCount_total)
				}
				console.log(localStorage);
				// _this.screenLock();
				// document.querySelector( '.loading' ).classList.remove( 'hide' )
				this.message = ""
				console.log(localStorage);

				// _this.message = "";
				this.message_json = "";
				this.message_send = "";
				this.message_send_error = "";
				this.message_store = "";
				// _this.message_progress = ""
				this.postMax_study = 0
				this.postMax_series = 0
				this.postMax_image = 0
				// this.progressCount_now = 0

				let target_studyIUID_list = []
				let imagecount_list = {}
				let checkedCount = 0
				this.orderErrorCount = 0

				this.ProgressDomList = []

				// 個別オーダーの場合
				if(item == "study"){
					let studyIUID = event.target.closest("tr").querySelector("span[data-dicom='" + "0020000D" +"']").textContent
					target_studyIUID_list.push(studyIUID)
					imagecount_list[studyIUID] = event.target.closest("tr").querySelector("span[data-dicom='imagecount_study']").textContent
					let copyRow = Object.assign({}, this.cfind_result_study[event.target.closest("tr").getAttribute("studynumber")-1]);
					this.StudyProgressDataList.push(copyRow)
					let currentDateTime = this.getCurrentDateTime();
					this.StudyProgressDataList[this.StudyProgressDataList.length - 1]["current_orderDate"] = currentDateTime
					this.StudyProgressDataList[this.StudyProgressDataList.length - 1]["progress"] = {"max":100,"value":0,"message":""}
					// current_orderDate プロパティに基づいて降順にソート
					this.StudyProgressDataList.sort((a, b) => new Date(b.current_orderDate) - new Date(a.current_orderDate));
					await _this.$nextTick(); // 次のTickまで待つ
					this.ProgressDomList[0] = this.StudyProgressDataList[0]["progress"]
					this.ProgressDomList[0].max = imagecount_list[studyIUID] * 2
					this.ProgressDomList[0].message = Math.floor(this.ProgressDomList[0].value / this.ProgressDomList[0].max * 100) + "%完了"
				}
				// まとめオーダーの場合
				else if(item == "conclude"){
					let checkList = document.getElementsByClassName("single-check")
					for(let i=0; i < checkList.length; i++ ){
						if(checkList[i].checked){
							let targetIndex = checkList[i].getAttribute("studynumber")-1
							let studyIUID = this.cfind_result_study[targetIndex]["0020000D"].Value[0]
							target_studyIUID_list.push(studyIUID)
							imagecount_list[studyIUID] = this.cfind_result_study[targetIndex]["imagecount_study"].Value[0]
							checkedCount += 1
							let copyRow = Object.assign({}, this.cfind_result_study[i]);
							this.StudyProgressDataList.push(copyRow)
							let currentDateTime = this.getCurrentDateTime();
							this.StudyProgressDataList[this.StudyProgressDataList.length - 1]["current_orderDate"] = currentDateTime
							this.StudyProgressDataList[this.StudyProgressDataList.length - 1]["progress"] = {"max":100,"value":0,"message":""}
							// current_orderDate プロパティに基づいて降順にソート
							this.StudyProgressDataList.sort((a, b) => new Date(b.current_orderDate) - new Date(a.current_orderDate));
							await _this.$nextTick(); // 次のTickまで待つ
							console.log("テスト！！！！！！！！！！！！！：" + _this.StudyProgressDataList[0]["00100020"].Value[0] + "：" + currentDateTime)
							_this.ProgressDomList[i] = _this.StudyProgressDataList[0]["progress"]
							_this.ProgressDomList[i].max = imagecount_list[studyIUID] * 2
							_this.ProgressDomList[i].message = Math.floor(_this.ProgressDomList[i].value / _this.ProgressDomList[i].max * 100) + "%完了"
							// 遅延を実現するPromiseを作成して待機
							await new Promise(resolve => setTimeout(resolve, 1000));
						}
					}
				}
				if(item == "conclude" && checkedCount == 0){
					alert("検査が選択されていません")
				}else{
					await this.getAE()
					if(this.orderErrorCount == 0){
						await this.storeDCM(target_studyIUID_list,imagecount_list,"imageOnly")
					}
					this.ordering = false
				}
				// document.querySelector( '.loading' ).classList.add( 'hide' );
				// document.getElementById("head").classList.remove("disabled");


				return;
			},
			async getAE(){
				let _this = this;
				const post_url = this.$store.state.API_HOST + '/get_ae'
				await axios.post(
					post_url,
					null,
					{
						params: {
							'app_name': _this.$store.state.APP_NAME,
							'user_id': _this.$store.state.userID,
						},
						headers: {
							'accept': 'application/json',
							'Content-Type': 'application/json',
						},
						withCredentials: true
					}
				).then(
					function(response) {
						console.log(response)
						if(typeof(response.data) != "string"){
							if(response.data.res == "success"){
								_this.ae_title = response.data.ae
								for (let i=0;i < _this.ProgressDomList.length;i++){
									// 進捗25%追加
									this.ProgressDomList[i].value += _this.ProgressDomList[i].max / 4;
									this.ProgressDomList[i].message = Math.floor(_this.ProgressDomList[i].value / _this.ProgressDomList[i].max * 100) + "%完了"
								}
							}else{
								this.orderErrorCount ++;
								// document.querySelector( '.loading' ).classList.add( 'hide' );
								for (let i=0;i < _this.ProgressDomList.length;i++){
									this.ProgressDomList[i].message = "エラー [20]";
									console.log("エラー [20]")
								}
							}
							console.log("ストア終了")
						}else{
							_this.$store.dispatch('logout');
							_this.$store.commit('setLoginUser', '')
						}
					}.bind(this)
				)
				.catch(
					function(error) {
						this.orderErrorCount ++;
						// document.querySelector( '.loading' ).classList.add( 'hide' );
						for (let i=0;i < _this.ProgressDomList.length;i++){
							this.ProgressDomList[i].message = "エラー [21]";
							console.log("エラー [21]")
						}
						console.log(error.response);
						console.log(this.result)
						console.log("ストア終了")
					}.bind(this)
				);
				document.getElementById("head").classList.remove("disabled");
			},
			async deleteImage(event){
				this.ordering = true
				let studyIUID = event.target.closest("tr").querySelector("span[data-dicom='" + "0020000D" +"']").textContent

				let copyRow = Object.assign({}, this.cfind_result_study[event.target.closest("tr").getAttribute("studynumber")-1]);
				this.StudyProgressDataList.push(copyRow)
				let currentDateTime = this.getCurrentDateTime();
				this.StudyProgressDataList[this.StudyProgressDataList.length - 1]["current_orderDate"] = currentDateTime
				this.StudyProgressDataList[this.StudyProgressDataList.length - 1]["progress"] = {"max":100,"value":0,"message":""}
				// current_orderDate プロパティに基づいて降順にソート
				this.StudyProgressDataList.sort((a, b) => new Date(b.current_orderDate) - new Date(a.current_orderDate));
				await this.$nextTick(); // 次のTickまで待つ
				this.ProgressDomList[0] = this.StudyProgressDataList[0]["progress"]
				// this.ProgressDomList[0].max = imagecount_list[studyIUID]
				this.ProgressDomList[0].message = Math.floor(this.ProgressDomList[0].value / this.ProgressDomList[0].max * 100) + "%完了"

				var _this = this
                // API送信のために{属性名1：:値,属性名2:値...}の形にする
                let cond = {"StudyInstanceUID":studyIUID}
                await axios
                    .post(_this.$store.state.SITEAPI_HOST + '/delete_data',cond)
                    .then(
                    async function(response) {
                        if(response.data.result == "success"){
							_this.ProgressDomList[0].value = _this.ProgressDomList[0].max
							// 進捗100%
							_this.ProgressDomList[0].message = "画像削除完了"
							this.ordering = false
                            await _this.cFindDICOM()
                        }else{
                            this.ProgressDomList[0].message = "エラー [22]";
                        }

                    }.bind(this)
                    )
                    .catch(
                    function(error) {
                        console.log(error)
                        this.ProgressDomList[0].message = "エラー [23]";
                    }.bind(this)
                    );
                return;
			},
			settingInputZone(){
				if(this.cfind_result_study.length > 0){
					var date = new Date();
					var editDate = date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2)
					var editTime = ('0' + date.getHours()).slice(-2) + ('0' + date.getMinutes()).slice(-2) + ('0' + date.getSeconds()).slice(-2)
					this.GroupName = localStorage.getItem('userID') + "-" + editDate
					this.DoubleReadingMode = 0
					this.Applicant = "読影管理"
					this.InterpretationRequests = 0
					this.OutPatientHospital = -1
					this.Departments = "整形外科"
					// 受付番号
					if(this.cfind_result_study[0]["00080050"].Value){
						this.LedgerNumber = this.cfind_result_study[0]["00080050"].Value[0];
					}else{
						this.LedgerNumber = (editDate + editTime)
					}
				}
				// 部位
				// if(this.dataSetList[0].string("x00080015")){
				// 	this.BodyPart = this.dataSetList[0].string("x00080015");
				// }else{
				// 	this.BodyPart = ""
				// }
			},
			clearInputZone(){
				this.GroupName = "";
				this.DoubleReadingMode = "";
				this.Anonymously = "";
				this.PastImage = false;
				this.GroupComment = "";
				this.Applicant = "";
				this.InterpretationRequests = "";
				this.OutPatientHospital = "";
				this.Departments = "";
				this.LedgerNumber = "";
				this.EditedPatientName = "";
				this.EditedPatientIdeogram = "";
				this.EditedPatinePhonetic = "";
				this.BodyPart = "";
				this.AttendingPhysician = "";
				this.Protocol = "";
				this.Operator = "";
				this.ContrastAgent = "";
				this.ClinicalInfo = "";
				this.StudyComments = "";
				this.OrderComments = "";

				this.settingInputZone();
				this.fileDelete();
			},
			settingJson(dataSet,item,num,sopUID){
				var date = new Date();
				var editDate = date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2)
				var editTime = ('0' + date.getHours()).slice(-2) + ('0' + date.getMinutes()).slice(-2) + ('0' + date.getSeconds()).slice(-2)
				switch (item){
					case 'group':
						console.log('オーダー日時：' + date.getFullYear() + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' +('0' + date.getDate()).slice(-2) + ' ' +  ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2));
						this.orderJson["OrderGroup"]["Name"] = this.GroupName
						this.orderJson["OrderGroup"]["Order"]["OrderDate"] = editDate
						this.orderJson["OrderGroup"]["Order"]["OrderTime"] = editTime
						this.orderJson["OrderGroup"]["Order"]["DoubleReadingMode"] = this.DoubleReadingMode
						if(document.getElementById("Anonymously").checked){
							this.orderJson["OrderGroup"]["Order"]["Anonymously"] = 1
						}else{
							this.orderJson["OrderGroup"]["Order"]["Anonymously"] = 0
						}
						// console.log(this.studylist)
						// console.log(orderJson)
						break;
					case 'image':
						var targetStudy = this.orderJson["OrderGroup"]["Order"]["ImageInfo"].find((v) => v["StudyInfo"]["StudyInstanceUID"] === dataSet["0020000D"].Value[0]);
						if (!targetStudy){
							var studyJson = {
									StudyInfo:{
										PatientID:"",
										PatientAE:"HOSP01",
										StudyInstanceUID:"",
										StudyModalities:"",
										StudyDate:0,
										SourceAETitle:"DICOMSRV",
										MoveImage:"yes",
										PastMoveImage:"yes",
										PastStudyFilter:{
											Modality:"",
											StudyDate:{
												From:"",
												To:""
											}
										},
										SOPInstanceUID:[]
									}
							}
							console.log("患者ID:" + dataSet["00100020"].Value[0])
							studyJson["StudyInfo"]["PatientID"] = dataSet["00100020"].Value[0];
							studyJson["StudyInfo"]["StudyInstanceUID"] = dataSet["0020000D"].Value[0];
							studyJson["StudyInfo"]["StudyModalities"] = dataSet["00080061"].Value[0];
							studyJson["StudyInfo"]["StudyDate"] = dataSet["00080020"].Value[0];
							// 過去画像設定
							if(this.PastImage){
								studyJson["StudyInfo"]["PastMoveImage"] = "yes";
							}else{
								studyJson["StudyInfo"]["PastMoveImage"] = "no";
							}
							studyJson["StudyInfo"]["PastStudyFilter"]["Modality"] = dataSet["00080061"].Value[0];
							studyJson["StudyInfo"]["PastStudyFilter"]["StudyDate"]["From"] = this.get1000DaysAgoDate(dataSet["00080020"].Value[0]);
							studyJson["StudyInfo"]["PastStudyFilter"]["StudyDate"]["To"] = dataSet["00080020"].Value[0];
							this.orderJson["OrderGroup"]["Order"]["ImageInfo"].push(studyJson);
						}
						var targetStudy_index = this.orderJson["OrderGroup"]["Order"]["ImageInfo"].findIndex((v) => v["StudyInfo"]["StudyInstanceUID"] === dataSet["0020000D"].Value[0]);
						this.orderJson["OrderGroup"]["Order"]["ImageInfo"][targetStudy_index]["StudyInfo"]["SOPInstanceUID"].push({"UID":sopUID});

						// console.log("レッジャー：" + dataSet.string("00080050"))
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["Applicant"] = this.Applicant
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["InterpretationRequests"] = this.InterpretationRequests
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["OutPatientHospital"] = this.OutPatientHospital
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["Departments"] = this.Departments

/*
////////////////////////////////////////////////////////////////////////////////////////////
						if(num == 0){
							if(dataSet["00080050"].Value[0]){
								this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["LedgerNumber"] = dataSet["00080050"].Value[0];
							}else{
								this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["LedgerNumber"] = (editDate + editTime)
							}
						}
////////////////////////////////////////////////////////////////////////////////////////////
*/

////////////////////////////////////////////////////////////////////////////////////////////
						if(num == 0){
							if(dataSet["00080050"].Value){
								if(dataSet["00080050"].Value[0]){
									this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["LedgerNumber"] = dataSet["00080050"].Value[0];
								}else{
									this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["LedgerNumber"] = (editDate + editTime)
								}
							}else{
								this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["LedgerNumber"] = (editDate + editTime)
							}
						}
/////////////////////////////////////////////////////////////////////////////////////////////


						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["EditedPatientName"] = this.EditedPatientName
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["EditedPatientIdeogram"] = this.EditedPatientIdeogram
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["EditedPatinePhonetic"] = this.EditedPatinePhonetic
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["AttendingPhysician"] = this.AttendingPhysician
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["Protocol"] = this.Protocol
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["Operator"] = this.Operator
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["ContrastAgent"] = this.ContrastAgent
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["ClinicalInfo"] = this.ClinicalInfo
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["StudyComments"] = this.StudyComments
						this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderComments"] = this.OrderComments

						// 伝票
						if(this.OrderDocument !== null){
							this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Encode"] = "Base64"

							var imagetype = this.OrderDocument.type
							if(imagetype == "image/jpeg"){
								this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Format"] = "JPEG"
							}else if(imagetype == "image/png"){
								this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Format"] = "PNG"
							}

							this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Image"] = this.Base64text
						}else{
							this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Encode"] = ""
							this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Format"] = ""
							this.orderJson["OrderGroup"]["Order"]["OrderInfo"]["OrderDocument"]["Image"] = ""
						}
						console.log("settingJson表示--------------------")
						console.log(this.orderJson)
						console.log("----------------------------")
						break;
				}
			},
			// 1000日前を算出
			get1000DaysAgoDate(inputDate) {
				// 入力された日付をDateオブジェクトに変換
				const dateObj = new Date(inputDate.substr(0, 4), inputDate.substr(4, 2) - 1, inputDate.substr(6, 2));

				// 1000日前の日付を計算
				const oneDayMilliseconds = 24 * 60 * 60 * 1000;
				const oneThousandDaysAgo = new Date(dateObj.getTime() - 1000 * oneDayMilliseconds);

				// 結果をyyyymmdd形式で返す
				const year = oneThousandDaysAgo.getFullYear();
				const month = String(oneThousandDaysAgo.getMonth() + 1).padStart(2, '0');
				const day = String(oneThousandDaysAgo.getDate()).padStart(2, '0');
				return `${year}${month}${day}`;
			},
			changeFile(){
				if(document.getElementById("OrderDocument").files[0] !== undefined){
					// 伝票をBase64文字列にエンコード
					var uploadImage = document.querySelector('#OrderDocument')
					var orderdocumentFile = uploadImage.files[0]
					var orderdocumentreader = new FileReader()
					orderdocumentreader.onload = (event) => {
						// Base64テキストをセット
						this.Base64text = event.currentTarget.result.split(',')[1]
					}
					orderdocumentreader.readAsDataURL(orderdocumentFile)
				}else{
					this.Base64text = ""
				}
			},
			allCheck(){
				let checkList = document.getElementsByClassName("single-check")
				for(let i=0;i < checkList.length;i++){
					if(document.getElementById("all-check").checked){
						checkList[i].checked = true
					}else{
						checkList[i].checked = false
					}
				}
			},
			// 伝票読込
			previewFile(file) {
				// プレビュー画像を追加する要素
				const preview = document.getElementById('preview');

				// FileReaderオブジェクトを作成
				const reader = new FileReader();

				// ファイルが読み込まれたときに実行する
				reader.onload = function (e) {
					const imageUrl = e.target.result; // 画像のURLはevent.target.resultで呼び出せる
					const img = document.createElement("img"); // img要素を作成
					img.src = imageUrl; // 画像のURLをimg要素にセット
					img.style.maxWidth = "260px"
					preview.textContent = ""; //一枚のみ有効とする
					preview.appendChild(img); // #previewの中に追加
				}

				// いざファイルを読み込む
				reader.readAsDataURL(file);
				if(document.getElementById("OrderDocument").files[0] !== undefined){
					this.OrderDocument = document.getElementById("OrderDocument").files[0]
					// 伝票をBase64文字列にエンコード
					var uploadImage = document.querySelector('#OrderDocument')
					var orderdocumentFile = uploadImage.files[0]
					var orderdocumentreader = new FileReader()
					orderdocumentreader.onload = (event) => {
						// Base64テキストをセット
						this.Base64text = event.currentTarget.result.split(',')[1]
					}
					orderdocumentreader.readAsDataURL(orderdocumentFile)
				}else{
					this.Base64text = ""
				}
			},
			// 伝票削除
			fileDelete(){
				const doc = document.getElementById('OrderDocument');
				const pre = document.getElementById('preview');
				doc.value = "";
				pre.textContent = "";
			},
			async cFindDICOM(){
				// document.querySelector( '.loading' ).classList.remove( 'hide' )
				const requests = [
					this.select_order(),
					this.cFindDICOM_study()
				];
				// 上記2つを並行処理し、2つの処理の完了を待機
				await Promise.all(requests);
				console.log("並行処理終了")
				if(this.cfind_result_study_tmp.length != 0){
					// オーダー済かどうかをチェックしてcfindで取得した配列に追加で格納
					for (let i=0;i < this.cfind_result_study_tmp.length;i++){
						let studyuid = this.cfind_result_study_tmp[i]["0020000D"].Value[0]
						const exists = this.resultList_order.some(item => {
							return item.studyuid.includes(studyuid);
						});
						let addValue = {}
						let addArray = []
						if(exists){
							addArray.push(true)
						}else{
							addArray.push(false)
						}
						addValue["Value"] = addArray
						this.cfind_result_study_tmp[i]["ordered"] = addValue
					}
					this.cfind_result_study = this.cfind_result_study_tmp
					this.cfind_result_series = this.cfind_result_series_tmp
					this.cfind_result_image = this.cfind_result_image_tmp
					this.message = ""
					// 入力欄に値を編集
					this.settingInputZone()
					document.getElementById('InputZone').style.display = "block";
					document.getElementById('allUpload').disabled = false;
					document.getElementById('concludeUpload').disabled = false;
					document.getElementById('getAllImage').disabled = false;
					document.getElementById('all-check').disabled = false;
					this.studycount = this.cfind_result_study.length;
				}else{
					this.message = "条件に一致する検査情報は見つかりませんでした。"
					this.studycount = 0
				}
				// document.querySelector( '.loading' ).classList.add( 'hide' );
			},
			async select_order(){
				var _this = this
				this.resultList_order = {}
				// API送信のために{属性名1：値,属性名2:値...}の形にする
                let cond = {}
                for (let i=0;i <_this.cfindCondition_study.length;i++){
                    let key = _this.cfindCondition_study[i]["ordername"]
					if(key != ""){
						let value = _this.cfindCondition_study[i]["value"]
						if(_this.cfindCondition_study[i]["type"] == "date"){
							if(value.start != "" ){
								cond[key + "_start"] = value.start.replace(/-/g,"")
							}
							if(value.end != "" ){
								cond[key + "_end"] = value.end.replace(/-/g,"")
							}
						}else{
							if(value != ""){
								cond[key] = value
							}
						}
					}
                }
				if(!Object.keys(cond).length){
					cond = null
				}
				console.log("オーダーコンディション")
				console.log(cond)
				await axios
				.post(_this.$store.state.API_HOST + '/select_orders/',
					cond,
					{
						params: {
							'app_name': _this.$store.state.APP_NAME,
							'max': _this.selectMaxNum_order,
							'id': _this.$store.state.userID,
						},
						headers: {
							'accept': 'application/json',
							'Content-Type': 'application/json',
						},
						withCredentials: true
					}
				)
				.then(
					function(response) {
					if(typeof(response.data) != "string"){
						// _this.ordercount = response.data.res.length
						_this.resultList_order = response.data.res
						console.log(_this.resultList_order);
					}else{
						_this.$store.dispatch('logout');
						_this.$store.commit('setLoginUser', '')
					}
					}.bind(this)
				)
				.catch(
					function(error) {
					this.result = "GETエラー";
					console.log(error);
					this.$store.dispatch('logout');
					this.$store.commit('setLoginUser', '')
					}.bind(this)
				);
				return;
			},
            // ローカルのdicomサーバーに検査情報問い合わせ
            async cFindDICOM_study(){
                var _this = this
                _this.message = "検査情報取得中・・・"
				_this.message_store = ""
                _this.current_study = ""
                _this.selectedRow = null
				_this.cfind_result_study = []
				_this.cfind_result_series = {}
				_this.cfind_result_image = {}
				_this.cfind_result_study_tmp = []
				_this.cfind_result_series_tmp = {}
				_this.cfind_result_image_tmp = {}
                // API送信のために{属性名1：値,属性名2:値...}の形にする
                let cond = {}
                for (let i=0;i <_this.cfindCondition_study.length;i++){
                    let key = _this.cfindCondition_study[i]["name"]
                    let value = _this.cfindCondition_study[i]["value"]
                    if(_this.cfindCondition_study[i]["type"] == "date"){
						if(value.start != "" || value.end != ""){
							cond[key] = value.start.replace(/-/g,"") + "-" + value.end.replace(/-/g,"")
						}else{
							cond[key] = ""
						}
                    }else{
                        if(value != ""){
							// cond[key] = value
							if(key == "QueryRetrieveLevel"){
								cond[key] = value
							}else{
								cond[key] = "*" + value + "*"
							}
                        }else{
                            cond[key] = ""
                        }
                    }
                }
                await axios
                    .post(_this.$store.state.SITEAPI_HOST + '/cfind_local',cond)
                    .then(
                    async function(response) {
                        if(response.data.result == "success"){
							if(response.data.dicomList.length > 0){
								// 同じスタディインスタンスUIDが複数取得されることがあるため、重複削除
								let uniqueList = []
								for(let i=0;i < response.data.dicomList.length;i++){
									// 特定の値を持つ要素があるかどうかを判断
									const hasSpecificValue = uniqueList.some(item => {
										return item["0020000D"].Value[0].includes(response.data.dicomList[i]["0020000D"].Value[0]);
									});
									if(!hasSpecificValue){
										uniqueList.push(response.data.dicomList[i])
									}
								}
								_this.cfind_result_study_tmp = uniqueList
								// シリーズ情報取得
								for(let i=0;i < _this.cfind_result_study_tmp.length;i++){
									// cfind_result_study_tmp 形式 ↓
									// [stuid1,stuid2,stuid3...]
									let stuid = _this.cfind_result_study_tmp[i]["0020000D"].Value[0]
									await _this.cFindDICOM_series(stuid,i)
								}
								// console.log(_this.cfind_result_study_tmp)
								// console.log(_this.cfind_result_series_tmp)
								// console.log(_this.cfind_result_image_tmp)
								// _this.cfind_result_study = _this.cfind_result_study_tmp
								// _this.cfind_result_series = _this.cfind_result_series_tmp
								// _this.cfind_result_image = _this.cfind_result_image_tmp
								// _this.message = ""
								// // 入力欄に値を編集
								// _this.settingInputZone()
								// document.getElementById('InputZone').style.display = "block";
								// document.getElementById('allUpload').disabled = false;
								// document.getElementById('concludeUpload').disabled = false;
								// document.getElementById('getAllImage').disabled = false;
								// document.getElementById('all-check').disabled = false;
								// _this.studycount = _this.cfind_result_study.length;
								// let width = document.getElementById("imageOnly").offsetWidth
								// let elements = document.getElementsByClassName("sticky-x2");
								// for(let i = 0; i < elements.length; i++){
								// 	elements[i].style.right = width + "px"
								// }
							}else{
								_this.studycount = 0;
							}
                        }else{
                            _this.message = "c-find failed"
                        }

                    }.bind(this)
                    )
                    .catch(
						function(error) {
							if (error.response) {
								// サーバーからのエラーレスポンス
								console.error('サーバーエラー:', error.response.data);
							} else if (error.request) {
								// サーバーにリクエストが送信されなかった場合
								console.error('ネットワークエラー:', error.request);
								// ネットワークエラーが発生したらアラートを表示する
								alert('EsMoveが動作していません');
							} else {
								// リクエストを設定する際のエラー
								console.error('リクエスト設定エラー:', error.message);
							}
							this.message = error;
						}.bind(this)
                    );
                return;
            },
            // ローカルのdicomサーバーに検査情報問い合わせ
            async cFindDICOM_series(stuid,studyIndex){
                var _this = this
                // API送信のために{属性名1：:値,属性名2:値...}の形にする
                let cond = {"QueryRetrieveLevel":"SERIES","StudyInstanceUID":stuid,"SeriesInstanceUID":"","StudyDate":"","PatientID":"","SeriesNumber":""}
                await axios
                    .post(_this.$store.state.SITEAPI_HOST + '/cfind_local',cond)
                    .then(
                    async function(response) {
                        if(response.data.result == "success"){
                            // 同じスタディインスタンスUIDが複数取得されることがあるため、重複削除
                            let uniqueList = []
                            for(let i=0;i < response.data.dicomList.length;i++){
                                // 特定の値を持つ要素があるかどうかを判断
                                const hasSpecificValue = uniqueList.some(item => {
                                    return item["0020000E"].Value[0].includes(response.data.dicomList[i]["0020000E"].Value[0]);
                                });
                                if(!hasSpecificValue){
                                    uniqueList.push(response.data.dicomList[i])
                                }
                            }
                            _this.cfind_result_series_tmp[stuid] = uniqueList
                            let addValue = {}
                            let addArray = []
                            addArray.push(uniqueList.length)
                            addValue["Value"] = addArray
                            // if(_this.cfind_result_study_tmp[studyIndex]["seriescount"]){
                            //     _this.cfind_result_study_tmp[studyIndex]["seriescount"].Value[0] += uniqueList.length
                            // }else{
                            //     _this.cfind_result_study_tmp[studyIndex]["seriescount"] = addValue
                            // }
                            _this.cfind_result_study_tmp[studyIndex]["seriescount"] = addValue
                            for(let i=0;i < uniqueList.length;i++){
                                // cfind_result_series_tmp 形式 ↓
                                // {
                                //     stuid1:[seuid1,seuid2],
                                //     stuid2:[seuid1,seuid2]
                                // }
                                let seuid = _this.cfind_result_series_tmp[stuid][i]["0020000E"].Value[0]
                                await _this.cFindDICOM_image(stuid,studyIndex,seuid,i)
                            }
                        }else{
                            _this.message = "c-find failed"
                        }

                    }.bind(this)
                    )
                    .catch(
                    function(error) {
                        console.log(error)
                        this.message = error;
                    }.bind(this)
                    );
                return;
            },
            // ローカルのdicomサーバーに検査情報問い合わせ
            async cFindDICOM_image(stuid,studyIndex,seuid,seriesIndex){
                var _this = this
                // API送信のために{属性名1：:値,属性名2:値...}の形にする
                let cond = {"QueryRetrieveLevel":"IMAGE","StudyInstanceUID":stuid,"SeriesInstanceUID":seuid,"StudyDate":"","PatientID":"","SOPInstanceUID":""}
                await axios
                    .post(_this.$store.state.SITEAPI_HOST + '/cfind_local',cond)
                    .then(
                    function(response) {
                        if(response.data.result == "success"){
                            // 同じスタディインスタンスUIDが複数取得されることがあるため、重複削除
                            let uniqueList = []
                            for(let i=0;i < response.data.dicomList.length;i++){
                                // 特定の値を持つ要素があるかどうかを判断
                                const hasSpecificValue = uniqueList.some(item => {
                                    return item["00080018"].Value[0].includes(response.data.dicomList[i]["00080018"].Value[0]);
                                });
                                if(!hasSpecificValue){
                                    uniqueList.push(response.data.dicomList[i])
                                }
                            }
                            let addSeries = {}
                            addSeries[seuid] = uniqueList
                            if(_this.cfind_result_image_tmp[stuid]){
                                _this.cfind_result_image_tmp[stuid][seuid] = uniqueList
                            }else{
                                _this.cfind_result_image_tmp[stuid] = addSeries
                            }

							let addValue_series = {}
							let addArray_series = []
							addArray_series.push(uniqueList.length)
							addValue_series["Value"] = addArray_series
							_this.cfind_result_series_tmp[stuid][seriesIndex]["imagecount_series"] = addValue_series
                            if(_this.cfind_result_study_tmp[studyIndex]["imagecount_study"]){
                                _this.cfind_result_study_tmp[studyIndex]["imagecount_study"].Value[0] += uniqueList.length
                                // _this.cfind_result_series_tmp[studyIndex][seriesIndex]["imagecount_series"].Value[0] = _this.cfind_result_image_tmp.length
                            }else{
								let addValue_study = {}
								let addArray_study = []
								addArray_study.push(uniqueList.length)
								addValue_study["Value"] = addArray_study
                                _this.cfind_result_study_tmp[studyIndex]["imagecount_study"] = addValue_study
                            }
                            // cfind_result_image_tmp 形式 ↓
                            // {
                            //     stuid1:{
                            //         seuid1:
                            //             [sop情報1,sop情報2],
                            //         seuid2:
                            //             [sop情報1,sop情報2]
                            //     },
                            //     stuid2:{
                            //         seuid1:
                            //             [sop情報1,sop情報2],
                            //         seuid2:
                            //             [sop情報1,sop情報2]
                            //     }
                            // }
                        }else{
                            _this.message = "c-find failed"
                        }

                    }.bind(this)
                    )
                    .catch(
                    function(error) {
                        console.log(error)
                        this.message = error;
                    }.bind(this)
                    );
                return;
            },
            viewControl_series(index,stuid){
                this.current_study = stuid
                this.selectedRow = index
            },
			// モダリティは複数の値が返ってくることがあるため、こちらでカンマ区切りにする。
			getCombinedString(items) {
				if (items.length === 1) {
					return items[0];
				} else {
					return items.join(',');
				}
			}
		},
		mounted: function(){
			var _this = this;
			// // DICOMファイル読み込み処理
			// var dropZone = document.getElementById('dropZone');
			// dropZone.addEventListener('dragover', this.handleDragOver, false);
			// dropZone.addEventListener('drop', this.handleFileSelect, false);

			// 伝票ファイル読み込み処理
			const fileInput = document.getElementById('OrderDocument');
			var dropZone_OD = document.getElementById('dropZone_OD');
			dropZone_OD.addEventListener('dragover', function(e) {
				e.stopPropagation();
				e.preventDefault();
				this.style.background = '#e1e7f0';
			}, false);

			dropZone_OD.addEventListener('dragleave', function(e) {
				e.stopPropagation();
				e.preventDefault();
				this.style.background = '#ffffff';
			}, false);
			const HandleOrderDocumentSelect = () => {
				const files = fileInput.files;
				for (let i = 0; i < files.length; i++) {
					_this.previewFile(files[i]);
				}
				fileInput.addEventListener('change', HandleOrderDocumentSelect);
			}
			HandleOrderDocumentSelect();

			dropZone_OD.addEventListener('drop', function(e) {
				e.stopPropagation();
				e.preventDefault();
				this.style.background = '#ffffff'; //背景色を白に戻す
				var files = e.dataTransfer.files; //ドロップしたファイルを取得
				if (files.length > 1) return alert('アップロードできるファイルは1つだけです。');
				fileInput.files = files; //inputのvalueをドラッグしたファイルに置き換える。
				_this.previewFile(files[0]);
			}, false);

		},
	}
</script>

<style>
	table, td, th{
		border: 1px solid black;
	}
	table {
		border-collapse: collapse;
	}
	thead {
		background-color: lightgray;
	}
	tbody {
		cursor: pointer;
		cursor: hand;
	}
	.mouse_hover:hover {
		background-color: rgb(118, 183, 239);
	}
	#message_json,#message_send,#message_send_error, #message_store {
		color:red
	}
	.allstudy {
		padding-top: 5px;
		padding-bottom: 5px;
		margin-right: 10px;
		margin-bottom: 10px;
	}
	.loading {
		margin: 0;
		padding: 0;
	}
	.loading.hide {
		opacity: 0;
		pointer-events: none;
		transition: opacity 500ms;
	}
	.loading .circle {
		display: block;
		position: relative;
		top: calc( 50% - 20px );
		width: 25px;
		height: 25px;
		margin: 0 auto;
		border: 7px solid #444444;
		border-top: 7px solid #5ae1e5;
		border-radius: 50px;
		animation: loading 700ms linear 0ms infinite normal both;
	}
	@keyframes loading {
		0% { transform: rotate( 0deg ); }
		100% { transform: rotate( 360deg ); }
	}
	.disabled {
		pointer-events: none;
		opacity: 0.5;
	}
	.border {
		padding: 10px;
		margin: 20px;
	}
	.border-solid {
		border: 2px solid #090201;
	}
	#orderGroupInputZone > label, #orderInputZone > label {
		margin-left: 25px;
	}

    #cfindCondition_studyZone {
        width: 95%;
        border: 2px solid #000000;
    }


	details {
		border: 1px solid #ccc;
	}
	details:not(:last-child) {
		margin-bottom: 20px;
	}

	/**
	* list-style: none; ←デフォルト三角削除（Chrome非対応）
	* cursor: pointer; ←カーソルをポインターに
	**/
	details summary {
		list-style: none;
		cursor: pointer;
		padding: 20px;
		background: #e4e4e4;
		color: #242323;
		font-size: 1rem;
		font-weight: bold;
		/* width: 50%; */
	}

	details span {
		margin: 0;
		padding: 20px;
	}
	#InputZone textarea{
		vertical-align:top;
	}

	.list-table {
		overflow: auto;
	}
	.list-table #studyTable, .list-table #seriesTable {
		width: max-content;
	}
	.hide {
		display: none !important;
	}
	.imagelist{
		display: flex;
	}
	.show {
		opacity: 1;
	}
	.showhide {
		opacity: 0;
	}
	.show_onetime {
		display: block !important;
	}
    .selected {
        background-color: rgb(118, 183, 239) !important;
        color: white;
    }
	tr:nth-of-type(odd) .normal{
		background-color: #eee;
	}
	tr:nth-of-type(even) .normal{
		background-color:#f2f2f2;
	}
	#overlay {
        position: absolute;
        /* display: none; */
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.8);
        z-index: 5;
    }

    .popup_progress {
        position: absolute;
        width: 30%;
        height: 30%;
        top: 25%;
        left: 25%;
        text-align: center;
        background: white;
    }
    .popup_progress h3 {
        font-size: 15px;
        height: 50px;
        line-height: 50px;
        background: white;
    }

	.tableView .studytableview,.table .seriestableview{
		height: 30vh;
		overflow: auto;
		width: 75vw;
		margin-right: auto;
		margin-left: 15px;
	}

	.studytableview td,.seriestableview td{
		white-space: nowrap;
	}

	.tableView thead{
		position: sticky;
		top: 0;
		left: 0;
		z-index:3
	}

	.sticky-x{
		position: sticky;
		top: 0;
		right: 0;
	}

	.messageArea{
		width:80%;
	}

	.ProgressCheck{
		/* width: 150px;
		height: 50px; */
		background: #aaa;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	.ordering{
		animation: flash 4s ease infinite;
	}

	@keyframes flash {
		0%,100% {
			opacity: 1;
		}
		50% {
			opacity: 0.25;
		}
	}

	.progressZone {
		max-height: 70%;
	}
	.progressZone th {
		position: sticky;
		top: 0;
		left: 0;
		z-index: 3;
	}
</style>
