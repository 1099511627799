import { createStore } from "vuex";
import axios from '../axios-auth';
import router from '../router';
// import {API_IP, API_PORT} from "../../public/const.json"
import createPersistedState from 'vuex-persistedstate'
import ChatManage from '../views/mixins/ChatManage.js'
import store from "../store/index.js"
// import { use } from 'vue/types/umd';


const getDefaultState = () => {
  return {
    playlists: [],
    videos: [],
    user: null,
    search: null,
  }
}

export default createStore({
  mixins: [ChatManage],
  state:{
    API_HOST:"",
    SITEAPI_HOST:"",
    APP_NAME:"",
    accessToken: null,
    registerResult: null,
    verifyResult: {},
    userID:0,
    loginUser: '',
    getDefaultState,
  },
  getters: {
    API_HOST: state => state.API_HOST,
    SITEAPI_HOST: state => state.SITEAPI_HOST,
    APP_NAME: state => state.APP_NAME,
    accessToken: state => state.accessToken,
    registerResult: state => state.registerResult,
    verifyResult: state => state.verifyResult,
    userID: state => state.userID,
    sysAdmin: state => state.sysAdmin
  },
  plugins: [createPersistedState(
    { // ストレージのキーを指定
      key: 'Vuex',
      // paths: ['accessToken'],
      // ストレージの種類を指定
      storage: window.sessionStorage
    }
  )],
  mutations:{
    updateaccessToken(state, accessToken){
      state.accessToken = accessToken;
    },
    updateuserID(state, userID){
      state.userID = userID;
    },
    registerResponse(state, registerResult){
      state.registerResult = registerResult;
    },
    verifyResponse(state, verifyResult){
      state.verifyResult = verifyResult;
    },
    setLoginUser (state, name) {
      state.loginUser = name
    },
    setAPI_HOST(state, API_HOST) {
      state.API_HOST = API_HOST
    },
    setSITEAPI_HOST(state, SITEAPI_HOST) {
      state.SITEAPI_HOST = SITEAPI_HOST
    },
    setAPP_NAME(state, APP_NAME) {
      state.APP_NAME = APP_NAME
    },
    reset(state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async loadJSONData({commit}) {
      try {
        commit('updateuserID', 0);
        const response = await fetch('/const.json');
        const data = await response.json()
        console.log(data)
        let API = data.API_IP + ":" + data.API_PORT
        commit('setAPI_HOST', API);
        let SITEAPI = data.SITEAPI_IP + ":" + data.SITEAPI_PORT
        commit('setSITEAPI_HOST', SITEAPI);
        let APP_NAME = data.APP_NAME
        commit('setAPP_NAME', APP_NAME);
        return;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async login({commit,dispatch}, authData){
      // let _this = this
      // axios.defaults.withCredentials = true
      await axios.post(
        store.state.API_HOST + '/login',
        authData,
        {
          //AxiosRequestConfig parameter
          withCredentials: true //correct
        }
      ).then(response=>{
        console.log(response)
        if (response.data.user_id != 0){
          console.log("ログイン成功")
          commit('updateuserID', response.data.user_id);
          dispatch('setAuthData', {
            user_id:response.data.user_id,
            sysAdmin:response.data.sysAdmin,
            domain:response.data.domain,
          });
          // let data = {
          //   user_id:response.data.user_id,
          //   sysAdmin:response.data.sysAdmin
          // };
          // setAuthData(data)
          // router.push({name:"chat",query:{status:"NoStart"}})
          router.push('/home');
          console.log("ホームへ移動")
          return response.data
        }else{
          return 0;
        }
      }).catch(error => {
        console.log("ログイン失敗")
        console.log(error)
        return -1;
      });
    },
    // 引数は手動ログアウトの場合のみ使用
    async logout({commit},genre){
      // let _this = this

      // let formData = new FormData()
      await axios.post(
        store.state.API_HOST + '/logout',
        null, // nullまたは空オブジェクトを送信
        {
          params: {
            app_name: store.state.APP_NAME,
            id: store.state.userID,
          },
          headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
        ).then(response=>{
          // httponly属性がtrueになっている場合はcookieはjsからは削除できない
          // document.cookie = "session_id=;domain=.esfill.co.jp;path=/; max-age=0";
          if(genre !== 'ManualLogout'){
            alert("セッションの有効期限が切れています。再度ログインを行なってください。")
          }
          console.log("ログアウト成功")
          console.log(response)
          commit('updateuserID', 0);
          return;
        }).catch(error => {
          console.log("ログアウト失敗")
          console.log(error)
          return;
        });

      localStorage.removeItem('accessToken');
      localStorage.removeItem('expiryTimeMs');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('userID');
      localStorage.removeItem('ChatToken');
      localStorage.removeItem('ChatUserID');
      localStorage.removeItem('domain');

      commit('reset')

      router.replace('/');
    },
    async register({commit},authData,config_token){
      console.log(authData.toString())
      await axios.post(
        store.state.API_HOST + '/create_users',
        authData,
        config_token
        ).then(response=>{
          commit('registerResponse', response.data["id"]);
        }).catch(error => {
          commit('registerResponse', error);
        });
    },
    async verify({commit},authData,config_token){
      console.log(authData.toString())
      await axios.post(
        store.state.API_HOST + '/verifyemail',
        authData,
        config_token
        ).then(response=>{
          commit('verifyResponse', response.data);
        }).catch(error => {
          commit('verifyResponse', error);
        });
    },
    async update({commit},userdata){
      await axios.post(
        store.state.API_HOST + '/update_users',
        userdata,
        {
          params: {
              'app_name': store.state.APP_NAME,
              'user_id': store.state.userID,
          },
          headers: {
              'accept': 'application/json',
              'Content-Type': 'application/json',
          },
          withCredentials: true
        }
      ).then(response=>{
          console.log(response)
          if(typeof(response.data) != "string"){
            commit('registerResponse', response.data.res["id"]);
          }else{
            store.dispatch('logout');
            store.commit('setLoginUser', '')
          }
      }).catch(error => {
        commit('registerResponse', error);
      });
    },
    async update_password({commit},userdata){
      // let _this = this
      await axios.post(
        store.state.API_HOST + '/update_password',
        userdata,
        {
          params: {
              'app_name': store.state.APP_NAME,
              'user_id': store.state.userID,
          },
          headers: {
              'accept': 'application/json',
              'Content-Type': 'application/json',
          },
          withCredentials: true
        }
      ).then(response=>{
        if(typeof(response.data) != "string"){
          commit('registerResponse', response.data.res["id"]);
        }else{
          store.dispatch('logout');
          store.commit('setLoginUser', '')
        }
      }).catch(error => {
        commit('registerResponse', error);
      });
    },
    // async refreshaccessToken({dispatch}){
    //   let data = {'refreshToken':localStorage.getItem('refreshToken')}
    //   await axios.post(store.state.API_HOST + '/refresh_token',
    //   data
    //   ).then(response=>{
    //       dispatch('setAuthData', {
    //         accessToken: response.data.access_token,
    //         expiresIn: response.data.refresh_exp,
    //         refreshToken: response.data.refresh_token,
    //         user_id:response.data.user_id,
    //         sysAdmin:response.data.sysAdmin
    //       });
    //   }).catch(error => {
    //     console.log(error.response);
    //     // リフレッシュトークンの有効期限が切れていた場合、ログアウト
    //     store.dispatch('logout');
    //     store.commit('setLoginUser', '')
    //   });
    //   return;
    // },
    async setAuthData(context, authData) {
      console.log("認証情報更新")
      console.log(authData)
      localStorage.setItem('userID', authData.user_id);
      localStorage.setItem('sysAdmin', authData.sysAdmin);
      localStorage.setItem('domain', authData.domain);
      console.log(localStorage)
    }
  }
});