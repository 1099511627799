<template>
    <div class="container">
        <h2>
            <font-awesome-icon icon="gauge-high" />&nbsp;{{ pageTitle }}
            <span  data-bs-toggle="tooltip" data-bs-placement="top" title="再読み込み" style="float: right;margin-right: 50px;cursor: pointer;">
				<font-awesome-icon icon="fa-redo-alt" @click="reloadPage" />
			</span>
        </h2>
        <div id="announceArea">
            <div class="row gy-3 mb-3">
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            <h3 class="m-0 px-0 pt-2 fs-4"><font-awesome-icon icon="square-rss" />&nbsp;{{ newsTitle }}</h3>
                        </div>
                        <div class="tableScroll" style="max-height:20vh;">
                            <table class="table table-hover">
                                <thead style="position:sticky;top:0;left:0;">
                                    <tr>
                                        <th scope="col" style="width:20%;">発信日</th>
                                        <th scope="col" style="width:70%;">タイトル</th>
                                        <th scope="col">既読</th>
                                    </tr>
                                </thead>
                                <tbody style="max-height:15vh;overflow-y: auto;">
                                    <tr v-for="( item, i ) of msgs" :key="i" @click="showModal(i)">
                                        <td @mouseover="showPopover(i)" @mouseout="hidePopover">{{ item.updatedatetime }}</td>
                                        <td @mouseover="showPopover(i)" @mouseout="hidePopover">
                                            <font-awesome-icon v-if="item.severity == 1" icon="triangle-exclamation" style="color:gold"/>
                                            <font-awesome-icon v-if="item.severity == 2" icon="triangle-exclamation" style="color:red"/>
                                            &nbsp;{{ item.title }}
                                        </td>
                                        <td>
                                            <font-awesome-icon v-if="item.read == 1" icon="fa-check" style="color: #39d719;" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="message">{{ message }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isPopoverVisible" class="popover" :style="{ top: `${popoverY}px`, left: `${popoverX}px`,minWidth:'200px'}">
                <!-- <div style="background-color: lightgray;">
                    <h6 style="padding: 10px;">メッセージ</h6>
                </div> -->
                <div v-html="popoverContent" style="padding: 10px;"></div>
            </div>
            <div v-if="showModalFlg" class="modal" @click="closeModal($event)">
                <div class="modal-content" @click.stop>
                    <div>
                        <div style="background-color: lightgray; display: flex;">
                            <h4 style="padding: 10px;">{{ modalcontent_title }}</h4>
                            <span class="modalClose" @click="showModalFlg = false">×</span>
                        </div>
                        <div v-html="modalContent" style="padding: 10px;"></div>
                        <!-- <button @click="showModalFlg = false">モーダルを閉じる</button> -->
                    </div>
                </div>
            </div>
        </div>
        <span style="display: flex;height: 60vh;max-height: 60vh;">
            <div id="readProgressArea">
                <div class="card">
                    <div class="card-header">
                        <h3 class="m-0 px-0 pt-2 fs-4"><font-awesome-icon icon="list-check" />&nbsp;{{ readStatusSummaryTitle }}</h3>
                    </div>
                    <div style="padding: 8px 16px 8px 16px;">
                        <div class="unit-radio edit-row">
                            <label>検索単位:</label>
                            <label class="radio-option" v-for="(item, i) of unit_radio_list" :key="i" style="margin-left: 10px;">
                                <input type="radio" v-model="selectedUnit" :value=item.value @click="selectedRange=''"/> {{ item.name }}
                            </label>
                        </div>
                        <div v-if="selectedUnit">
                            <label>検索項目:</label>
                            <span v-if="selectedUnit == 'months_completed'">レポート納品日時</span>
                            <span v-else>依頼開始日時</span>
                        </div>
                        <div>
                            <label for="selectedRange" class="search_range">検索範囲：</label>
                            <select name="selectedRange" v-model="selectedRange" @change="calculateDates">
                                <option value=""></option>
                                <option v-for="(item,i) of this[selectedUnit + '_select_list']" :key="i" :value=item.value>{{ item.name }}</option>
                            </select>
                        </div>
                        <div v-if="startDate">
                            <label>検索日時:</label>
                            <span>{{ startDate + "～" + endDate }}</span>
                        </div>
                        <div class="message">{{ readStatusMessage }}</div>
                        <div class="tableScroll" style="max-height:50vh;">
                            <table class="table table-hover">
                                <thead style="position:sticky;top:0;left:0;">
                                    <tr>
                                        <th scope="col" style="width:31%;">グループ名</th>
                                        <th scope="col" style="width:23%;">受付完了</th>
                                        <th scope="col" style="width:23%;">読影中</th>
                                        <th scope="col" style="width:23%;">読影完了</th>
                                    </tr>
                                </thead>
                                <tbody style="overflow-y: auto;">
                                    <template v-for="( item, i ) of TotallingDataList" :key="i">
                                        <tr v-if="i === 0 || item.targetdate !== TotallingDataList[i - 1].targetdate">
                                            <td colspan="4" style="background-color:darkgray;">{{ item.targetdate }}</td>
                                        </tr>
                                        <tr>
                                            <td >{{ item.ordergroupname }}</td>
                                            <td >{{ item.acceptedcount }}</td>
                                            <td >{{ item.readingcount }}</td>
                                            <td >{{ item.readcount }}</td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div id="FileLinkArea">
                <div class="card">
                    <div class="card-header">
                        <h3 class="m-0 px-0 pt-2 fs-4"><font-awesome-icon icon="list-check" />&nbsp;{{ fileLinkTitle }}</h3>
                    </div>
                    <div style="color:red;text-align: center;margin-top: auto;margin-bottom: auto;">整備中</div>
                </div>
            </div>
        </span>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "HomeScreen",
    data() {
        return {
            btnName: 'Button',
            pageTitle: 'ダッシュボード',
            newsTitle: '運営からのお知らせ',
            readStatusSummaryTitle: '読影進捗の集計',
            fileLinkTitle:'ファイルリンク',
            msgs: [],
            selectMaxNum_announce:100,
            message:"",
            readStatusMessage:"",
            announceCount:0,
            index:-1,
            isPopoverVisible: false,
            popoverX: 0,
            popoverY: 0,
            popoverContent: "",
            showModalFlg: false,
            modalcontent_title:"",
            modalContent: "",
            unit_radio_list:[
                {name:"日次",value:"days"},
                {name:"週間",value:"weeks"},
                {name:"月間",value:"months"},
                {name:"月間（納品）",value:"months_completed"},
            ],
            days_select_list:[
                {name:"本日",value:"0"},
                {name:"１日前",value:"1"},
                {name:"２日前",value:"2"},
                {name:"３日前",value:"3"},
                {name:"４日前",value:"4"},
                {name:"５日前",value:"5"},
                {name:"６日前",value:"6"},
                {name:"７日前",value:"7"},
                {name:"８日前",value:"8"},
                {name:"９日前",value:"9"},
                {name:"１０日前",value:"10"},
                {name:"１１日前",value:"11"},
                {name:"１２日前",value:"12"},
                {name:"１３日前",value:"13"},
                {name:"１４日前",value:"14"},

            ],
            weeks_select_list:[
                {name:"今週",value:"0"},
                {name:"１週間前",value:"1"},
                {name:"２週間前",value:"2"},
                {name:"３週間前",value:"3"},
                {name:"４週間前",value:"4"},
                {name:"５週間前",value:"5"},
                {name:"６週間前",value:"6"},
                {name:"７週間前",value:"7"},
                {name:"８週間前",value:"8"},
            ],
            months_select_list:[
                {name:"今月",value:"0"},
                {name:"１か月前",value:"1"},
                {name:"２か月前",value:"2"},
                {name:"３か月前",value:"3"},
                {name:"４か月前",value:"4"},
                {name:"５か月前",value:"5"},
                {name:"６か月前",value:"6"},
                {name:"７か月前",value:"7"},
                {name:"８か月前",value:"8"},
                {name:"９か月前",value:"9"},
                {name:"１０か月前",value:"10"},
                {name:"１１か月前",value:"11"},
                {name:"１２か月前",value:"12"},
            ],
            months_completed_select_list:[
                {name:"今月",value:"0"},
                {name:"１か月前",value:"1"},
                {name:"２か月前",value:"2"},
                {name:"３か月前",value:"3"},
                {name:"４か月前",value:"4"},
                {name:"５か月前",value:"5"},
                {name:"６か月前",value:"6"},
                {name:"７か月前",value:"7"},
                {name:"８か月前",value:"8"},
                {name:"９か月前",value:"9"},
                {name:"１０か月前",value:"10"},
                {name:"１１か月前",value:"11"},
                {name:"１２か月前",value:"12"},
            ],
            TotallingDataList:[],
            selectedUnit:"days",
            selectedRange:"0",
            startDate: null,
            endDate: null,
            // readStatusSummary: [
            //     {order: '2.20220502.114225.444236', orderupdatedatetime: '22/5/2', status: '完了'},
            //     {order: '2.20220502.114444.349932', orderupdatedatetime: '22/5/2', status: '完了'},
            //     {order: '2.20220502.115347.904465', orderupdatedatetime: '22/5/2', status: '完了'},
            // ],
            // readPath: 'https://www.esfill.co.jp/esinfo/read.php'
        }
    },
    methods: {
        reloadPage() {
            // thisを使ってwindow.location.reload()を呼び出す
            window.location.reload();
        },
        view_announcement(){
            console.log("お知らせ表示")
            this.message = "読み込み中・・・"
            let _this = this
            axios.post(this.$store.state.API_HOST + '/delivery_announcement',
                null,
                {
                params: {
                    app_name: _this.$store.state.APP_NAME,
                    max:_this.selectMaxNum_announce,
                    user_id: _this.$store.state.userID,
                },
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                }
            ).then(
                function(response) {
                if(typeof(response.data) != "string"){
                    _this.msgs = response.data.res
                    if(_this.msgs.length != 0){
                        _this.set_list()
                    }else{
                        _this.message = "お知らせはありません。"
                    }

                }else{
                    _this.$store.dispatch('logout');
                    _this.$store.commit('setLoginUser', '')
                }
                }.bind(this)
            )
            .catch(
                function(error) {
                    this.message = "GETエラー";
                    console.log(error);
                }.bind(this)
            );
        },
        async set_list() {
            var _this = this
            for( var i=0; i < _this.msgs.length; i++ ){
                var row = _this.msgs[i]
                for (var key in row) {
                    // console.log(key)
                    if(key == "insertdatetime" || key == "updatedatetime"){
                        var date = row[key].substr(0,10).replace(/-/g,"/")
                        var time = row[key].substr(11,8)
                        _this.msgs[i][key] = date + " " + time
                    }
                }
            }
            _this.announceCount = _this.msgs.length
            this.message = ""
        },
        showPopover(index) {
            this.isPopoverVisible = true;
            const item = this.msgs[index];
            this.popoverContent = item.content; // データ内のHTMLを取得
            // マウスの位置にポップアップを表示
            this.popoverX = event.clientX;
            this.popoverY = event.clientY;
        },
        hidePopover() {
            this.isPopoverVisible = false;
            this.popoverContent = "";
        },
        showModal(index){
            this.showModalFlg = true
            this.modalcontent_title = this.msgs[index].title
            this.modalContent = this.msgs[index].content
            // 未読の場合は既読に更新
            if(this.msgs[index].read == 0){
                // 既読にする
                this.msgs[index].read = 1
                let _this = this
                let data = {"seq":this.msgs[index].seq,"updatedatetime":this.msgs[index].updatedatetime}
                axios.post(this.$store.state.API_HOST + '/create_read_announcement',
                    data,
                    {
                    params: {
                        app_name: _this.$store.state.APP_NAME,
                        max:_this.selectMaxNum_announce,
                        user_id: _this.$store.state.userID,
                    },
                    headers: {
                        'accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                    }
                ).then(
                    function(response) {
                    if(typeof(response.data) != "string"){
                        console.log("お知らせ既読の更新成功")
                    }else{
                        _this.$store.dispatch('logout');
                        _this.$store.commit('setLoginUser', '')
                    }
                    }.bind(this)
                )
                .catch(
                    function(error) {
                        this.message = "GETエラー";
                        console.log(error);
                    }.bind(this)
                );
            }
        },
        closeModal(event) {
            // モーダル内の要素をクリックしていたら何もしない
            if (event.target.classList.contains('modal-content')) {
                return;
            }
            // モーダル外をクリックした場合、モーダルを閉じる
            this.showModalFlg = false;
        },
        calculateDates() {
            if(this.selectedUnit != "" && this.selectedRange != ""){
                const now = new Date();
                let start = "";
                let end = "";

                if (this.selectedUnit === "days") {
                    const daysAgo = parseInt(this.selectedRange);
                    start = new Date(now);
                    start.setDate(now.getDate() - daysAgo);
                    start.setHours(0, 0, 0, 0);

                    end = new Date(now);
                    end.setDate(now.getDate() - daysAgo);
                    end.setHours(23, 59, 59, 999);
                } else if (this.selectedUnit === "weeks") {
                    const weeksAgo = parseInt(this.selectedRange);
                    start = new Date(now);
                    start.setDate(now.getDate() - now.getDay() - 7 * weeksAgo);
                    start.setHours(0, 0, 0, 0);

                    end = new Date(start);
                    end.setDate(start.getDate() + 6);
                    end.setHours(23, 59, 59, 999);
                } else if (this.selectedUnit === "months" || this.selectedUnit === "months_completed") {
                    const monthsAgo = parseInt(this.selectedRange);
                    start = new Date(now);
                    start.setMonth(now.getMonth() - monthsAgo, 1);
                    start.setHours(0, 0, 0, 0);

                    end = new Date(now);
                    end.setMonth(now.getMonth() - monthsAgo + 1, 0);
                    end.setHours(23, 59, 59, 999);
                }

                this.startDate = this.formatDate(start);
                this.endDate = this.formatDate(end);
                this.get_TotallingData()
            }
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');

            return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
        },
        // DBの読影進捗ごとにデータを集計
        get_TotallingData(){
            this.readStatusMessage = "読み込み中・・・"
            let _this = this
            let data = {"start":this.startDate,"end":this.endDate,"unit":this.selectedUnit}
            axios.post(this.$store.state.API_HOST + '/get_totalling',
                data,
                {
                params: {
                    app_name: _this.$store.state.APP_NAME,
                    user_id: _this.$store.state.userID,
                },
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                withCredentials: true,
                }
            ).then(
                function(response) {
                if(typeof(response.data) != "string"){
                    _this.previousTargetDate = null
                    _this.TotallingDataList = response.data.res
                    if(_this.TotallingDataList.length == 0){
                        _this.readStatusMessage = "検索結果はありませんでした。"
                    }else{
                        _this.readStatusMessage = ""
                    }
                    // _this.set_list()
                }else{
                    _this.$store.dispatch('logout');
                    _this.$store.commit('setLoginUser', '')
                }
                }.bind(this)
            )
            .catch(
                function(error) {
                    this.message = "GETエラー";
                    console.log(error);
                }.bind(this)
            );
        },
    },
    created: function() {
        this.view_announcement()
        // this.msgs.reverse()
        // this.loadtitles( this.readPath )
        this.calculateDates()
    },
}
</script>

<style scoped>
/* ポップアップのスタイルを設定 */
    .popover {
        position: absolute;
        top: 0;
        left: 0;
        background: white;
        border: 1px solid #ccc;
        /* padding: 10px; */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        z-index: 999; /* 他の要素の上に表示 */
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-content {
        background: #fff;
        /* padding: 20px; */
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        width: 60%;
    }

    .modalClose {
        font-size: 2rem;
        margin-left: auto;
        margin-right: 15px;
    }

    .modalClose:hover {
        cursor: pointer;
    }
    #readProgressArea {
        width: 48%;
        overflow: auto;
        resize: both;
    }

    #FileLinkArea {
        width: 48%;
        overflow: auto;
        resize: both;
        margin-left: 20px;
    }

    .message {
        color: red;
    }

    .card{
        height: 100%;
    }
</style>