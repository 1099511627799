<template>
  <div id="app">
    <header id="head" class="top-banner">
      <div class="mx-auto px-3 py-3 text-start">
        <img src="./assets/images/logo_w_…_col.png" alt="EsForum Logo" class="logo-top-page-link" @click="gotoTop()">
        <login-status-menu :login="isAuthenticated"></login-status-menu>
      </div>
    </header>
    <main>
      <nav v-if="isAuthenticated" class="main-menu">
        <main-menu
          :menuItems="menuItems"
        ></main-menu>
      </nav>

      <!-- バックグラウンドでiframeにロケットチャットを読み込んでおく -->
      <template v-if="isAuthenticated">
        <chat-communication v-bind:class="{hide:hideflg}" v-model:hideflg="hideflg"></chat-communication>
      </template>

      <!-- RocketChatWindow.vueが開かれたタイミングで上記のiframeを表示 -->

      <router-view class="page" v-slot="{ Component }" v-model:hideflg="hideflg">
        <keep-alive :include="isAuthenticated ? includeComponents : []" :exclude="excludeComponents">
          <component :is="Component"></component>
        </keep-alive>
      </router-view>
    </main>
  </div>
</template>

<script>
import LoginStatusMenu from './components/LoginStatusMenu.vue';
import MainMenu from './components/MainMenu.vue'
import ChatCommunication from './views/ChatCommunication.vue';

export default {
  // mixins: [ChatManage],
  components: {
    LoginStatusMenu,
    MainMenu,
    ChatCommunication,
  },
  data() {
    return {
      menuItems:[
        {name: 'ダッシュボード', href: '/home', icon: 'gauge-high'},
        {name: '読影オーダー（DICOM）', href: '/dicomparser', icon: 'clipboard-user'},
        {name: '読影オーダー（非DICOM）', href: '/jpegparser', icon: 'clipboard-user'},
        {name: '読影オーダー（ローカル）', href: '/dicomparserlocal', icon: 'clipboard-user'},
        {name: '読影オーダー（PACS）', href: '/dicomparserpacs', icon: 'clipboard-user'},
        {name: 'オーダーリスト', href: '/orderlist', icon: 'rectangle-list'},
        {name: 'チャット', href: '/chat', icon: 'fa-comment'},
        {name: '管理', href: '/Manage', icon: 'fa-id-card'},
      ],
      hideflg:true,
      // ログインしている間はキャッシュし、値を保持する。ログアウトすると初期化されるコンポーネントのリスト
      includeComponents:['DicomParser','DicomParserLocal','DicomParserPacs','JpegParser'],
      // キャッシュから除外するコンポーネントの名前を指定。名前は各コンポーネント内のexport defaultで指定しているnameを指定。
      excludeComponents: ['ProfileEdit','OrderList','HomeScreen'],
    }
  },
  watch: {
    // ルートが変わるたびにコンポーネント情報を取得し、componentデータに格納
    $route(to) {
      if(to.matched[0].name != "chat"){
        this.hideflg = true
      }else{
        this.hideflg = false
      }
      // this.component = to.matched[0].components.default;
      // // 現在開いているコンポーネントの名前をrouter.jsから取得
      // this.componentName = to.matched[0].name;
    },
  },
  // watch:{
  //   hideflg:function(newVal,oldVal){
  //     console.log(newVal, oldVal)
  //     var _this = this
  //     document.getElementById('chatwindow').onload = async function() {
  //       await _this.startChat()
  //       _this.chatLoggedIn = true
  //     }
  //   }
  // },
  computed: {
    isAuthenticated(){
      return this.$store.getters.userID !== 0;
    }
  },
  methods:{
    // logout(){
    //   console.log("ログアウトfrom App.vue")
    //   this.$store.dispatch('logout');
    // },
    gotoTop() {
      console.log("ホーム画面に戻ります。")
      this.$router.push('/')
    }
  },
  created(){
    console.log("vue開始")
    this.$store.dispatch('loadJSONData',"");
  }
}
</script>

<style scoped>
.header-item{
  padding: 10px;
  cursor: pointer;
}
</style>
<style>
/* @import 'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css'; */

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /* margin-top: 60px; */
  display: flex;
	flex-direction: column;
	min-height: 100vh;
}
#app main {
  flex: 1 1 auto;
  background-color: #f2f2f2;
  display: flex;
}
#app main nav.main-menu {
  padding: 0.75rem 0 1rem 1rem;
  flex: 0 0 auto;
  background-color: transparent;
}
#app main .page {
  padding: 0.75rem 0 0 0.25rem;
  flex: 1 1 auto;
}

#logout {
  float: right;
  padding: 5px;
  margin-right: 5px;
}
.top-banner div {
  height: 4.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.top-banner div img {
  height: 100%;
}
.logo-top-page-link {
  filter: drop-shadow(2px 6px 2px rgba(0,0,0,0.25));
  cursor: pointer;
}

#app main .page {
    padding: 0.75rem 0 0 1.25rem;
    flex: 1 1 auto;
}

a[href="/Manage"]{
    position: fixed;
    bottom: 50px;
    color: #000000 !important;
}

a[href="/Manage"]:hover{
    color: #2a2222 !important;
}

.hide{
  width: 0;
  height:0;
}
</style>